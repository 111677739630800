import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '../classes/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  subRuntimeConfig: Subscription;

  constructor(private router: Router,
              private apiService: ApiService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    // there is some sort of bug relating to angular and video element playback
    // where the video freezes if you reload the page
    // the following is a workaround :

    let e = <HTMLVideoElement>document.getElementById('login-video');
    let parent = e.parentElement;
    e.remove();

    let e2 = <HTMLVideoElement>document.createElement("video");
    e2.setAttribute('id', 'login-video');
    e2.setAttribute('src', '/mint/img/rain.mp4');
    e2.autoplay = true;
    e2.muted = true;
    e2.loop = true;
    e2.load();
    parent.prepend(e2);
  }

  ngOnDestroy() {

    this.subRuntimeConfig.unsubscribe();
  }

  loadCredentials(event: any) {

    document.body.style.cursor = 'wait';

    event.preventDefault();

    let loadFileElement = <HTMLInputElement>document.getElementById('load-credentials-file');

    loadFileElement.click();
  }

  fileLoaded(event: any) {

    event.preventDefault();

    if (event.target.files && event.target.files.length === 1) {

      let file = event.target.files[0];

      let fileReader = new FileReader();

      fileReader.onload = () => {
        this.parseCredentials(<string>fileReader.result);
      }

      fileReader.readAsText(file);
    }
  }

  parseCredentials(text: string) {

    let lines = text.split(/\r?\n|\r/);

    if (lines.length < 3 || lines[0] !== '[ApiUser]') { 
      document.body.style.cursor = 'default';
      alert('Credentials file is invalid');
      return;
    }

    let line1Pair = lines[1].split('=');
    let line2Pair = lines[2].split('=');

    if (line1Pair.length !== 2 ||
        line2Pair.length !== 2 ||
        line1Pair[0] !== 'aws_access_key_id' ||
        line2Pair[0] !== 'aws_secret_access_key') {

      document.body.style.cursor = 'default';
      alert('Credentials file aws_access_key_id or aws_secret_access_key missing or invalid');
      return;
    }

    this.apiService.setCredentials(line1Pair[1], line2Pair[1]);

    this.subRuntimeConfig = this.apiService.getRuntimeConfig()
      .subscribe((success: boolean) => { 
        document.body.style.cursor = 'default';
        if (success) {
          this.router.navigate(['live-alerts']);
        }
    });
    
  }

}
