import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CsoSpsCombined } from '../../classes/cso-sps-combined';
import { FilterSortSpillageEvents } from '../../classes/filter-sort-spillage-events';
import { JqUtils } from '../../classes/jq-utils';

import * as moment from 'moment';

declare var $: any;

enum ComboType { CSO_ONLY, SPS_ONLY, CSO_AND_SPS };

interface DropDownItem {
  type: ComboType;
  text: string;
}

@Component({
  selector: 'app-site-cso-combined-spillage-events',
  templateUrl: './site-cso-combined-spillage-events.component.html',
  styleUrls: ['./site-cso-combined-spillage-events.component.scss']
})
export class SiteCsoCombinedSpillageEventsComponent implements OnInit {

  filterSortSpillageEvents: FilterSortSpillageEvents;

  csoSpsCombined: CsoSpsCombined;

  dropDownItems: DropDownItem[] = [
    { type: ComboType.CSO_ONLY,     text: 'CSO spills without SPS overflow' },
    { type: ComboType.SPS_ONLY,     text: 'SPS overflow without CSO spills' },
    { type: ComboType.CSO_AND_SPS,  text: 'SPS overflows and CSO spills at the same time' }
  ];

  currentDropDownItem: DropDownItem;


  constructor(private changeDetectorRef: ChangeDetectorRef) {
    
    this.csoSpsCombined = new CsoSpsCombined();
    this.filterSortSpillageEvents = new FilterSortSpillageEvents(10);
  }


  ngOnInit() {

    // let siteName = this.route.snapshot.paramMap.get('name');
    
    // this.subSpillEvents = this.apiService.getSpillEvents(siteName).subscribe(
    //   (spills: SpillageEvent[]) => {
    //     this.filterSortSpillageEvents.spillsReceived = spills;
    //     this.filterSortSpillageEvents.initialise();
    //     this.filterSortSpillageEvents.process();
    //     this.initFilterControls();
    // }); 

    // this.spillEventsCSO = this.loadData2TEMP('GLASSHOUSE_SPS-CSO-spills-summary.csv');
    // this.spillEventsSPS = this.loadData2TEMP('GLASSHOUSE_SPS-CSO-spills-summary.csv');

    this.currentDropDownItem = this.dropDownItems[0];
  }


  ngOnDestroy() {
  }


  setSpsCombined(csoSpsCombined: CsoSpsCombined) {

    this.csoSpsCombined = csoSpsCombined;
    this.configureFilterSort();
  } 


  // loadData2TEMP(fn1: string) : SpillageEvent[] {

  //   let eventfile = '/TEMP/' + fn1;
    
  //   let requestEvents = new XMLHttpRequest();  
  //   requestEvents.open("GET", eventfile, false);   
  //   requestEvents.send(null);  

  //   let spillageEvents: SpillageEvent[] = [];

  //   let lines = requestEvents.responseText.split(/\r?\n|\r/);

  //   lines.forEach(line => {

  //     let linedata = line.split(',');

  //     if (linedata.length == 8) {

  //       let spillage = new SpillageEvent(

  //         this.route.snapshot.paramMap.get('name'), // siteName
  //         SpillageEvents.spillageLevels.getLevelFromText(linedata[0].toUpperCase()), // spillageLevel
  //         SpillageEvents.getDate(linedata[1]), // startTimestamp
  //         linedata[1], // startTimestampDisplay
  //         SpillageEvents.getDate(linedata[2]), // endTimestamp
  //         linedata[2], // endTimestampDisplay
  //         linedata[3], // duration
  //         0, // durationHours
  //         '', // savedDuration
  //         0, // estimatedLitres
  //         0, // savedLitres
  //         0.0, // savedPercentage
  //         parseFloat(linedata[4]), // rainfallIntensity
  //         linedata[5].trim() === 'y' ? true : false, // causeRain
  //         linedata[6].trim() === 'y' ? true : false, // causePump
  //         linedata[7].trim() === 'y' ? true : false  // causeBlockage
  //       );

  //       spillage.durationHours = SpillageEvents.calcDurationHours(spillage.duration);

  //       spillageEvents.push(spillage);
  //     }

  //   });

  //   return spillageEvents;
  // }


  initFilterControls() {

    let _this = this;

    $("#range-slider-duration-2").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxDuration,
      values: [ 0, this.filterSortSpillageEvents.maxDuration ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByDuration(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-rainfall-2").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxRainfall,
      values: [ 0, this.filterSortSpillageEvents.maxRainfall ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByRainfall(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    let callback = (function(fromDate, toDate) {

      if (fromDate) { this.filterSortSpillageEvents.setFilterByMinTimestamp(fromDate); }
      if (toDate) { this.filterSortSpillageEvents.setFilterByMaxTimestamp(moment(toDate).add(1, 'day').toDate()); }
      this.filterSortSpillageEvents.process();

    }).bind(this);

    JqUtils.datePickerSetup("#date-picker-from-2", "#date-picker-to-2", callback);

  }


  resetFilters() {

    $("#range-slider-duration-2").slider("values", [0, $("#range-slider-duration-2").slider("option", "max")]);
    $("#range-slider-rainfall-2").slider("values", [0, $("#range-slider-rainfall-2").slider("option", "max")]);
    this.filterSortSpillageEvents.setFilterByDuration(0, $("#range-slider-duration-2").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByRainfall(0, $("#range-slider-rainfall-2").slider("option", "max"));

    $("#date-picker-from-2").datepicker("setDate", null);
    $("#date-picker-to-2").datepicker("setDate", null);
    this.filterSortSpillageEvents.setFilterByMinTimestamp(null);
    this.filterSortSpillageEvents.setFilterByMaxTimestamp(null);

    this.filterSortSpillageEvents.resetFilters();
  }


  dataVisSelect2(event: any, ddi: DropDownItem) {

    if (event) { event.preventDefault(); }

    this.currentDropDownItem = ddi;

    this.configureFilterSort();
  }


  configureFilterSort() {

    switch (this.currentDropDownItem.type) {

      case ComboType.CSO_ONLY : {
        this.filterSortSpillageEvents.spillsReceived = this.csoSpsCombined.soloCSOEvents;
        break;
      }
      case ComboType.SPS_ONLY : {
        this.filterSortSpillageEvents.spillsReceived = this.csoSpsCombined.soloSPSEvents;
        break;
      }
      case ComboType.CSO_AND_SPS : {
        this.filterSortSpillageEvents.spillsReceived = this.csoSpsCombined.overlappedEvents;
        break;
      }
    }
      
    this.filterSortSpillageEvents.initialise();
    this.filterSortSpillageEvents.process();
    this.initFilterControls();
  }

}
