import { SpillageEvent } from './spillage-event';
import { SpillageLevels } from './spillage-levels';

import * as moment from 'moment';

export class SpillageEvents {
  
  static spillageLevels = new SpillageLevels();

  constructor() { }
  
  static parse(data: any) : SpillageEvent[] {

    if (!data || data.retcode == undefined || data.rettext == undefined ||
        data.value == undefined || data.value == null || typeof data.value != 'object') {
      console.error('Spillage Events Retrieval - Invalid response from API Gateway');
      return;
    }

    if (data.retcode !== 1) {
      console.error('Spillage Events Retrieval - Error returned : ' + data.rettext);
      return;
    }

    let spillageEvents: SpillageEvent[] = [];

    data.value.forEach(obj => {
      spillageEvents.push(...SpillageEvents.parseCsv(obj));
    });

    return spillageEvents;
  }


  static parseCsv(data: any) : SpillageEvent[] {

    if (!data || !data.assetname || !data.csv) { return []; }
      
    let lines = data.csv.split(/\r?\n|\r/);

    let arr: SpillageEvent[] = [];

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].indexOf(',') > -1) {
          
        let linedata = lines[i].split(',');

        let startDate = SpillageEvents.getDate(linedata[1]);
        let endDate = SpillageEvents.getDate(linedata[2]);
       
        let spillage = new SpillageEvent(

          data.assetname,
          SpillageEvents.spillageLevels.getLevelFromText(linedata[0].toUpperCase()), // spillageLevel
          startDate, // startTimestamp
          SpillageEvents.getDisplayDate(startDate), // startTimestampDisplay
          endDate, // endTimestamp
          SpillageEvents.getDisplayDate(endDate), // endTimestampDisplay
          linedata[3], // duration
          0, // durationHours
          linedata[7], // savedDuration
          parseInt(linedata[4]), // estimatedLitres
          parseInt(linedata[5]), // savedLitres
          parseFloat(linedata[6]), // savedPercentage
          parseFloat(linedata[9]), // rainfallIntensity
          // linedata[10].trim() === 'y' ? true : false, // causeRain
          // linedata[11].trim() === 'y' ? true : false, // causePump
          // linedata[12].trim() === 'y' ? true : false  // causeBlockage
          false, // causeRain
          false, // causePump
          false  // causeBlockage
        );

        spillage.durationHours = SpillageEvents.calcDurationHours(spillage.duration);

        arr.push(spillage);
      }			
    }

    return arr;
  }


  // this parser version is used for the reduced return relating to combined CSO/SPS analysis
  static parseReducedCsv(data: any) : SpillageEvent[] {
      
    let lines = data.split(/\r?\n|\r/);

    let arr: SpillageEvent[] = [];

    for (let i = 1; i < lines.length; i++) {
      if (lines[i].indexOf(',') > -1) {
          
        let linedata = lines[i].split(',');

        let startDate = SpillageEvents.getDate(linedata[1]);
        let endDate = SpillageEvents.getDate(linedata[2]);
       
        let spillage = new SpillageEvent(

          '', // siteName
          SpillageEvents.spillageLevels.getLevelFromText(linedata[0].toUpperCase()), // spillageLevel
          startDate, // startTimestamp
          SpillageEvents.getDisplayDate(startDate), // startTimestampDisplay
          endDate, // endTimestamp
          SpillageEvents.getDisplayDate(endDate), // endTimestampDisplay
          linedata[3], // duration
          0, // durationHours
          '', // savedDuration
          0, // estimatedLitres
          0, // savedLitres
          0, // savedPercentage
          parseFloat(linedata[4]), // rainfallIntensity
          linedata[5].trim() === 'y' ? true : false, // causeRain
          linedata[6].trim() === 'y' ? true : false, // causePump
          linedata[7].trim() === 'y' ? true : false  // causeBlockage
        );

        spillage.durationHours = SpillageEvents.calcDurationHours(spillage.duration);

        arr.push(spillage);
      }			
    }

    return arr;
  }


  static getDate(date: string) : Date {

    let localMoment = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local(); 
    return localMoment.toDate();
  } 


  static getDisplayDate(date: Date) : string {

    return moment(date).format('DD/MM/YYYY HH:mm');
  } 


  static calcDurationHours(duration: string) : number {
    let a = duration.split(':');
    if (a.length !== 2) { return 0; }
    return parseFloat(a[0]) + (parseFloat(a[1]) / 60.0); 
  }  


  static getEmptySpillageEvent() : SpillageEvent {
    return new SpillageEvent(
      '',
      SpillageEvents.spillageLevels.getLevelFromId(0),
      new Date(),
      '',
      new Date(),
      '',
      '',
      0,
      '',
      0,0, 0, 0,
      false, false, false
    );
  }

}