import { Component, OnInit } from '@angular/core';
import { AsyncSubject, Subscription, forkJoin } from 'rxjs';
import { ChartSPS } from '../classes/chart-sps';
import { ApiService } from '../classes/api.service';
import { VisualisationData } from '../classes/visualisation-data';

import * as moment from 'moment';


@Component({
  selector: 'app-network-interdepend',
  templateUrl: './network-interdepend.component.html',
  styleUrls: ['./network-interdepend.component.scss']
})
export class NetworkInterdependComponent implements OnInit {

  branchBtnText: string;

  charts: ChartSPS[] = [];

  branchesAssets: string[][] = [
    ['CASTLE VALE SPS', 'BERWICK_CSO_26', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['BERWICK NO 5 SPS', 'BERWICK_CSO_26', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['BERWICK NO 9 SPS', 'BERWICK_CSO_19', 'BERWICK NO 7 SPS',  'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['SCREMERSTON NO 1 SOUTH SPS', 'SCREMERSTON NO 2 NORTH SPS', 'BERWICK_CSO_6', 'BERWICK NO 2 SPS', 'BERWICK NO 1 SPS'],
    ['BERWICK NO 8 SPS', 'BERWICK NO 7 SPS', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['BERWICK NO 4 SPS', 'BERWICK_CSO_17', 'BERWICK_CSO_22', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['RAVENSDOWNE BARRACKS SPS', 'BERWICK_CSO_17', 'BERWICK_CSO_22', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['BERWICK QUAY WALL SPS', 'BERWICK_CSO_31', 'BERWICK NO 3 SPS'],
    ['BERWICK NO 6 SPS'],
    ['BERWICK NO 10 SPS', 'BERWICK_CSO_13'],
    ['HIVEACRES SPS']
  ];

  branches: string[] = [];
  assets: string[] = [];

  sub: Subscription;

  timer: any;


  constructor(private apiService: ApiService) {

    this.branchesAssets.forEach(branchAssets => {
      this.branches.push(branchAssets[0]);
    });
  }

  ngOnInit() {
    setTimeout(() => { this.branchSelect(null, 0)}, 1);
  }

  ngOnDestroy() {
    if (this.sub) { this.sub.unsubscribe(); }
  }

  configureCharts(branchIdx: number) {

    this.charts.forEach(chart => {

      if (chart) {
        chart.c3Chart.destroy();
      }
    });

    this.charts = [];
    
    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    let startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    let endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');

    let subjs: AsyncSubject<VisualisationData>[] = [];

    this.branchesAssets[branchIdx].forEach(asset => {

      subjs.push(this.apiService.getVisualisationData(asset, startDateHist, endDateHist, false));
      this.charts.push(new ChartSPS());
    });

    this.sub = forkJoin(...subjs).subscribe((visDatas: VisualisationData[]) => { 

      let mirrorCharts = [];

      for (let i = 0; i < visDatas.length; i++) {

        let visData = visDatas[i];
        
        visData.pumpsData = visData.pumpsData.map(pumpData => "");

        if (i < (visDatas.length - 1)) {
          this.charts[i].createChart(320, 'netw-interdep-chart-cont-' + i, 'Historic', visData);
          mirrorCharts.push(this.charts[i].c3Chart);
        }
        else {
          this.charts[i].createChart(420, 'netw-interdep-chart-cont-' + i, 'Historic', visData, mirrorCharts);
        }        
      }        
    });  
  }


  clearWaitCursorWhenRendered() {      

    for (let i = 0; i < this.charts.length; i++) {
      if (!this.charts[i] || !this.charts[i].rendered) { return; }
    }

    clearInterval(this.timer);

    this.timer = null;

    // this is a chrome/c3 bug workaround
    //this.hideCharts(false);

    document.body.style.cursor = 'default';
  }


  branchSelect(event: any, branchIdx: number) {

    if (event) { event.preventDefault(); }

    // this is a chrome/c3 bug workaround
    //this.hideCharts(true);

    this.branchBtnText = this.branchesAssets[branchIdx][0];

    this.assets = [];

    this.branchesAssets[branchIdx].forEach(asset => {
      this.assets.push(asset);
    });

    setTimeout(() => { this.configureCharts(branchIdx); }, 1);

    document.body.style.cursor = 'wait';

    if (this.timer == null) {
      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }

  // there is a bug in c3 or chrome that makes reloading the visualisation data
  // really slow when these 2 charts are visible (Firefox is okay tho)
  // hence this function to hide the charts during a visualisation data reload
  hideCharts(hidden: boolean) {

    let i = 0;

    while (true) {

      let element = document.getElementById('netw-interdep-chart-cont-' + i)

      if (element) {
        element.hidden = hidden;
      }
      else {
        break;
      }

      i++;
    }
  }

}
