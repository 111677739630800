import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthService } from './classes/auth.service';

import { Login2Component } from './login2/login2.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LiveAlertsComponent } from './live-alerts/live-alerts.component';
import { SpillPredsComponent } from './spill-preds/spill-preds.component';
import { MapViewComponent } from './map-view/map-view.component';
import { AllSitesComponent } from './all-sites/all-sites.component';
import { NetworkAnalysisComponent } from './network-analysis/network-analysis.component';
import { NetworkInterdependComponent } from './network-interdepend/network-interdepend.component';
import { SiteSpsComponent } from './site-sps/site-sps.component';
import { SiteCsoComponent } from './site-cso/site-cso.component';
import { SiteWwtwComponent } from './site-wwtw/site-wwtw.component';

const routes: Routes = [
  { path: '', redirectTo: '/login2', pathMatch: 'full' },  
  { path: 'login2', component: Login2Component }, 
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthService] }, 
  { path: 'live-alerts', component: LiveAlertsComponent, canActivate: [AuthService] },
  { path: 'spill-preds', component: SpillPredsComponent, canActivate: [AuthService] },
  { path: 'map-view', component: MapViewComponent, canActivate: [AuthService] },
  { path: 'all-sites', component: AllSitesComponent, canActivate: [AuthService] },
  { path: 'network-analysis', component: NetworkAnalysisComponent, canActivate: [AuthService] },
  { path: 'network-interdepend', component: NetworkInterdependComponent, canActivate: [AuthService] },
  { path: 'siteSPS/:name', component: SiteSpsComponent, canActivate: [AuthService] },
  { path: 'siteCSO/:name', component: SiteCsoComponent, canActivate: [AuthService] },
  { path: 'siteWWTW/:name', component: SiteWwtwComponent, canActivate: [AuthService] },
  { path: '**', redirectTo: '/login2' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
