import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-site-status-bar',
  templateUrl: './site-status-bar.component.html',
  styleUrls: ['./site-status-bar.component.scss']
})
export class SiteStatusBarComponent implements OnInit {

  constructor() { }

  ngOnInit() {    
  }

}
