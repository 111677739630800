import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

interface MenuLink {
  routerLink: string;
  text: string;
  class: string;
}

@Component({
  selector: 'app-menu-links',
  templateUrl: './menu-links.component.html',
  styleUrls: ['./menu-links.component.scss']
})
export class MenuLinksComponent implements OnInit {

  menuLinks: MenuLink[];

  constructor(private route: ActivatedRoute) {
    this.menuLinks = [
      {routerLink: '/live-alerts', text: 'Live Alerts', class: 'nav-item active'},
      {routerLink: '/spill-preds', text: 'Spillage Predictions', class: 'nav-item'},
      {routerLink: '/map-view', text: 'Map View', class: 'nav-item'},
      {routerLink: '/all-sites', text: 'All Sites', class: 'nav-item'},
      {routerLink: '/network-analysis', text: 'Network Analysis', class: 'nav-item'},
      //{routerLink: '/network-interdepend', text: 'Network Interdependencies Analysis', class: 'nav-item'},
      //{routerLink: '/network-improvement-simulation', text: 'Network Improvement Simulation', class: 'nav-item'},
      {routerLink: '/energy-reduction', text: 'Energy Reduction', class: 'nav-item'},
    ]
  }

  ngOnInit() {
    let routerLink = '/' + this.route.snapshot.routeConfig.path;

    this.menuLinks.forEach(ml => { 
      if (ml.routerLink === routerLink) {
        ml.class = 'nav-item active';
      }
      else {
        ml.class = 'nav-item';
      }
    });
  }

}
