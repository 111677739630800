import { Router } from '@angular/router';
import { ChartAPI, generate } from 'c3';
import { selectAll, select } from 'd3';
import { SpillagesSummary } from './spillages-summary';
import { Site } from '../classes/site';

export class BarChartNetwork {

  c3Chart: ChartAPI = null;

  rendered: boolean;

  sites: Site[] = [];

  constructor() { }

  public createChart(chartContainerId: string, spillsSummaries: SpillagesSummary[], type: string, router: Router)
  {
    if (spillsSummaries.length < 1) { return; }

    this.rendered = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    if (type === 'Spills') {

      spillsSummaries.sort((a, b) => b.numEvents - a.numEvents);

      let  xdata=[], y1data=[], y2data=[], y3data=[];
      xdata.push('Station');
      y1data.push('High');
      y2data.push('Moderate');
      y3data.push('Low');

      spillsSummaries.forEach(ss => {
        xdata.push(ss.siteName);
        y1data.push(ss.numHigh);
        y2data.push(ss.numModerate);
        y3data.push(ss.numLow);		
      });

      this.c3Chart = generate({
        bindto: '#' + chartContainerId,
        size: { height: 400 },
        padding: { right: 25 },
        data: {
          x: 'Station',      
          columns: [ xdata, y1data, y2data, y3data ],
          type: 'bar',
          groups: [ ['High', 'Moderate', 'Low'] ],      
          order: null
        },
        color: { pattern: ['#E5332A','#F29100','#FFD032'] },
        axis: {
          x: {
            type: 'category',
            categories: xdata,
            tick: { rotate: 70, multiline: false },
            height:150
          }
        },
        zoom: { enabled: true, rescale: true },	
        onrendered: () => { 
          
          if (this.rendered) { return; }

          this.rendered = true; 
        
          if (chartContainerId == 'year-minus-1-container' ||
              chartContainerId == 'year-minus-2-container' ||
              chartContainerId == 'year-minus-3-container' ||
              chartContainerId == 'year-minus-4-container') {

            let _this = this;

            selectAll('#' + chartContainerId + ' .c3-axis-x .tick tspan')
              .each(function(d,i){
                let selection = select(this);
                let siteName = selection.text().replace(/\+/g, ' ');
                selection.on("click", () => {
                  
                  let site = _this.sites.find(site => site.name == siteName);
                  let url = 'site' + site.facility + '/' + site.name;
                  router.navigate([url]);
                });
              });
          }
        
        }	
      });
    }
    else if (type === 'Quantity' || type === 'Duration') {

      if (type === 'Quantity') {
        spillsSummaries.sort((a, b) => b.litresSpilled - a.litresSpilled);
      }
      else {
        spillsSummaries.sort((a, b) => b.daysDuration - a.daysDuration);
      }

      let  xdata=[], ydata=[];
      xdata.push('Station');
      ydata.push(type === 'Quantity' ? 'Quantity (ltrs)' : 'Duration (days)');

      spillsSummaries.forEach(ss => {
        xdata.push(ss.siteName);
        ydata.push(type === 'Quantity' ? ss.litresSpilled : ss.daysDuration);
      });

      this.c3Chart = generate({
		    bindto: '#' + chartContainerId,
        size: { height: 400 },
        padding: { right: 25 },
		    data: {
		      x: 'Station',
		      columns: [ xdata, ydata ],
		      type: 'bar',
		      order: 'desc'
		    },
		    axis: {
          x: {
            type: 'category',
            categories: xdata,
            tick: { rotate: 70, multiline: false },		            
          }
		    },
        zoom: { enabled: true },
        onrendered: () => {

          if (this.rendered) { return; }
           
          this.rendered = true; 
      
          if (chartContainerId == 'year-minus-1-container' ||
              chartContainerId == 'year-minus-2-container' ||
              chartContainerId == 'year-minus-3-container' ||
              chartContainerId == 'year-minus-4-container') {

            let _this = this;

            selectAll('#' + chartContainerId + ' .c3-axis-x .tick tspan')
              .each(function(d,i){
                let selection = select(this);
                let siteName = selection.text().replace(/\+/g, ' ');
                selection.on("click", () => {
                  
                  let site = _this.sites.find(site => site.name == siteName);
                  let url = 'site' + site.facility + '/' + site.name;
                  router.navigate([url]);
                });
            });
          }
        }	  			
		  });
    }
    
  }


  public setSites(sites: Site[]) : void 
  {
    this.sites = sites;
  }
  
}
