import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Site } from '../../classes/site';
import { SiteMap } from '../../classes/site-map';
import { ChartSPS } from '../../classes/chart-sps';
import { ChartCSO } from '../../classes/chart-cso';
import { VisualisationData } from '../../classes/visualisation-data';
import { ApiService } from '../../classes/api.service';
import { LoadService } from '../../classes/load.service';
import { RuntimeConfig } from '../../classes/runtime-config';

import * as moment from 'moment';


@Component({
  selector: 'app-expanded-row',
  templateUrl: './expanded-row.component.html',
  styleUrls: ['./expanded-row.component.scss']
})
export class ExpandedRowComponent implements OnInit {

  @Input() site: Site;
  @Input() chartContainerId: string;

  chartSPS: ChartSPS;
  chartCSO: ChartCSO;
  siteMap: SiteMap;

  timer: any;

  subVisDataPred: Subscription;

  labelText: string;


  constructor(private apiService: ApiService,
              private loadService: LoadService,
              private runtimeConfig: RuntimeConfig) { }


  ngOnInit() {
    if (this.chartContainerId == 'blockChartCont') {
      this.labelText = 'Blockage';
    }
    else if (this.chartContainerId == 'anomChartCont') {
      this.labelText = 'Anomaly';
    }
    else {
      this.labelText = 'Predicted';
    }
  }


  ngOnDestroy() {

    if (this.subVisDataPred) { this.subVisDataPred.unsubscribe() };
    if (this.siteMap) { this.siteMap.abort(); }
  }


  createChart(site: Site, containerId: string) {

    if (!this.subVisDataPred) { 

      document.body.style.cursor = 'wait';

      let alertStartDate: moment.Moment = null;
      let alertEndDate: moment.Moment = null;

      if (this.chartContainerId == 'blockChartCont') {
        alertStartDate = moment(site.blockageAlert.startTime);
        alertEndDate = moment(site.blockageAlert.endTime);
      }
      else if (this.chartContainerId == 'anomChartCont') {
        alertStartDate = moment(site.anomalyAlert.startTime);
        alertEndDate = moment(site.anomalyAlert.endTime);
      }

      // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
      // let startDatePred = moment().subtract(2, 'year').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
      // let endDatePred = moment().subtract(2, 'year').format('DD/MM/YYYY HH:mm');
      // let startDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
      // let endDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');   
      let startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
      if (this.chartContainerId == 'blockChartCont' || this.chartContainerId == 'anomChartCont') {
        // display period before event - 2 days or 25% of event duration, whichever is bigger
        if (site.name == '17513_CSO') {
          startDatePred = '22/06/2020 00:00';
        }
        else if (site.name == '16759_CSO') {
          startDatePred = '17/07/2020 00:00';
        }
        else {
          let priorDays = Math.floor(alertEndDate.diff(alertStartDate, 'day') * 0.25);
          if (priorDays < 2) { priorDays = 2; }
          startDatePred = moment(alertStartDate).subtract(priorDays, 'day').format('DD/MM/YYYY HH:mm');
        }
        
      }
      else if (this.chartContainerId == 'spillChartCont') {
        startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('DD/MM/YYYY HH:mm'); // !!!! TEMP FOR WW !!!!
      }
      let endDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');   

      this.subVisDataPred = this.apiService.getVisualisationData(site.name, startDatePred, endDatePred, true)
        .subscribe((visData: VisualisationData) => { 

          visData.currentDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
          if (this.chartContainerId == 'blockChartCont' || this.chartContainerId == 'anomChartCont') {
            visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(48, 'hour').format('YYYY-MM-DD HH:mm:ss');
            visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
            visData.alertStartDate = alertStartDate.format('YYYY-MM-DD HH:mm:ss');
            visData.alertEndDate = alertEndDate.format('YYYY-MM-DD HH:mm:ss');            
          }
          if (this.chartContainerId == 'spillChartCont') {
            visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('YYYY-MM-DD HH:mm:ss');
            visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
          }
          else {
            visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
            visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
          }
            
          if (site.facility == 'SPS') {
            this.chartSPS = new ChartSPS();
            this.chartSPS.createChart(300, containerId + site.id2, 'Prediction', visData);
          }
          else if (site.facility == 'CSO') {
            this.chartCSO = new ChartCSO();
            if (this.chartContainerId == 'blockChartCont') {
              this.chartCSO.createChart(300, containerId + site.id2, 'Blockage', visData);
            }
            else if (this.chartContainerId == 'anomChartCont') {
              this.chartCSO.createChart(300, containerId + site.id2, 'Anomaly', visData);
            }
            else {
              this.chartCSO.createChart(300, containerId + site.id2, 'Prediction', visData);
            }
          }
          
      });

      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }  


  createMap(site: Site) {
  
    // looks like the expand animation is interferring with the map drawing so small delay required
    if (!this.siteMap) { 
      let _this = this;
      setTimeout(function(){
          _this.siteMap = new SiteMap(_this.loadService, _this.runtimeConfig, site);
        }, 500);
    }
  }


  clearWaitCursorWhenRendered() {

    if ((this.chartSPS && this.chartSPS.rendered) ||
        (this.chartCSO && this.chartCSO.rendered)) {

      clearInterval(this.timer);
      document.body.style.cursor = 'default';
    }
  }

}
