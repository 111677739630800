import { PumpAlertLevel } from './pump-alert-levels';

export class PumpAlert {
  constructor(
    public pump1AlertLevel: PumpAlertLevel,
    public pump2AlertLevel: PumpAlertLevel,
    public alertDays: number,
    public pumpOn: number,
    public pumpOff: number
  ) { }
}