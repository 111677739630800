import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpillageEvent } from '../../classes/spillage-event';
import { ApiService } from '../../classes/api.service';
import { FilterSortSpillageEvents } from '../../classes/filter-sort-spillage-events';
import { JqUtils } from '../../classes/jq-utils';
import { TimestampPair } from '../../classes/timestamp-pair';

import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-site-cso-spillage-events',
  templateUrl: './site-cso-spillage-events.component.html',
  styleUrls: ['./site-cso-spillage-events.component.scss']
})
export class SiteCsoSpillageEventsComponent implements OnInit {

  @Output() emitterJumpToVisualisation = new EventEmitter<TimestampPair>();

  filterSortSpillageEvents: FilterSortSpillageEvents;

  subSpillEvents: Subscription;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef) {
    this.filterSortSpillageEvents = new FilterSortSpillageEvents(10);
  }

  ngOnInit() {

    let siteName = this.route.snapshot.paramMap.get('name');
    
    this.subSpillEvents = this.apiService.getSpillEvents(siteName).subscribe(
      (spills: SpillageEvent[]) => {
        this.filterSortSpillageEvents.spillsReceived = spills;
        this.filterSortSpillageEvents.initialise();
        this.filterSortSpillageEvents.process();
        this.initFilterControls();
    }); 

    
    // this.filterSortSpillageEvents.spillsReceived = this.loadData2TEMP('GLASSHOUSE_SPS-CSO-spills-summary.csv');
    // this.filterSortSpillageEvents.initialise();
    // this.filterSortSpillageEvents.process();
    // this.initFilterControls();
  }


  // loadData2TEMP(fn1: string) : SpillageEvent[] {

  //   let eventfile = '/TEMP/' + fn1;
    
  //   let requestEvents = new XMLHttpRequest();  
  //   requestEvents.open("GET", eventfile, false);   
  //   requestEvents.send(null);  

  //   let spillageEvents: SpillageEvent[] = [];

  //   let lines = requestEvents.responseText.split(/\r?\n|\r/);

  //   lines.forEach(line => {

  //     let linedata = line.split(',');

  //     if (linedata.length == 8) {

  //       let spillage = new SpillageEvent(

  //         this.route.snapshot.paramMap.get('name'), // siteName
  //         SpillageEvents.spillageLevels.getLevelFromText(linedata[0].toUpperCase()), // spillageLevel
  //         SpillageEvents.getDate(linedata[1]), // startTimestamp
  //         linedata[1], // startTimestampDisplay
  //         SpillageEvents.getDate(linedata[2]), // endTimestamp
  //         linedata[2], // endTimestampDisplay
  //         linedata[3], // duration
  //         0, // durationHours
  //         '', // savedDuration
  //         0, // estimatedLitres
  //         0, // savedLitres
  //         0.0, // savedPercentage
  //         parseFloat(linedata[4]), // rainfallIntensity
  //         linedata[5].trim() === 'y' ? true : false, // causeRain
  //         linedata[6].trim() === 'y' ? true : false, // causePump
  //         linedata[7].trim() === 'y' ? true : false  // causeBlockage
  //       );

  //       spillage.durationHours = SpillageEvents.calcDurationHours(spillage.duration);

  //       spillageEvents.push(spillage);
  //     }

  //   });

  //   return spillageEvents;
  // }


  ngOnDestroy() {

    if (this.subSpillEvents) { this.subSpillEvents.unsubscribe() };
  }

  initFilterControls() {

    let _this = this;

    $("#range-slider-litres").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxLitres,
      values: [ 0, this.filterSortSpillageEvents.maxLitres ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByLitres(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-duration").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxDuration,
      values: [ 0, this.filterSortSpillageEvents.maxDuration ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByDuration(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-rainfall").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxRainfall,
      values: [ 0, this.filterSortSpillageEvents.maxRainfall ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByRainfall(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });
 
    let callback = (function(fromDate, toDate) {

      if (fromDate) { this.filterSortSpillageEvents.setFilterByMinTimestamp(fromDate); }
      if (toDate) { this.filterSortSpillageEvents.setFilterByMaxTimestamp(moment(toDate).add(1, 'day').toDate()); }
      this.filterSortSpillageEvents.process();

    }).bind(this);

    JqUtils.datePickerSetup("#date-picker-from", "#date-picker-to", callback);
  }


  resetFilters() {

    $("#range-slider-litres").slider("values", [0, $("#range-slider-litres").slider("option", "max")]);
    $("#range-slider-duration").slider("values", [0, $("#range-slider-duration").slider("option", "max")]);
    $("#range-slider-rainfall").slider("values", [0, $("#range-slider-rainfall").slider("option", "max")]);
    this.filterSortSpillageEvents.setFilterByLitres(0, $("#range-slider-litres").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByDuration(0, $("#range-slider-duration").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByRainfall(0, $("#range-slider-rainfall").slider("option", "max"));

    $("#date-picker-from").datepicker("setDate", null);
    $("#date-picker-to").datepicker("setDate", null);
    this.filterSortSpillageEvents.setFilterByMinTimestamp(null);
    this.filterSortSpillageEvents.setFilterByMaxTimestamp(null);

    this.filterSortSpillageEvents.resetFilters();
  }


  jumpToVisualisation(startTimestamp: Date, endTimestamp: Date) {
    
    let timestampPair = new TimestampPair(startTimestamp, endTimestamp);

    this.emitterJumpToVisualisation.emit(timestampPair);
  }

}
