import { InflowRateLevel } from './inflow-rate-levels';

export class ChamberInfo {

  public pumpRates: number[] = [];

  constructor(
    public inflowRateLevel: InflowRateLevel,
    public inflow1Hour: number, 
    public inflow6Hour: number,
    public inflow12Hour: number,
    public inflow24Hour: number,
    public chamberVolume: number,
    public highLevel: number,
    public overflowLevel: number,
    public pump1OnLevel: number,
    public pumpAssistLevel: number,
    public pumpOffLevel: number,
    pumpspecs: any
  ) {

    if (pumpspecs) {

      if (pumpspecs.pump1 && pumpspecs.pump1.flow_rate) {
        this.pumpRates.push(pumpspecs.pump1.flow_rate);
      }

      if (pumpspecs.pump2 && pumpspecs.pump2.flow_rate) {
        this.pumpRates.push(pumpspecs.pump2.flow_rate);
      }

      if (pumpspecs.pump3 && pumpspecs.pump3.flow_rate) {
        this.pumpRates.push(pumpspecs.pump3.flow_rate);
      }
      
      if (pumpspecs.pump4 && pumpspecs.pump4.flow_rate) {
        this.pumpRates.push(pumpspecs.pump4.flow_rate);
      }

      if (pumpspecs.pump5 && pumpspecs.pump5.flow_rate) {
        this.pumpRates.push(pumpspecs.pump5.flow_rate);
      }

      if (pumpspecs.pump6 && pumpspecs.pump6.flow_rate) {
        this.pumpRates.push(pumpspecs.pump6.flow_rate);
      }

      if (pumpspecs.pump7 && pumpspecs.pump7.flow_rate) {
        this.pumpRates.push(pumpspecs.pump7.flow_rate);
      }

      if (pumpspecs.pump8 && pumpspecs.pump8.flow_rate) {
        this.pumpRates.push(pumpspecs.pump8.flow_rate);
      }

      if (pumpspecs.pump9 && pumpspecs.pump9.flow_rate) {
        this.pumpRates.push(pumpspecs.pump9.flow_rate);
      }
    }
  }

}