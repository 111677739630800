import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ExpandedRowComponent } from '../../shared-components/expanded-row/expanded-row.component';
import { Site } from '../../classes/site';
import { Sites } from '../../classes/sites';
import { ApiService } from '../../classes/api.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';


@Component({
  selector: 'app-spillage-alerts',
  templateUrl: './spillage-alerts.component.html',
  styleUrls: ['./spillage-alerts.component.scss']
})
export class SpillageAlertsComponent implements OnInit {

  @ViewChildren(ExpandedRowComponent) expandedRows: QueryList<ExpandedRowComponent>;
  
  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService) {
    this.filterSortSites = new FilterSortSites(20);
  }


  ngOnInit() {

    this.apiService.sitesSubject.subscribe((sites: Site[]) => {
      
      //this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.sitesReceived = Sites.buildAlertsSiteArray(sites, 'Spillage');
      this.filterSortSites.removeNonAffected('spillage');
      this.filterSortSites.process();
    }); 
  }


  createDynamicElements(site: Site) {

    let expandedRow = this.expandedRows.find(er => er.site.id2 == site.id2);

    expandedRow.createChart(site, 'spillChartCont');

    setTimeout(function(){ expandedRow.createMap(site); }, 500);
  }

}
