import { Site } from './site';
import { Sites } from './sites';
import { SpillageAlert } from './spillage-alert';
import { SpillageLevels } from './spillage-levels';
import { BlockageAlert } from './blockage-alert';
import { BlockageLevels } from './blockage-levels';
import { AnomalyAlert } from './anomaly-alert';

import * as moment from 'moment';

export class LiveAlerts {

  static spillageLevels = new SpillageLevels();
  static blockageLevels = new BlockageLevels();
  
  static assign(sites: Site[], data: any) : void {
     
    if (!data || data.retcode == undefined || data.rettext == undefined ||
        data.value == undefined || data.value == null || typeof data.value != 'string') {
      console.error('Live Alerts Retrieval - Invalid response from API Gateway');
      return;
    }

    if (data.retcode !== 1) {
      console.error('Live Alerts Retrieval - Error returned : ' + data.rettext);
      return;
    }

    let lines: string[] = data.value.split(/\r?\n|\r/);
        
    for (let i = 1; i < lines.length; i++) {
      if (lines[i].indexOf(',') > -1) {
        
        //"AlertType,Severity,Station,AssetType,Expected,Duration,Quantity (ltrs),Catchment,StartTime,EndTime"
        //"Spillage,LOW,BERWICK NO 1 SPS,SPS,02:00 - 02:15,00:15,-,-,-,-"
        let linedata = lines[i].split(',');

        let alertType = linedata[0];
        let level = linedata[1];
        let sitename = linedata[2];

        if (alertType == "Spillage") {

          let startTime = moment.utc(linedata[8], 'YYYY-MM-DD HH:mm').local();
          let endTime = moment.utc(linedata[9], 'YYYY-MM-DD HH:mm').local();

          //let expected = linedata[4];
          let expected = startTime.format('HH:mm') + ' - ' + endTime.format('HH:mm');
          let duration = linedata[5];

          let durationMinutes = Sites.calcDurationMinutes(duration);

          let spillageAlert = new SpillageAlert(
              this.spillageLevels.getLevelFromText(level),
              expected, 
              duration, 
              durationMinutes, 
              0);

          for (let j = 0; j < sites.length; j++) {
            if (sitename == sites[j].name) {
              sites[j].spillageAlerts.push(spillageAlert);
              if (spillageAlert.spillageLevel.id > sites[j].spillageAlert.spillageLevel.id) {
                sites[j].spillageAlert = spillageAlert;
                sites[j].anomalyPumpSpillage = "S"
              }
            }
          }
        }
        else if (alertType == "Blockage") {

          let startTime = moment.utc(linedata[8], 'YYYY-MM-DD HH:mm').local().toDate();
          let endTime = moment.utc(linedata[9], 'YYYY-MM-DD HH:mm').local().toDate();

          let startTimeDisplay = moment(startTime).format('DD/MM/YYYY HH:mm');
          let endTimeDisplay = moment(endTime).format('DD/MM/YYYY HH:mm');
          
          let blockageAlert = new BlockageAlert(
            this.blockageLevels.getLevelFromText(level),
            startTime, 
            endTime,
            startTimeDisplay, 
            endTimeDisplay);

          for (let j = 0; j < sites.length; j++) {
            if (sitename == sites[j].name) {
              sites[j].blockageAlert = blockageAlert;
            }
          }
        }
        else if (alertType == "Anomaly") {

          let description = linedata[10];

          let startTime = moment.utc(linedata[8], 'YYYY-MM-DD HH:mm').local().toDate();
          let endTime = moment.utc(linedata[9], 'YYYY-MM-DD HH:mm').local().toDate();

          let startTimeDisplay = moment(startTime).format('DD/MM/YYYY HH:mm');
          let endTimeDisplay = moment(endTime).format('DD/MM/YYYY HH:mm');

          let anomalyAlert = new AnomalyAlert(
            description, 
            startTime, 
            endTime,
            startTimeDisplay, 
            endTimeDisplay);

          for (let j = 0; j < sites.length; j++) {
            if (sitename == sites[j].name) {
              sites[j].anomalyAlert = anomalyAlert;
              sites[j].anomalyAlerts.push(anomalyAlert);
              sites[j].anomalyPumpSpillage = "A"
            }
          }

        }
      }
    }      

    return;
  }
  
}