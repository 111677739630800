import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApiService } from '../../classes/api.service';
import { SpillagesSummary } from '../../classes/spillages-summary';
import { SpillagesSummaries } from '../../classes/spillages-summaries';

@Component({
  selector: 'app-network-analysis-status-bar',
  templateUrl: './network-analysis-status-bar.component.html',
  styleUrls: ['./network-analysis-status-bar.component.scss']
})
export class NetworkAnalysisStatusBarComponent implements OnInit {

  currentYear: number;

  spillsSummCurrYr: SpillagesSummary;

  subSpillsSummsCurrYr: Subscription;

  constructor(private apiService: ApiService) {

    //this.currentYear = new Date().getFullYear();
    this.currentYear = new Date('2020-07-25T12:55:00').getFullYear();

    this.spillsSummCurrYr = SpillagesSummaries.getEmptySpillagesSummary();
  }

  ngOnInit() {

    this.subSpillsSummsCurrYr = this.apiService.getSpillsSummary('ALL', this.currentYear)
      .subscribe(ss => {

        this.spillsSummCurrYr = ss;
        this.spillsSummCurrYr.litresSpilled = Math.round(this.spillsSummCurrYr.litresSpilled /= 1000000);
      });
  }

  ngOnDestroy() {

    this.subSpillsSummsCurrYr.unsubscribe();
  }

}
