import { Component, OnInit } from '@angular/core';
import { Site } from '../../../classes/site';
import { Sites } from '../../../classes/sites';
import { ApiService } from '../../../classes/api.service';

@Component({
  selector: 'app-status-site',
  templateUrl: './status-site.component.html',
  styleUrls: ['./status-site.component.scss']
})
export class StatusSiteComponent implements OnInit {

  site: Site = Sites.getEmptySite();
  stationType: string;

  constructor(private apiService: ApiService) { }

  ngOnInit() {

    this.apiService.siteSubject.subscribe((site: Site) => {

        this.site = site;

        switch(this.site.facility) {
          case 'SPS':
            this.stationType = 'Pump Station';
            break;
          case 'CSO':
            this.stationType = 'Combined Spillage Overflow';
            break;
          case 'WWTW':
            this.stationType = 'Waste Water Treatment Works';
            break;			        			        			        			        
        };
      }
    );
  }

}
