import * as moment from 'moment';

let NUM_PUMPS_MIN = 4;

export class VisualisationData {

  rainfallData: string = null;
  overflowData: string = null;
  pumpsData: string[] = [];
  slmData: string = null;
  lvlpredData: string = null;

  rainfallPredictionData: string = null;
  overflowPredictionData: string = null;
  slmPredictionData: string = null;
  lvlpredPredictionData: string = null;

  currentDate: string;
  startDate: string;
  endDate: string;

  alertStartDate: string;
  alertEndDate: string;

  constructor(data: any[], prediction: boolean) {

    data.forEach(d => {

      if (!d || !d.actuals || d.actuals.retcode == undefined || d.actuals.rettext == undefined ||
          d.actuals.value == undefined || d.actuals.value == null || typeof d.actuals.value != 'object') {
        console.error('Visualisation Data Retrieval - Invalid response from API Gateway');
        return;
      }

      if (d.actuals.retcode !== 1) {
        console.error('Visualisation Data Retrieval - Error returned : ' + d.rettext);
        return;
      }
        
      let actuals = d.actuals.value;
            
      actuals.forEach(actual => {
        switch (actual.file_type) {
          case 'rain'     : this.rainfallData = actual.csv_data; break;
          case 'oflow'    : this.overflowData = actual.csv_data; break;
          case 'SLM'      : this.slmData = actual.csv_data; break;
          case 'lvlpred'  : this.lvlpredData = actual.csv_data; break;
          default       : for (let i = this.pumpsData.length + 1; i <= 9; i++) {
                            if (actual.file_type == 'pump' + i) {
                              this.pumpsData.push(actual.csv_data);
                              break;
                            }
                            else {
                              break;
                            }
                          }
                          break;
        }
      });

      if (prediction) {

        if (!d.predictions || d.predictions.retcode == undefined || d.predictions.rettext == undefined ||
            d.predictions.value == undefined || d.predictions.value == null || typeof d.predictions.value != 'object') {
          console.error('Visualisation Data Retrieval - Invalid response from API Gateway');
          return;
        }

        if (d.predictions.retcode !== 1) {
          console.error('Visualisation Data Retrieval - Error returned : ' + d.rettext);
          return;
        }
          
        let predictions = d.predictions.value;
                  
        predictions.forEach(prediction => {
          switch (prediction.file_type) {
            case 'rain'     : this.rainfallPredictionData = prediction.csv_data; break;
            case 'oflow'    : this.overflowPredictionData = prediction.csv_data; break;
            case 'SLM'      : this.slmPredictionData = prediction.csv_data; break;
            case 'lvlpred'  : this.lvlpredPredictionData = prediction.csv_data; break;
          }
        });
      }
    });

    // always need NUM_PUMPS_MIN
    for (let i = this.pumpsData.length; i < NUM_PUMPS_MIN; i++) {
      this.pumpsData.push('');
    }

  }

}