import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { Site } from '../classes/site';
import { MapComponent } from '../shared-components/map/map.component';


@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {

  @ViewChild(MapComponent) mapComponent:MapComponent;

  mapCheckTimer: any = null;

  sites: Site[] = [];

  constructor(private apiService: ApiService,
              private cognitoService: CognitoService,
              private router: Router) {
  }

  ngOnInit() {
    
    document.head.title = "Map View - StormHarvester";
    document.body.className = "dashboard";

    document.body.style.cursor = 'wait';
    this.mapCheckTimer = setInterval(() => { 
      if (this.mapComponent.mapReady()) { 
        this.mapComponent.setCursor('wait');
        clearInterval(this.mapCheckTimer);
      }
    }, 100);

    this.apiService.getSites();
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {
      this.sites = sites;
      document.body.style.cursor = 'default';
      this.mapComponent.setCursor('');
    });

    this.cognitoService.refreshIdTokenStart();
  }

  ngOnDestroy() {

    // only call unsubscribe on the subject once on the top level component
    this.apiService.sitesSubject.unsubscribe();

    this.cognitoService.refreshIdTokenStop();

    document.body.style.cursor = 'default';
    this.mapComponent.setCursor('');
  }
  
}
