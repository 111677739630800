import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, AsyncSubject, Subscription } from 'rxjs';

import { Site } from '../classes/site';
import { Sites } from '../classes/sites';
import { SiteMap } from '../classes/site-map';
import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { LoadService } from '../classes/load.service';
import { ChartSPS } from '../classes/chart-sps';
import { BarChart } from '../classes/bar-chart';
import { InflowChart } from '../classes/inflow-chart';
import { SpillagesSummary } from '../classes/spillages-summary';
import { VisualisationData } from '../classes/visualisation-data';
import { RuntimeConfig } from '../classes/runtime-config';
import { JqUtils } from '../classes/jq-utils';
import { TimestampPair } from '../classes/timestamp-pair';

import * as moment from 'moment';

declare var $: any;

var CUSTOM_DATE_IDX = 5;
var LAST_IDX_SPILLAGE_TABLE = -1;

@Component({
  selector: 'app-site',
  templateUrl: './site-sps.component.html',
  styleUrls: ['./site-sps.component.scss']
})
export class SiteSpsComponent implements OnInit {
  
  site: Site;
  siteName: string;
  timer: any;
  dataVisBtnText: string;
  displayImpact: boolean = false;

  subVisDataPred: Subscription;
  subVisDataHists: Subscription[] = [null, null, null, null, null, null];
  subSpillsSummaries: Subscription;

  subjVisDataHists: AsyncSubject<VisualisationData>[] = [null, null, null, null, null, null];

  chart: ChartSPS;
  dataVisChart: ChartSPS;
  noSpillsChart: BarChart;
  qtySpillsChart: BarChart; 
  durSpillsChart: BarChart; 
  dryInflowChart: InflowChart; 

  siteMap: SiteMap;

  dwiFileName: string;

  showDateRange: boolean;
  dateRangeLoading: boolean;
  lastIdx: number;
  startDatesHist: string[] = [];
  endDatesHist: string[] = [];
  selectedStartDate: Date;
  selectedEndDate: Date;

  activatedWithCustomDataVis = false;

  yZoomMin: Number = 0;
  yZoomMax: Number = 110;

  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private cognitoService: CognitoService,
              private loadService: LoadService,
              private runtimeConfig: RuntimeConfig) {
    
    this.dataVisBtnText = 'Last Week';
    this.lastIdx = 0;
    this.showDateRange = false;
    this.dateRangeLoading = false;

    this.dataVisChart = new ChartSPS();

    if (this.route.snapshot['queryParams']['start'] && this.route.snapshot['queryParams']['end']) {
      this.activatedWithCustomDataVis = true;
    }
  }


  ngOnInit() {

    this.siteName = this.route.snapshot.paramMap.get('name');

    this.site = Sites.getEmptySite();

    this.apiService.getSite(this.siteName);

    this.apiService.siteSubject.subscribe((site: Site) => { 
      this.site = site;
      this.siteMap = new SiteMap(this.loadService, this.runtimeConfig, site, true);
      this.dwiFileName = site.id + '-dwf.png';
    });

    this.cognitoService.refreshIdTokenStart();
  }


  ngAfterViewInit() {

    // IMPORTANT ! - not allowed to change angular component attributes in here
    // this chart stuff is okay as it is direct dom manipulation independent of angular

    document.body.style.cursor = 'wait';

    //let currentYear = new Date().getFullYear();
    let currentYear = new Date('2020-07-25T12:55:00').getFullYear();

    let subjectSpillsSummaries: Subject<SpillagesSummary>[] = [];
    //for (let i = 0; i < 5; ++i) {
    // TEMP for NWL
    for (let i = 1; i <= 5; ++i) {
      subjectSpillsSummaries.push(this.apiService.getSpillsSummary(this.siteName, currentYear - i));
    }

    this.subSpillsSummaries = forkJoin(...subjectSpillsSummaries).subscribe((spillagesSummaries: SpillagesSummary[]) => {

      this.noSpillsChart = new BarChart();
      this.noSpillsChart.createChart('noOfSpills', spillagesSummaries);

      this.qtySpillsChart = new BarChart();
      this.qtySpillsChart.createChart('qtyOfSpills', spillagesSummaries);

      this.durSpillsChart = new BarChart();
      this.durSpillsChart.createChart('durOfSpills', spillagesSummaries);
    });    

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    //let startDatePred = moment().subtract(2, 'year').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    //let endDatePred = moment().subtract(2, 'year').format('DD/MM/YYYY HH:mm');
    // let startDatePred = moment().subtract(3, 'month').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    // let endDatePred = moment().subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    // let startDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    // let endDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');

    //let startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    let startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('DD/MM/YYYY HH:mm'); // !!!! FOR DEMO !!!!
    let endDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');

    this.subVisDataPred = this.apiService.getVisualisationData(this.siteName, startDatePred, endDatePred, true)
      .subscribe((visData: VisualisationData) => { 

        visData.currentDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');;
        //visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
        visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('YYYY-MM-DD HH:mm:ss'); // !!!! FOR DEMO !!!!
        visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
        
        this.chart = new ChartSPS();
        this.chart.createChart(320, 'predictionCont', 'Prediction', visData);
    });

    // let startDateHist = moment().subtract(3, 'month').subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    // let endDateHist = moment().subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    let startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    let endDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    //let startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    //let endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);

    this.subjVisDataHists[0] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    if (!this.activatedWithCustomDataVis) {
      this.subVisDataHists[0] = this.subjVisDataHists[0].subscribe((visData: VisualisationData) => { 
        this.dataVisChart.createChart(420, 'dataVisContainer', 'Historic', visData);
        this.configureSlider();
      });
    }
    else {
      setTimeout(() => {
        let timestampPair = new TimestampPair(new Date(this.route.snapshot['queryParams']['start']),
                                              new Date(this.route.snapshot['queryParams']['end']));
        this.onJumpToVisualisation(timestampPair);
      }, 1);
      
    }
    
    // pre-emptive load last 3 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[1] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load last 6 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[2] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load last 12 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[3] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load prev 12 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(24, 'month').format('DD/MM/YYYY HH:mm');
    endDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(24, 'month').format('DD/MM/YYYY HH:mm');
    //endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[4] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // STILL USING DUMMY DATA for Inflow
    this.dryInflowChart = new InflowChart();
    let siteId = this.siteName.replace(/ /g, '_');
    // TEMP for NWL
    //this.dryInflowChart.createChart(siteId, 'dryWeatherInflow');

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }


  ngOnDestroy() {

    // only call unsubscribe on the subject once on the top level component
    this.apiService.siteSubject.unsubscribe();

    this.subVisDataPred.unsubscribe();

    this.unsubscribeVisDataHists();

    this.subSpillsSummaries.unsubscribe();

    this.cognitoService.refreshIdTokenStop();
    
    if (this.siteMap) { this.siteMap.abort(); }

    document.body.style.cursor = 'default';
  }


  unsubscribeVisDataHists() {

    for (let i = 0; i < 6; i++) {
      if (this.subVisDataHists[i]) { this.subVisDataHists[i].unsubscribe(); }
    }

    this.dateRangeLoading = false;
  }


  clearWaitCursorWhenRendered() {     
    
    // *****************                        ***************** 
    // ***************** TEMP CHANGE FOR WESSEX ***************** 
    // *****************                        ***************** 

    // if (this.chart          && this.chart.rendered          &&
    //     this.dataVisChart   && this.dataVisChart.rendered   &&
    //     this.noSpillsChart  && this.noSpillsChart.rendered  &&
    //     this.qtySpillsChart && this.qtySpillsChart.rendered &&
    //     this.durSpillsChart && this.durSpillsChart.rendered &&
    //     this.dryInflowChart && this.dryInflowChart.rendered) {

    if (this.chart          && this.chart.rendered &&
        this.dataVisChart   && this.dataVisChart.rendered) {

      clearInterval(this.timer);

      this.timer = null;

      this.dateRangeLoading = false;
      
      document.body.style.cursor = 'default';
    }
  }


  dataVisSelect(event: any, idx: number) {

    if (event) { event.preventDefault(); }

    if      (idx == 0)  { this.dataVisBtnText = 'Last Week'; }
    else if (idx == 1)  { this.dataVisBtnText = 'Last 3 Months'; }
    else if (idx == 2)  { this.dataVisBtnText = 'Last 6 Months'; }
    else if (idx == 3)  { this.dataVisBtnText = 'Last 12 Months'; }
    else if (idx == 4)  { this.dataVisBtnText = 'Previous 12 Months'; }
    else if (idx == 5)  { this.dataVisBtnText = 'Custom Date Range'; }
    else    { return; }

    this.unsubscribeVisDataHists();

    if (idx < CUSTOM_DATE_IDX) {

      this.showDateRange = false;

      this.lastIdx = idx;

      this.populateNewVisDataHist(idx);
    }
    else {

      this.showDateRange = true;

      // delay to allow angular to create the elements for the datepickers
      setTimeout(() => { this.configureDatePickers(this.lastIdx); }, 1);
    }
  }


  configureDatePickers(lastIdx: number, timestampPair?: TimestampPair) {  

    let callback = (function(fromDate, toDate) {
      this.selectedStartDate = fromDate;
      this.selectedEndDate = toDate;
    }).bind(this);

    JqUtils.datePickerSetup("#vis-data-date-from", "#vis-data-date-to", callback);

    if (lastIdx == LAST_IDX_SPILLAGE_TABLE) {
      $("#vis-data-date-from").datepicker("setDate", JqUtils.getDateFromDDMMYY(timestampPair.start));
      $("#vis-data-date-to").datepicker("setDate", JqUtils.getDateFromDDMMYY(timestampPair.end));
    }
    else {
      $("#vis-data-date-from").datepicker("setDate", JqUtils.getDateFromDDMMYY(this.startDatesHist[lastIdx]));
      $("#vis-data-date-to").datepicker("setDate", JqUtils.getDateFromDDMMYY(this.endDatesHist[lastIdx]));
    }
  }


  configureSlider() {

    let _this = this;

    this.yZoomMin = this.dataVisChart.yAxisMin;
    this.yZoomMax = this.dataVisChart.yAxisMax;

    $("#vis-data-scale").slider({
      range: true,
      min: this.yZoomMin,
      max: this.yZoomMax,
      values: [ this.yZoomMin, this.yZoomMax ],
      step: 1,
      slide: function(event, ui) {
        if (ui.values[0] >= ui.values[1]) {
          return false;
        }
        else {
          _this.yZoomMin = ui.values[0];
          _this.yZoomMax = ui.values[1];
          _this.dataVisChart.setYAxisZoom(_this.yZoomMin, _this.yZoomMax);
        }
      },
    });
  }


  loadCustomData(event: any) {

    let dateFrom = $("#vis-data-date-from").datepicker("getDate");
    let dateTo = $("#vis-data-date-to").datepicker("getDate");

    if (!dateFrom || !dateTo) {
      alert("You must specify a Start and End date !");
      return;
    }
    
    let maxDateTo = moment(dateFrom).add(1, 'year').toDate();

    if (dateTo.getTime() > maxDateTo.getTime()) {
      alert("You cannot specify a custom date range greater than 1 year !");
      return;
    }

    this.dateRangeLoading = true;

    this.populateNewVisDataHist(CUSTOM_DATE_IDX);
  }


  populateNewVisDataHist(idx: number) {  

    if (this.dataVisChart.c3Chart) {
      this.dataVisChart.c3Chart.destroy();
      this.dataVisChart.rendered = false;
    }
    
    if (idx == CUSTOM_DATE_IDX) {

      let dateFrom = $("#vis-data-date-from").datepicker("getDate");
      let dateTo = $("#vis-data-date-to").datepicker("getDate");

      let startDateHist = moment(dateFrom).format('DD/MM/YYYY HH:mm');
      let endDateHist = moment(dateTo).add(1, 'day').format('DD/MM/YYYY HH:mm');

      this.subjVisDataHists[idx] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);
    }

    this.subVisDataHists[idx] = this.subjVisDataHists[idx].subscribe((visData: VisualisationData) => {
      // a significant delay is required here for the UI to sort itself out before the chart starts rendering
      setTimeout(() => { 
        this.dataVisChart.createChart(420, 'dataVisContainer', 'Historic', visData);

        this.yZoomMin = this.dataVisChart.yAxisMin;
        this.yZoomMax = this.dataVisChart.yAxisMax;
        $("#vis-data-scale").slider("option", {min: this.yZoomMin, max: this.yZoomMax});
        $("#vis-data-scale").slider("values", [this.yZoomMin, this.yZoomMax]);
      }, 100);
    });

    document.body.style.cursor = 'wait';

    $("#dataVisContainer").text("Loading, please wait ...");

    if (this.timer == null) {
      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }


  dataVisImpact(event: any) {

    this.displayImpact = event.srcElement.checked
    this.dataVisChart.impactActive(this.displayImpact);
  }


  onJumpToVisualisation(timestampPair: TimestampPair) {

    this.dataVisBtnText = 'Custom Date Range';
    this.showDateRange = true;

    setTimeout(() => { 
      this.configureDatePickers(LAST_IDX_SPILLAGE_TABLE, timestampPair);
    }, 1);

    // delay to allow angular to create the elements for the datepickers
    setTimeout(() => { 

      $("#vis-data-date-from").datepicker("setDate", timestampPair.start);
      $("#vis-data-date-to").datepicker("setDate", timestampPair.end);
      this.loadCustomData(null);
      this.router.navigate(['./'], { fragment: 'dataVisHeader', relativeTo: this.route })
    }, 1);

  }

}
