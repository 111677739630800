import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoopAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared-components/navbar/navbar.component';
import { MenuLinksComponent } from './shared-components/navbar/menu-links/menu-links.component';
import { NotificationsComponent } from './shared-components/navbar/notifications/notifications.component';
import { AccountComponent } from './shared-components/navbar/account/account.component';
import { DateTimeComponent } from './shared-components/date-time/date-time.component';
import { MapComponent } from './shared-components/map/map.component';
import { RainfallRadarComponent } from './shared-components/map/rainfall-radar/rainfall-radar.component';
import { SpillageAlertsComponent } from './spill-preds/spillage-alerts/spillage-alerts.component';
import { BlockageAlertsComponent } from './live-alerts/blockage-alerts/blockage-alerts.component';
import { PumpAlertsComponent } from './live-alerts/pump-alerts/pump-alerts.component';
import { FooterComponent } from './shared-components/footer/footer.component';
import { FooterLinksComponent } from './shared-components/footer/footer-links/footer-links.component';
import { LiveAlertsComponent } from './live-alerts/live-alerts.component';
import { AlertsStatusBarComponent } from './shared-components/alerts-status-bar/alerts-status-bar.component';
import { StatusFloodsComponent } from './shared-components/alerts-status-bar/status-floods/status-floods.component';
import { MapViewComponent } from './map-view/map-view.component';
import { MapViewStatusBarComponent } from './map-view/map-view-status-bar/map-view-status-bar.component';
import { StatusSpillageComponent } from './map-view/map-view-status-bar/status-spillage/status-spillage.component';
import { StatusAlertsComponent } from './shared-components/status-alerts/status-alerts.component';
import { SiteSpsComponent } from './site-sps/site-sps.component';
import { ExpandedRowComponent } from './shared-components/expanded-row/expanded-row.component';
import { ExpandedRowPumpComponent } from './shared-components/expanded-row-pump/expanded-row-pump.component';
import { SiteStatusBarComponent } from './shared-components/site-status-bar/site-status-bar.component';
import { StatusSiteComponent } from './shared-components/site-status-bar/status-site/status-site.component';
import { SiteSpsSpillageEventsComponent } from './site-sps/site-sps-spillage-events/site-sps-spillage-events.component';
import { LoginComponent } from './login/login.component';
import { AllSitesComponent } from './all-sites/all-sites.component';
import { PumpStationsComponent } from './all-sites/pump-stations/pump-stations.component';
import { CsosComponent } from './all-sites/csos/csos.component';
import { WwtwsComponent } from './all-sites/wwtws/wwtws.component';
import { NetworkAnalysisComponent } from './network-analysis/network-analysis.component';
import { NetworkAnalysisStatusBarComponent } from './network-analysis/network-analysis-status-bar/network-analysis-status-bar.component';
import { NetworkSpillagesSummariesComponent } from './network-analysis/network-spillages-summaries/network-spillages-summaries.component';
import { NumberPlusMinusPipe } from './classes/number-plus-minus.pipe';
import { NetworkSpillageEventsComponent } from './network-analysis/network-spillage-events/network-spillage-events.component';
import { SiteCsoComponent } from './site-cso/site-cso.component';
import { SiteWwtwComponent } from './site-wwtw/site-wwtw.component';
import { SiteCsoSpillageEventsComponent } from './site-cso/site-cso-spillage-events/site-cso-spillage-events.component';
import { SiteCsoSpsCombinedComponent } from './site-cso/site-cso-sps-combined/site-cso-sps-combined.component';
import { SiteCsoCombinedSpillageEventsComponent } from './site-cso/site-cso-combined-spillage-events/site-cso-combined-spillage-events.component';
import { Login2Component } from './login2/login2.component';
import { NetworkInterdependComponent } from './network-interdepend/network-interdepend.component';
import { AnomalyAlertsComponent } from './live-alerts/anomaly-alerts/anomaly-alerts.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SpillPredsComponent } from './spill-preds/spill-preds.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MenuLinksComponent,
    NotificationsComponent,
    AccountComponent,
    DateTimeComponent,
    MapComponent,
    RainfallRadarComponent,
    SpillageAlertsComponent,
    BlockageAlertsComponent,
    PumpAlertsComponent,
    FooterComponent,
    FooterLinksComponent,
    LiveAlertsComponent,
    AlertsStatusBarComponent,
    StatusFloodsComponent,
    MapViewComponent,
    MapViewStatusBarComponent,
    StatusSpillageComponent,
    StatusAlertsComponent,
    SiteSpsComponent,
    ExpandedRowComponent,
    ExpandedRowPumpComponent,
    SiteStatusBarComponent,
    StatusSiteComponent,
    SiteSpsSpillageEventsComponent,
    LoginComponent,
    AllSitesComponent,
    PumpStationsComponent,
    CsosComponent,
    WwtwsComponent,
    NetworkAnalysisComponent,
    NetworkAnalysisStatusBarComponent,
    NetworkSpillagesSummariesComponent,
    NumberPlusMinusPipe,
    NetworkSpillageEventsComponent,
    SiteCsoComponent,
    SiteWwtwComponent,
    SiteCsoSpillageEventsComponent,
    SiteCsoSpsCombinedComponent,
    SiteCsoCombinedSpillageEventsComponent,
    Login2Component,
    NetworkInterdependComponent,
    AnomalyAlertsComponent,
    ChangePasswordComponent,
    SpillPredsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatPaginatorModule,
    MatTooltipModule,
    NoopAnimationsModule,
    FormsModule
  ],
  providers: [ 
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
