import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent implements OnInit {

  today : Date;

  constructor() { }

  ngOnInit() {
    //this.today = new Date();
    this.today = new Date('2020-07-25T12:55:00');
  }

}
