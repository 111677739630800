import { ChartAPI, generate } from 'c3';
import { CsvUtil } from './csv-util';
import { VisualisationData } from './visualisation-data';

import * as moment from 'moment';

declare var $: any;

export class ChartCSO {

  c3Chart: ChartAPI = null;

  chart1data: any = null;
  chart2data: any = null;
  chart3data: any = null;
  chart4data: any = null;
  chart5data: any = null;
  chart6data: any = null;

  rendered: boolean;
  initialised: boolean;

  startTime: number = 0;
  endTime: number = 0;

  xAxisMin = undefined;
  xAxisMax = undefined;

  yAxisMin:number = 0;
  yAxisMax:number = 0;

  maxRainfall:number = 0;

  constructor() { }

  public createChart(height: number, chartContainerId: string, type: string, visData: VisualisationData)
  {
    //console.log('CSO Chart render started');
    //this.startTime = (new Date()).valueOf();

    // chart may be regenerated so always null
    this.chart1data = null;
    this.chart2data = null;
    this.chart3data = null;
    this.chart4data = null;
    this.chart5data = null;
    this.chart6data = null;

    this.yAxisMin = 0;
    this.yAxisMax = 110;

    this.rendered = false;
    this.initialised = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }
    
    // SLM levels
    this.chart1data = CsvUtil.getCSVData(visData.slmData, 'x1','SLM Levels');
    this.adjustToTimezone(this.chart1data);

    // High and Low historical prediction thresholds
    if (visData.lvlpredData != null) {
      this.chart5data = CsvUtil.getLevelPredData(visData.lvlpredData, 'x5', 'Hi Pred Thresholds'); 
      this.chart6data = CsvUtil.getLevelPredData(visData.lvlpredData, 'x6', 'Low Pred Thresholds');
    }

    // High and Low future prediction thresholds
    if (type !== 'Blockage' && type !== 'Anomaly') {
      if (visData.lvlpredPredictionData != null) {
        if (this.chart5data) {
          this.chart5data = CsvUtil.addLevelPredData(this.chart5data, visData.lvlpredPredictionData, 'Hi Pred Thresholds');
        }
        else {
          this.chart5data = CsvUtil.getLevelPredData(visData.lvlpredPredictionData, 'x5', 'Hi Pred Thresholds');
        }
        if (this.chart6data) {
          this.chart6data = CsvUtil.addLevelPredData(this.chart6data, visData.lvlpredPredictionData, 'Low Pred Thresholds');
        }
        else {
          this.chart6data = CsvUtil.getLevelPredData(visData.lvlpredPredictionData, 'x6', 'Low Pred Thresholds');
        }
      }
    }

    // High and Low prediction thresholds - adjust for timezone
    if (this.chart5data) { this.adjustToTimezone(this.chart5data); }
    if (this.chart6data) { this.adjustToTimezone(this.chart6data); }

    //
    // THIRD DATA ARRAY REQUIRED FOR OVERFLOW PREDICTIONS - GREEN COLUMNS AS PER SPS
    //
    // if (type === 'Prediction') {
    //   CsvUtil.addCSVData(this.chart1data, visData.overflowPredictionData); 
    // }

    // TEMP - date restructure when using local data files
    // for (let i = 1; i < this.chart1data.xdata.length; i++) {
    //   let x:string = this.chart1data.xdata[i];
    //   this.chart1data.xdata[i] = x.substr(6, 4) + '-' + x.substr(3, 2) + '-' + x.substr(0, 2) + ' ' + x.substr(11, 5) + ':00';
    // }
    // TEMP

    // expand y axis to accommodate SLM spikes above 120 or anomalous negatives
    for (let i = 1; i < this.chart1data.ydata.length; ++i) {
      if (parseFloat(this.chart1data.ydata[i]) > this.yAxisMax) {
        this.yAxisMax = parseFloat(this.chart1data.ydata[i]); 
      }
      if (parseFloat(this.chart1data.ydata[i]) < this.yAxisMin) {
        this.yAxisMin = parseFloat(this.chart1data.ydata[i]); 
      }
    }

    // rainfall
    this.chart2data = CsvUtil.getCSVData(visData.rainfallData, 'x2','Rainfall');

    // predicted rainfall
    if (type !== 'Blockage' && type !== 'Anomaly') {
      if (visData.lvlpredPredictionData != null) {
        CsvUtil.addCSVData(this.chart2data, visData.rainfallPredictionData); 
      }
    }

    // reduce rainfall data for historical charts (chart speed optimisation) 
    if (type === 'Historic') {
      this.chart2data = CsvUtil.reduceChartData(this.chart2data);
    }
    this.adjustToTimezone(this.chart2data);

    // TEMP - date restructure when using local data files
    // for (let i = 1; i < this.chart2data.xdata.length; i++) {
    //   let x:string = this.chart2data.xdata[i];
    //   this.chart2data.xdata[i] = x.substr(6, 4) + '-' + x.substr(3, 2) + '-' + x.substr(0, 2) + ' ' + x.substr(11, 5) + ':00';
    // }
    // TEMP

    // calc max rainfall
    this.maxRainfall = 5;
    for (let i = 1; i < this.chart2data.ydata.length; ++i) {
      if (this.chart2data.ydata[i] > this.maxRainfall) { this.maxRainfall = this.chart2data.ydata[i]; }
    }
    this.maxRainfall = Math.ceil(this.maxRainfall);

    // charts will automatically extend to data width so force if necessary
    if (type === 'Prediction') {
      this.chart2data = this.forceChartWidth(this.chart2data, visData.startDate,  visData.endDate);      
    }

    // predicted slm levels
    if (type !== 'Blockage' && type !== 'Anomaly') {
      if (visData.lvlpredPredictionData != null) {
        this.chart4data = CsvUtil.getCSVData(visData.slmPredictionData, 'x4', 'Predicted Levels');
        this.adjustToTimezone(this.chart4data);

        // bridge any gap between slm data and predicted slm data using historic slm predictions
        let histPredData = null;

        if (type !== 'Prediction' && visData.lvlpredData != null) {
          histPredData = CsvUtil.getLevelPredData(visData.lvlpredData, 'N/A', 'Predictions');
          this.adjustToTimezone(histPredData);
        }

        if (histPredData) {
          let slmMaxDate = '0001-01-01 00:00:00';
          if (this.chart1data && this.chart1data.xdata.length >= 2) {
            slmMaxDate = this.chart1data.xdata[this.chart1data.xdata.length - 1];
          }
          this.chart4data = this.prependHistPreds(this.chart4data, histPredData, slmMaxDate);
        }

        // expand y axis to accommodate predicted SLM spikes above 120
        for (let i = 1; i < this.chart4data.ydata.length; ++i) {
          if (parseFloat(this.chart4data.ydata[i]) > this.yAxisMax) {
            this.yAxisMax = parseFloat(this.chart4data.ydata[i]); 
          }
          if (parseFloat(this.chart4data.ydata[i]) < this.yAxisMin) {
            this.yAxisMin = parseFloat(this.chart4data.ydata[i]); 
          }
        }
      }
    }

    //if (this.chart4data) { this.chart4data = this.truncateToDate(this.chart4data, '2020-06-25 10:00:00'); }
    //if (this.chart5data) { this.chart5data = this.truncateToDate(this.chart5data, '2020-06-25 10:00:00'); }
    //if (this.chart6data) { this.chart6data = this.truncateToDate(this.chart6data, '2020-06-25 10:00:00'); }

    // don't let any historic data through
    if (type === 'Prediction') {

      this.chart4data = this.trimToCurrentTime(this.chart4data);
      this.chart2data = this.trimToCurrentTime(this.chart2data);
      this.chart5data = this.trimToCurrentTime(this.chart5data);
      this.chart6data = this.trimToCurrentTime(this.chart6data);
    }

    // set up chart grids
    let regions = [];
    let grid = {};

    if (type === 'Prediction') {

      let grid0 = visData.currentDate;
      let grid1 = CsvUtil.getGridTime(visData.currentDate, 1, 'hour');
      let grid2 = CsvUtil.getGridTime(visData.currentDate, 2, 'hour');
      let grid3 = CsvUtil.getGridTime(visData.currentDate, 3, 'hour');
      let grid4 = CsvUtil.getGridTime(visData.currentDate, 4, 'hour');
      let grid5 = CsvUtil.getGridTime(visData.currentDate, 5, 'hour');
      let grid6 = CsvUtil.getGridTime(visData.currentDate, 6, 'hour');

      regions.push({axis: 'x', start: visData.currentDate, end: visData.endDate, class: 'region-predicted'});
      grid['x'] = {
        lines: [
          {value: grid0, text: 'Now'},
          {value: grid1, text: '+1hr'},
          {value: grid2, text: '+2hrs'},
          {value: grid3, text: '+3hrs'},
          {value: grid4, text: '+4hrs'},
          {value: grid5, text: '+5hrs'},
          {value: grid6, text: '+6hrs'}
        ]
      }; 

      this.chart3data = CsvUtil.getPredictedOflowData(visData.overflowPredictionData, 100, 'x3','Predicted Spillage');
      this.adjustToTimezone(this.chart3data);
    }

    // C3 does not like empty arrays, so...
    let xs = {};
    let columns = [];
    let pattern = [];
    let types = {};

    //
    // this is temporary for WW
    //
    if (type !== 'Prediction') {
      if (this.chart1data.xdata.length >= 3) {
        xs['SLM Levels'] = 'x1';
        columns.push(this.chart1data.xdata);
        columns.push(this.chart1data.ydata);
        pattern.push('#A0522D');
      }
    }
    
    if (this.chart2data.xdata.length >= 2) { 
      xs['Rainfall'] = 'x2';
      columns.push(this.chart2data.xdata);
      columns.push(this.chart2data.ydata);
      pattern.push('#119ade');
    }

    if (this.chart3data && this.chart3data.xdata.length >= 2) { 
      xs['Predicted Spillage'] = 'x3';
      columns.push(this.chart3data.xdata);
      columns.push(this.chart3data.ydata);
      pattern.push('#9a82fb');
      types['Predicted Spillage'] = 'area';
    }

    if (this.chart4data && this.chart4data.xdata.length >= 2) { 
      xs['Predicted Levels'] = 'x4';
      columns.push(this.chart4data.xdata);
      columns.push(this.chart4data.ydata);
      pattern.push('#228844');
    }

    if (this.chart5data && this.chart5data.xdata.length >= 2) { 
      xs['Hi Pred Thresholds'] = 'x5';
      columns.push(this.chart5data.xdata);
      columns.push(this.chart5data.ydata);
      pattern.push('#aaaaaa');
    }

    if (this.chart6data && this.chart6data.xdata.length >= 2) { 
      xs['Low Pred Thresholds'] = 'x6';
      columns.push(this.chart6data.xdata);
      columns.push(this.chart6data.ydata);
      pattern.push('#aaaaaa');
    }

    regions.push({axis: 'y', start: 100, class: 'regionOverflow'});
    grid['y'] = {
      lines: [
        {value: 100, text: 'Overflow', position: 'start', class:'overflow-gridline'}
      ]
    }; 

    let zoomConfig: any = { enabled: false };
    let tooltipGrouped = false;
    let tickConfig: any = { culling: { max: 16 }, count: 16, format: '%H:%M' }; 
    let subchartConfig: any = { show: false };

    if (type === 'Historic') {
      //zoomConfig = { enabled: true, rescale: true, type: 'drag' };
      //tickConfig = { culling: { max: 20 }, count: 20, format: '%d/%m/%Y' }; 
      tickConfig = { format: '%d/%m/%Y %H:%M', fit: false }; 
      subchartConfig = { show: true };
    }  
    else if (type === 'Blockage' || type === 'Anomaly') {
      tickConfig = { culling: { max: 11 }, count: 11, format: '%d/%m/%Y %H:%M', fit: false }; 
    }
    
    if (type === 'Blockage' || type === 'Anomaly') {
      regions.push({axis: 'x', start: visData.alertStartDate, end: visData.alertEndDate, class: 'region-predicted'});
      grid['x'] = {
        lines: [
          {value: visData.alertStartDate, text: type + ' Start'},
          {value: visData.alertEndDate, text: type + ' End'}
        ]
      }; 
    }

    // initially hide slm predictions
    let hide = undefined;
    if (this.chart4data && this.chart4data.xdata.length >= 2 && type !== 'Prediction') {
      hide = ['Predicted Levels'];
    }

    // round yAxisMax and yAxisMin
    this.yAxisMax = (Math.ceil(this.yAxisMax / 10.0)) * 10;
    this.yAxisMin = (Math.floor(this.yAxisMin / 10.0)) * 10;

    // create the chart
    this.c3Chart = generate({
      bindto: '#' + chartContainerId,
      size: {
        height: height
      },
      data: {
        xFormat: '%Y-%m-%d %H:%M:%S',
        xs: xs,
        columns: columns,     
        // types: {
        //   Spillage: 'area',
        //   StormHarvesterActive: 'area',
        // },
        types: types,
        axes: {
          'SLM Levels' : 'y',
          'Rainfall' : 'y2',
        },
        hide: hide
      },
      color: { pattern: pattern },
      legend: { show: true },    
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          label: {
            text: 'Time/Duration',
            position: 'outer-center'
          },		            
          tick: tickConfig,
          min: this.xAxisMin,
          max: this.xAxisMax
        },
        y:{		  
          show:true,  
          min: this.yAxisMin,
          max: this.yAxisMax,        
          label: { text: 'SLM Levels', position: 'outer-middle' }
        },
        y2:{	
          show:true,
          min: 0,
          max: this.maxRainfall,	            
          label: { text: 'Rainfall (mm)', position: 'outer-middle' }
        }
      },
      point: { show: false },
      zoom: zoomConfig,
      tooltip: {
        show: true,
        grouped: tooltipGrouped,
        position: (data, width, height, element) => {
          if (data[0].id === 'Rainfall' ||
              data[0].id === 'SLM Levels' ||
              data[0].id === 'Predicted Levels' ||
              data[0].id === 'Hi Pred Thresholds' ||
              data[0].id === 'Low Pred Thresholds') {
              // || data[0].id === 'Predicted Spillage') {
            return { top: 40, left: 0 };
          }
          return { top: -1000, left: 0 };
        } 
      },
      regions: regions,
      grid: grid,
      subchart: subchartConfig,
      onrendered: () => { 
        this.rendered = true;
        if (this.startTime > 0) {
          //this.endTime = (new Date()).valueOf();
          this.endTime = (new Date('2020-07-25T12:55:00')).valueOf();
          console.log('CSO Chart creation took ' + (this.endTime - this.startTime) + 'ms');
          this.startTime = 0;
        }

        if (!this.initialised) {
          // stop the row containing the chart from collapsing when a legend is clicked
          $(".c3-legend-item").click(function(event){event.stopPropagation();});
          this.initialised = true;
        }
      }		
    });
  }


  private adjustToTimezone(chartdata: any) {

    for (let i = 1; i < chartdata.xdata.length; i++) { 
      let momentLocalTime = moment.utc(chartdata.xdata[i], 'YYYY-MM-DD HH:mm:ss').local();
      chartdata.xdata[i] = momentLocalTime.format('YYYY-MM-DD HH:mm:ss');
    }
  }


  private trimToCurrentTime(chartdata: any) : any {

    if (chartdata.xdata.length > 0) {
        
      let temp = { xdata: [], ydata: [] };
      temp.xdata.push(chartdata.xdata[0]);
      temp.ydata.push(chartdata.ydata[0]);
      
      let momentLocalTimeNow = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss');
      
      for (let i = 1; i < chartdata.xdata.length; i++) {
        
        let momentLocalTime = moment(chartdata.xdata[i], 'YYYY-MM-DD HH:mm:ss');

        // trim any old predictions
        if (momentLocalTime > momentLocalTimeNow) {
          temp.xdata.push(chartdata.xdata[i]);
          temp.ydata.push(chartdata.ydata[i]);
        }
      }
      return temp;
    }

    return chartdata;
  }


  private forceChartWidth(chartdata, startDate, endDate) {

    // we want the chart to fully extend from -6hrs to +6hrs
    // so we may need to hack in null values at these times
    // as c3 chart does not do the right thing

    this.xAxisMin = startDate;
    this.xAxisMax = endDate;

    if (chartdata.xdata.length > 0) {

      let temp = { xdata: [], ydata: [] };

      temp.xdata.push(chartdata.xdata[0]);
      temp.ydata.push(chartdata.ydata[0]);

      if (chartdata.xdata.length > 1) {

        let startDate = moment(chartdata.xdata[1], 'YYYY-MM-DD HH:mm:ss').toDate();
        let minDate = moment(this.xAxisMin, 'YYYY-MM-DD HH:mm:ss').toDate();

        if (minDate < startDate) {
          temp.xdata.push(this.xAxisMin);
          temp.ydata.push(null);
        }
      }
      else {
        temp.xdata.push(this.xAxisMin);
        temp.ydata.push(null);
      }

      for (let i = 1; i < chartdata.xdata.length; i++ ) {

        temp.xdata.push(chartdata.xdata[i]);
        temp.ydata.push(chartdata.ydata[i]);
      }

      if (chartdata.xdata.length > 2) {

        let endDate = moment(chartdata.xdata[chartdata.xdata.length - 1], 'YYYY-MM-DD HH:mm:ss').toDate();
        let maxDate = moment(this.xAxisMax, 'YYYY-MM-DD HH:mm:ss').toDate();

        if (maxDate > endDate) {
          temp.xdata.push(this.xAxisMax);
          temp.ydata.push(null);
        }
      }
      else {
        temp.xdata.push(this.xAxisMax);
        temp.ydata.push(null);
      }

      return temp;
    }
    else {
      return chartdata;
    }      
  }


  private prependHistPreds(chartdata, histPredData, slmMaxDate) {

    let temp = { xdata: [], ydata: [] };

    temp.xdata.push(chartdata.xdata[0]);
    temp.ydata.push(chartdata.ydata[0]);

    // prepend the historical predictions
    for (let i = 1; i < histPredData.xdata.length; i++) {
      // we can do a string compare here due to the date format
      if (histPredData.xdata[i] > slmMaxDate) {
        temp.xdata.push(histPredData.xdata[i]);
        temp.ydata.push(histPredData.ydata[i]);
      }
    }

    // append the predictions
    for (let i = 1; i < chartdata.xdata.length; i++) {
      if (chartdata.xdata[i] > slmMaxDate) {
        temp.xdata.push(chartdata.xdata[i]);
        temp.ydata.push(chartdata.ydata[i]);
      }
    }

    return temp;
  }


  private truncateToDate(chartdata, date) {
    
    let temp = { xdata: [], ydata: [] };

    temp.xdata.push(chartdata.xdata[0]);
    temp.ydata.push(chartdata.ydata[0]);

    for (let i = 1; i < chartdata.xdata.length; i++) {
      // we can do a string compare here due to the date format
      if (chartdata.xdata[i] > date) {
        temp.xdata.push(chartdata.xdata[i]);
        temp.ydata.push(chartdata.ydata[i]);
      }
    }

    return temp;
  }


  public setYAxisZoom(newMin, newMax) {

    this.c3Chart.axis.min({y: newMin, y2: 0});
    this.c3Chart.axis.max({y: newMax, y2: this.maxRainfall});
  }
  
}
