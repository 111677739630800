import * as moment from 'moment';

declare var $: any;

export class JqUtils {

  static datePickerSetup(fromId, toId, callback) : void {

    $(fromId).datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd/mm/yy'
    })
    .on("change", function() {
      let fromDate = JqUtils.getDateFromDDMMYY(this.value);
      let toDate = $(toId).datepicker("getDate");
      if (fromDate) { 
        if (!toDate) {
          $(toId).datepicker("option", "defaultDate", fromDate);
        }
        else if (fromDate > toDate) {
          $(toId).datepicker("setDate", fromDate);
        }
      }
      callback(fromDate, toDate);
    });

    $(toId).datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd/mm/yy'
    })
    .on("change", function() {
      let toDate = JqUtils.getDateFromDDMMYY(this.value);
      let fromDate = $(fromId).datepicker("getDate");
      if (toDate) {
        if (!fromDate) {
          $(fromId).datepicker("option", "defaultDate", toDate);
        }
        else if (toDate < fromDate) {
          $(fromId).datepicker("setDate", toDate);
        }
      }
      callback(fromDate, toDate);
    });

  }


  static getDateFromDDMMYY(text) : Date {
    
    let date;
    try { date = $.datepicker.parseDate('dd/mm/yy', text); }
    catch( error ) { date = null; }
    return date;
  }

}