import { Component, OnInit, ViewChildren, QueryList  } from '@angular/core';
import { ExpandedRowComponent } from '../../shared-components/expanded-row/expanded-row.component';
import { Site } from '../../classes/site';
import { Sites } from '../../classes/sites';
import { ApiService } from '../../classes/api.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';

@Component({
  selector: 'app-anomaly-alerts',
  templateUrl: './anomaly-alerts.component.html',
  styleUrls: ['./anomaly-alerts.component.scss']
})
export class AnomalyAlertsComponent implements OnInit {

  @ViewChildren(ExpandedRowComponent) expandedRows: QueryList<ExpandedRowComponent>;

  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService) {
    this.filterSortSites = new FilterSortSites(20);
  }

  ngOnInit() {  

    console.log('ngOnInit');
    
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      //this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.sitesReceived = Sites.buildAlertsSiteArray(sites, 'Anomaly');
      this.filterSortSites.removeNonAffected('anomaly');
      this.filterSortSites.process();
      console.log('observed');
      console.log(this.filterSortSites);
      //console.trace();
    });
  }

  createDynamicElements(site: Site) {

    let expandedRow = this.expandedRows.find(er => er.site.id2 == site.id2);

    expandedRow.createChart(site, 'anomChartCont');

    setTimeout(function(){ expandedRow.createMap(site); }, 500);
  }

}

