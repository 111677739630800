import { SpillageLevel, SpillageLevels } from './spillage-levels';

export class SpillageEvent {
  constructor(
    public siteName: string,
    public spillageLevel: SpillageLevel,
    public startTimestamp: Date,
    public startTimestampDisplay: string,
    public endTimestamp: Date,
    public endTimestampDisplay: string,
    public duration: string,
    public durationHours: number,
    public savedDuration: string,
    public estimatedLitres: number,
    public savedLitres: number,
    public savedPercentage: number,
    public rainfallIntensity: number,
    public causeRain: boolean,
    public causePump: boolean,
    public causeBlockage: boolean
  ) {}
}