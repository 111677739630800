export class PumpAlertLevel {
   
  constructor(public id: number, public text: string, public cssClass: string) { }
}

export class PumpAlertLevels {

  private severityLevels: PumpAlertLevel[] = [];

  constructor() {
    this.severityLevels.push(new PumpAlertLevel(0, 'NO ALERT', 'alert-green'));
    this.severityLevels.push(new PumpAlertLevel(1, 'LOW', 'alert-yellow'));
    this.severityLevels.push(new PumpAlertLevel(2, 'MODERATE', 'alert-orange'));
    this.severityLevels.push(new PumpAlertLevel(3, 'NOT ACTIVE', 'alert-red'));
  }

  getLevelFromId(id: number): PumpAlertLevel {
    if (id >= 1 && id <= 3) {
      return this.severityLevels[id];
    }
    else {
      return this.severityLevels[0];
    }
  }

  getLevelFromText(text: string): PumpAlertLevel {
    let severityLevel = this.severityLevels.find(sl => sl.text == text);

    if (severityLevel) {
      return severityLevel;
    }
    else {
      return this.severityLevels[0];
    }
  }

  // FOR TESTING ONLY
  getRandom(): PumpAlertLevel {
    return this.severityLevels[Math.floor(Math.random() * 4)];
  }

}