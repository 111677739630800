export class BlockageLevel {
   
  constructor(public id: number, public text: string, public cssClass: string) { }
}

export class BlockageLevels {

  private severityLevels: BlockageLevel[] = [];

  constructor() {
    // this.severityLevels.push(new BlockageLevel(0, 'NONE', ''));
    // this.severityLevels.push(new BlockageLevel(1, 'MINOR', 'alert-yellow'));
    // this.severityLevels.push(new BlockageLevel(2, 'PARTIALLY', 'alert-orange'));
    // this.severityLevels.push(new BlockageLevel(3, 'FULLY', 'alert-red'));

    this.severityLevels.push(new BlockageLevel(0, 'NONE', 'alert-grey'));
    this.severityLevels.push(new BlockageLevel(1, 'LOW', 'alert-yellow'));
    this.severityLevels.push(new BlockageLevel(2, 'MODERATE', 'alert-orange'));
    this.severityLevels.push(new BlockageLevel(3, 'HIGH', 'alert-red'));
    
  }

  getLevelFromId(id: number): BlockageLevel {
    if (id >= 1 && id <= 3) {
      return this.severityLevels[id];
    }
    else {
      return this.severityLevels[0];
    }
  }

  getLevelFromText(text: string): BlockageLevel {
    let severityLevel = this.severityLevels.find(sl => sl.text == text);

    if (severityLevel) {
      return severityLevel;
    }
    else {
      return this.severityLevels[0];
    }
  }

  // FOR TESTING ONLY
  getRandom(): BlockageLevel {
    return this.severityLevels[Math.floor(Math.random() * 4)];
  }

}