import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs';
import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { Site } from '../classes/site';
import { MessageService, MessageId } from '../classes/message.service';
import { MapComponent } from '../shared-components/map/map.component';
import * as moment from 'moment';

const DATA_REFRESH_INTERVAL_SECONDS = (60 * 15);
const USER_INACTIVITY_TIMEOUT_SECONDS = (60 * 5);

@Component({
  selector: 'app-live-alerts',
  templateUrl: './live-alerts.component.html',
  styleUrls: ['./live-alerts.component.scss']
})
export class LiveAlertsComponent implements OnInit {

  @ViewChild(MapComponent) mapComponent:MapComponent;

  mapCheckTimer: any = null;

  sites: Site[] = [];

  blockageTabClass = "nav-link active";
  anomalyTabClass = "nav-link";
  pumpTabClass = "nav-link";
  
  blockageTabContentClass = "tab-pane fade show active";
  anomalyTabContentClass = "tab-pane fade";
  pumpTabContentClass = "tab-pane fade";

  subFileContents: Subscription;

  refreshViewTimeout: NodeJS.Timer = null;
  timeLastDataRetrieval:moment.Moment = null;
  timeLastUserAction:moment.Moment = null;


  constructor(private apiService: ApiService,
              private cognitoService: CognitoService,
              private messageService: MessageService,
              private router: Router,
              private route: ActivatedRoute) {
    
    this.refreshViewTimeout = setInterval(() => { this.checkForRefreshView(); }, 1000);
    this.timeLastDataRetrieval = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss');
    this.timeLastUserAction = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss');
  }


  checkForRefreshView() {

    let secondsSinceLastDataRetrieval = moment().diff(this.timeLastDataRetrieval, 'seconds');
    let secondsSinceLastUserAction = moment().diff(this.timeLastUserAction, 'seconds');

    if (secondsSinceLastDataRetrieval > DATA_REFRESH_INTERVAL_SECONDS &&
        secondsSinceLastUserAction > USER_INACTIVITY_TIMEOUT_SECONDS) {
      this.initDataRetrieval();
    }
  }


  @HostListener('window:mousemove') refreshUserState() {
    this.timeLastUserAction = moment();
  }


  initDataRetrieval() {

    document.body.style.cursor = 'wait';
    this.timeLastDataRetrieval = moment();
    this.apiService.getSites(true);
  }

              
  ngOnInit() {
    
    document.head.title = "Live Alerts - StormHarvester";
    document.body.className = "dashboard";

    document.body.style.cursor = 'wait';
    this.mapCheckTimer = setInterval(() => { 
      if (this.mapComponent.mapReady()) { 
        this.mapComponent.setCursor('wait');
        clearInterval(this.mapCheckTimer);
      }
    }, 100);
    
    this.apiService.getSites();
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {
      this.sites = sites;
      document.body.style.cursor = 'default';
      this.mapComponent.setCursor('');
    });

    this.messageService.msgSubject.subscribe((msgId: MessageId) => this.processMessage(msgId));

    this.cognitoService.refreshIdTokenStart();
  } 
  
  
  ngOnDestroy() {

    // only call unsubscribe on the subject once on the top level component
    this.apiService.sitesSubject.unsubscribe();

    if (this.subFileContents) { this.subFileContents.unsubscribe(); }

    this.cognitoService.refreshIdTokenStop();

    document.body.style.cursor = 'default';
    this.mapComponent.setCursor('');
  }


  processMessage(msgId: MessageId) {

    let allowedMessages = [
      MessageId.FILTER_BLOCKAGES_NONE, 
      MessageId.FILTER_BLOCKAGES_HIGH,
      MessageId.FILTER_BLOCKAGES_MODERATE,
      MessageId.FILTER_BLOCKAGES_LOW,
      MessageId.NAVIGATE_PUMP_ALERTS,
      MessageId.NAVIGATE_ANOMALY_ALERTS
    ];

    if (!allowedMessages.includes(msgId)) {
      return;
    }

    this.blockageTabClass = "nav-link";
    this.pumpTabClass = "nav-link";
    this.anomalyTabClass = "nav-link";

    this.blockageTabContentClass = "tab-pane fade";
    this.pumpTabContentClass = "tab-pane fade";
    this.anomalyTabContentClass = "tab-pane fade";

    switch (msgId) {

      case MessageId.FILTER_BLOCKAGES_NONE :
      case MessageId.FILTER_BLOCKAGES_HIGH :
      case MessageId.FILTER_BLOCKAGES_MODERATE :
      case MessageId.FILTER_BLOCKAGES_LOW : {
        this.blockageTabClass = "nav-link active";
        this.blockageTabContentClass = "tab-pane fade show active";
        setTimeout(() => { this.router.navigate(['./'], { fragment: 'blockage', relativeTo: this.route }) }, 1);
        break;
      }

      case MessageId.NAVIGATE_PUMP_ALERTS : {
        this.pumpTabClass = "nav-link active";
        this.pumpTabContentClass = "tab-pane fade show active";
        setTimeout(() => { this.router.navigate(['./'], { fragment: 'pump', relativeTo: this.route }) }, 1);
        break;
      }

      case MessageId.NAVIGATE_ANOMALY_ALERTS : {
        this.anomalyTabClass = "nav-link active";
        this.anomalyTabContentClass = "tab-pane fade show active";
        setTimeout(() => { this.router.navigate(['./'], { fragment: 'anomaly', relativeTo: this.route }) }, 1);
        break;
      }
      
    } 
  }


  getHistNonSpillAlerts() {

    this.subFileContents = this.apiService.getFileContents('HistoricNonSpillAlerts.txt')
      .subscribe((fileContents: string) => {

        let filename = 'HistoricNonSpillAlerts.txt';    
        let blob = new Blob([fileContents], {type: 'text/plain'});

        // is IE ? :(
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, filename);
        }
        else {
          let a = document.createElement('a');
          a.download = filename;
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
          a.click();
          window.URL.revokeObjectURL(a.href);
          a.remove();
        }
    });    
  }

}
