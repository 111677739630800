import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../classes/api.service';
import { Site } from '../../../classes/site';

@Component({
  selector: 'app-status-spillage',
  templateUrl: './status-spillage.component.html',
  styleUrls: ['./status-spillage.component.scss']
})
export class StatusSpillageComponent implements OnInit {

  spillageLitres: number = 0;
  savingsLitres: number = 0;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
 
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.spillageLitres = 0;
      this.savingsLitres = 0;

      sites.forEach(site => {

        site.spillageAlerts.forEach(spillageAlert => {

          this.spillageLitres += spillageAlert.quantity;
        });
      }); 
    }); 
  }

}
