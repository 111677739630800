import { Injectable } from '@angular/core';
import { HttpClient }  from '@angular/common/http';
import { Subject, AsyncSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Site } from './site';
import { Sites } from './sites';
import { LiveAlerts } from '../classes/live-alerts';
import { SpillageEvent } from './spillage-event';
import { SpillageEvents } from './spillage-events';
import { SpillagesSummary } from './spillages-summary';
import { SpillagesSummaries } from './spillages-summaries';
import { VisualisationData } from './visualisation-data';
import { CsoSpsCombined } from './cso-sps-combined';
import { FloodWarningData } from './flood-warning-data';
import { RuntimeConfig } from './runtime-config';
import { TimeUtil } from './time-util';

import * as moment from 'moment';

declare var apigClientFactory: any;
var ALERT_DELAY = 1000;

@Injectable({ providedIn: 'root' })
export class ApiService {

  sitesSubject: Subject<Site[]>;
  siteSubject: Subject<Site>;

  apigClient: any;


  /**********************************
  * constructor
  **********************************/
  constructor(private http: HttpClient,
              private runtimeConfig: RuntimeConfig) {
  }


  /**********************************
  * setCredentials
  **********************************/
  setCredentials(awsAccessKeyId: string, awsSecretAccessKey: string) {
    
    if (!sessionStorage) {
      alert('browser does not support session storage to allow use of file based credentials!');
      return;
    }

    sessionStorage.setItem('awsAccessKeyId', awsAccessKeyId);
    sessionStorage.setItem('awsSecretAccessKey', awsSecretAccessKey);
  }
 

  /**********************************
  * checkCreateApiClient
  **********************************/
  checkCreateApiClient() {
    if (!this.apigClient) {
      this.apigClient = apigClientFactory.newClient({
        //apiKey: '?',
        //accessKey: sessionStorage.getItem('awsAccessKeyId'),
        //secretKey: sessionStorage.getItem('awsSecretAccessKey'),
        region: 'eu-west-1'
      });
    }
  }


  /**********************************
  * defaultAdditionalParams
  **********************************/
  defaultAdditionalParams() {

    return { 
      queryParams: { s3bucket: sessionStorage.getItem('s3Bucket') },
      headers: { Authorization: sessionStorage.getItem('awsIdentityToken') }
    };
  }


  /**********************************
  * getRuntimeConfig
  **********************************/
  getRuntimeConfig() : Subject<boolean> {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();
              
    let subject = new Subject<boolean>();

    this.apigClient.runtimeConfigGet(null, null, additionalParams)
      .then((response) => { 
        let data = this.parseJSON(response);
        let valid = this.runtimeConfig.parse(data);
        subject.next(valid);
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err); 
          setTimeout(function(){alert('An API error (UI runtime config) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
        }
      });

    return subject;
  }


  /**********************************
  * getSites
  **********************************/
  getSites(refresh: boolean = false) {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    if (!refresh) {
      this.sitesSubject = new Subject<Site[]>();
    }

    Promise.all([this.apigClient.assetGet(null, null, additionalParams),
                 this.apigClient.liveAlertsGet(null, null, additionalParams)])
      .then((responses) => { 
        // doing this we can call unsubscribe once on the subject rather than tracking all subscriptions
        if (!this.sitesSubject.closed) { 
          
          let sitesData = this.parseJSON(responses[0]);
          let sites = Sites.parse(sitesData, false);

          let alertsData = this.parseJSON(responses[1]);
          LiveAlerts.assign(sites, alertsData);

          this.sitesSubject.next(sites);

          // can't complete because of refreshes
          // but note, forkJoins, if ever required, need complete
          //this.sitesSubject.complete(); 
        }
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (assets / live alerts) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });
  }


  /**********************************
  * getSite
  **********************************/
  getSite(siteName: string) {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    additionalParams.queryParams.assetname = siteName;

    this.siteSubject = new Subject<Site>();

    Promise.all([this.apigClient.assetGet(null, null, additionalParams),
                 this.apigClient.liveAlertsGet(null, null, additionalParams)])
      .then((responses) => { 
        // doing this we can call unsubscribe once on the subject rather than tracking all subscriptions
        if (!this.siteSubject.closed) {
          let data = this.parseJSON(responses[0]);
          let sites = Sites.parse(data, true);
          if (sites.length != 1) { throw new Error('Invalid site count (expected 1)'); }
          if (sites[0].name != siteName) { throw new Error('Invalid site name'); }

          let alertsData = this.parseJSON(responses[1]);
          LiveAlerts.assign(sites, alertsData);
          
          this.siteSubject.next(sites[0]);
          this.siteSubject.complete(); // need to complete for forkJoins
        }
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (asset / live alert) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });
  }


  /**********************************
  * getSpillEvents
  **********************************/
  getSpillEvents(siteName: string) : Subject<SpillageEvent[]> {

    this.checkCreateApiClient();

    // let startDate = moment().subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    // let endDate = moment().format('DD/MM/YYYY HH:mm');

    let additionalParams: any = this.defaultAdditionalParams();

    additionalParams.queryParams.assetname = siteName;
    additionalParams.queryParams.last12mths = 'True';
    //additionalParams.queryParams.startdate = startDate;
    //additionalParams.queryParams.enddate = endDate;       

    let subject = new Subject<SpillageEvent[]>();

    this.apigClient.spillageDetailsGet(null, null, additionalParams)
      .then((response) => { 
        let data = this.parseJSON(response);
        let spillEvents = SpillageEvents.parse(data);
        subject.next(spillEvents);
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err); 
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (spillage details) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });

    return subject;
  }


  /**********************************
  * getSpillsSummaries
  **********************************/
  getSpillsSummaries(year: number) : Subject<SpillagesSummary[]> {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    additionalParams.queryParams.assetname = 'ALL';
    additionalParams.queryParams.year = year;
    additionalParams.queryParams.network_summary = 'False';

    let additionalParams2: any = this.defaultAdditionalParams();

    let subject = new Subject<SpillagesSummary[]>();

    Promise.all([this.apigClient.spillageSummaryGet(null, null, additionalParams),
                 this.apigClient.assetGet(null, null, additionalParams2)])
      .then((responses) => {

        let summariesData = this.parseJSON(responses[0]);
        let summaries = SpillagesSummaries.parse(summariesData, year);

        let sitesData = this.parseJSON(responses[1]);
        let sites = Sites.parse(sitesData, false);

        SpillagesSummaries.assign(summaries, sites);

        subject.next(summaries);
        
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err); 
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (spillage summaries) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });

    return subject;
  }


  /**********************************
  * getSpillsSummary
  **********************************/
  getSpillsSummary(siteName: string, year: number, month?: number) : Subject<SpillagesSummary> {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    additionalParams.queryParams.assetname = siteName;
    additionalParams.queryParams.year = year;
    additionalParams.queryParams.network_summary = siteName == 'ALL' ? 'True' : 'False';

    if (month) { additionalParams.queryParams.month = month }

    let subject = new Subject<SpillagesSummary>();

    this.apigClient.spillageSummaryGet(null, null, additionalParams)
      .then((response) => { 
        let data = this.parseJSON(response);
        let spillsSummary: SpillagesSummary = null;
        let spillsSummaries = SpillagesSummaries.parse(data, year, month);
        if (spillsSummaries.length == 1) {
          spillsSummary = spillsSummaries[0];
        }
        else {
          spillsSummary = SpillagesSummaries.getEmptySpillagesSummary();
        }
        subject.next(spillsSummary);
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (spillage summary) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });

    return subject;
  }


  /**********************************
  * getVisualisationData
  **********************************/
  // getVisualisationData(siteName: string, startDate: string, endDate: string, prediction: boolean) : AsyncSubject<VisualisationData> {

  //   this.checkCreateApiClient();

  //   let additionalParams: any = this.defaultAdditionalParams();

  //   additionalParams.queryParams.assetname = siteName;
  //   additionalParams.queryParams.startdate = startDate;
  //   additionalParams.queryParams.enddate = endDate;
  //   additionalParams.queryParams.prediction = prediction; 

  //   let subject = new AsyncSubject<VisualisationData>();

  //   this.apigClient.assetDetailsGet(null, null, additionalParams)
  //     .then((response) => { 
  //       let data = this.parseJSON(response);
  //       let visData = new VisualisationData(data, prediction);
  //       subject.next(visData);
  //       subject.complete(); // need to complete for forkJoins and AsyncSubjects
  //     })
  //     .catch((err) => { 
  //       if (!err.name || err.name !== 'ObjectUnsubscribedError') {
  //         console.error('API request processing failed');
  //         console.error(err);
  //         if (sessionStorage.getItem('awsIdentityToken') != '') {
  //           setTimeout(function(){alert('An API error (visualisation data) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
  //         }
  //       }
  //     });

  //   return subject;
  // }

  getVisualisationData(siteName: string, startDate: string, endDate: string, prediction: boolean) : AsyncSubject<VisualisationData> {

    this.checkCreateApiClient();

    let startDateUtc = TimeUtil.makeLocalStringUtcString(startDate, 'DD/MM/YYYY HH:mm');
    let endDateUtc = TimeUtil.makeLocalStringUtcString(endDate, 'DD/MM/YYYY HH:mm');

    let dataTypes = ['oflow', 'rain', 'slm'];

    if (!prediction) {
      //dataTypes.push('pump1', 'pump2', 'pump3', 'pump4', 'pump5', 'pump6', 'pump7', 'pump8', 'pump9');
      dataTypes.push('pump1', 'pump2', 'pump3', 'pump4', 'pump5');
    }

    // if data visualisation, include pump
    let startMoment = moment(startDate, 'DD/MM/YYYY HH:mm');
    let endMoment = moment(endDate, 'DD/MM/YYYY HH:mm');
    let duration = moment.duration(endMoment.diff(startMoment));
    // for prediction chart and 3 month / 1 week historic chart, get level predictions
    if (duration.asDays() < 100) {
    //if (duration.asDays() < 80) {
      dataTypes.push('lvlpred');
      // force into prediction mode
      prediction = true;
    }

    //dataTypes = ['slm'];

    let promises = [];

    dataTypes.forEach(dataType => {

      let ap: any = this.defaultAdditionalParams();
      ap.queryParams.datatype = dataType;
      ap.queryParams.assetname = siteName;
      ap.queryParams.startdate = startDateUtc;
      ap.queryParams.enddate = endDateUtc;
      ap.queryParams.prediction = prediction; 
      promises.push(this.apigClient.assetDetailsGet(null, null, ap));
    });  

    let subject = new AsyncSubject<VisualisationData>();

    Promise.all(promises)
      .then((responses) => { 
        
        let data = [];
        responses.forEach(response => {

          data.push(this.parseJSON(response));
        });
        let visData = new VisualisationData(data, prediction);
        subject.next(visData);
        subject.complete(); // need to complete for forkJoins and AsyncSubjects

      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (visualisation data) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });
    
    return subject;
  }


  /**********************************
  * getCsoSpsCombined
  **********************************/
  getCsoSpsCombined(csoName: string, spsName: string, startDate: string, endDate: string) : Subject<CsoSpsCombined> {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    let startDateUtc = TimeUtil.makeLocalStringUtcString(startDate, 'DD/MM/YYYY HH:mm');
    let endDateUtc = TimeUtil.makeLocalStringUtcString(endDate, 'DD/MM/YYYY HH:mm');

    additionalParams.queryParams.csoname = csoName;
    additionalParams.queryParams.spsname = spsName;
    additionalParams.queryParams.startdate = startDateUtc;
    additionalParams.queryParams.enddate = endDateUtc;  

    let subject = new Subject<CsoSpsCombined>();

    this.apigClient.csoSpsCombinedGet(null, null, additionalParams)
      .then((response) => { 
        let data = this.parseJSON(response);
        let csoSpsCombined = new CsoSpsCombined();
        csoSpsCombined.parse(data);
        subject.next(csoSpsCombined);
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('An API error (cso/sps combined) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });

    return subject;
  }


  /**********************************
  * getFileContents
  **********************************/
  getFileContents(filename: string) : Subject<string> {

    this.checkCreateApiClient();

    let additionalParams: any = this.defaultAdditionalParams();

    additionalParams.queryParams.filename = filename;

    let subject = new Subject<string>();

    this.apigClient.fileContentsGet(null, null, additionalParams)
      .then((response) => { 
        let data = this.parseJSON(response);
        subject.next(data.value);
        subject.complete(); // need to complete for forkJoins
      })
      .catch((err) => { 
        if (!err.name || err.name !== 'ObjectUnsubscribedError') {
          console.error('API request processing failed');
          console.error(err);
          if (sessionStorage.getItem('awsIdentityToken') != '') {
            setTimeout(function(){alert('Could not process request, please contact StormHarvester support.');}, ALERT_DELAY);
          }
        }
      });

    return subject;
  }



  /**********************************
  * getFloodWarnings
  **********************************/
  getFloodWarnings(area: string) : Subject<FloodWarningData> {

    let subject = new Subject<FloodWarningData>();

    let url = 'https://environment.data.gov.uk/flood-monitoring/id/floods?lat=';
    url +=  this.runtimeConfig.getFloodAlertLatitude();
    url += '&long=';
    url += this.runtimeConfig.getFloodAlertLongitude();
    url += '&dist=';
    url += this.runtimeConfig.getFloodAlertRadius();

    //this.http.get('http://localhost:3000/api/flood-monitoring/id/floods?county=' + area)
    this.http.get(url)
    .pipe( 
      catchError(err => { console.error('Error retrieving flood warning info');
                          console.error(err);
                          setTimeout(function(){alert('An API error (flooding) has occurred, please contact StormHarvester support.');}, ALERT_DELAY);
                          return of({items:[]});
                        })
    )
    .subscribe(response => {
        subject.next(new FloodWarningData(response));
        subject.complete(); // need to complete for forkJoins
      }
    );

    return subject;
  }


  /**********************************
  * parseJSON
  **********************************/
  // TODO : find out why sometimes responses are parsed and somtimes not
  parseJSON(response: any) : any {

    if (response && response.data)
    {
      if (typeof response.data.body === 'string') {
        return JSON.parse(response.data);
      }
      else {
        return response.data;
      }
    }
    else {
      return null;
    }
  }

}
