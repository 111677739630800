import { SpillageEvent } from './spillage-event';
import { SpillageEvents } from './spillage-events';

export class CsoSpsCombined {

  totalSpsOverflows: number = 0;
  highSpsOverflows: number = 0;
  moderateSpsOverflows: number = 0;
  lowSpsOverflows: number = 0;

  totalCsoOverflows: number = 0;
  highCsoOverflows: number = 0;
  moderateCsoOverflows: number = 0;
  lowCsoOverflows: number = 0;

  totalSoloSpsOverflows: number = 0;
  highSoloSpsOverflows: number = 0;
  moderateSoloSpsOverflows: number = 0;
  lowSoloSpsOverflows: number = 0;

  totalSoloCsoOverflows: number = 0;
  highSoloCsoOverflows: number = 0;
  moderateSoloCsoOverflows: number = 0;
  lowSoloCsoOverflows: number = 0;

  totalCombinedOverflows: number = 0;
  highCombinedOverflows: number = 0;
  moderateCombinedOverflows: number = 0;
  lowCombinedOverflows: number = 0;

  soloCSOEvents: SpillageEvent[] = [];
  soloSPSEvents: SpillageEvent[] = [];
  overlappedEvents: SpillageEvent[] = [];

    
  constructor() {}


  parse(data: any) {

    if (!data || data.retcode == undefined || data.rettext == undefined ||
        data.value == undefined || data.value == null || typeof data.value != 'object') {
      console.error('CsoSpsCombined Retrieval - Invalid response from API Gateway');
      return;
    }

    if (data.retcode !== 1) {
      console.error('CsoSpsCombined Retrieval - Error returned : ' + data.rettext);
      return;
    }
      
    let value = data.value;

    this.totalSpsOverflows = value.SPSOverflows.NoOfSPSOverflows;
    this.highSpsOverflows = value.SPSOverflows.HighSeverity;
    this.moderateSpsOverflows = value.SPSOverflows.ModerateSeverity;
    this.lowSpsOverflows = value.SPSOverflows.LowSeverity;

    this.totalCsoOverflows = value.CSOOverflows.NoOfCSOOverflows;
    this.highCsoOverflows = value.CSOOverflows.HighSeverity;
    this.moderateCsoOverflows = value.CSOOverflows.ModerateSeverity;
    this.lowCsoOverflows = value.CSOOverflows.LowSeverity;

    this.totalSoloSpsOverflows = value.SoloSPSOverflows.NoOfSoloSPSOverflows;
    this.highSoloSpsOverflows = value.SoloSPSOverflows.HighSeverity;
    this.moderateSoloSpsOverflows = value.SoloSPSOverflows.ModerateSeverity;
    this.lowSoloSpsOverflows = value.SoloSPSOverflows.LowSeverity;

    this.totalSoloCsoOverflows = value.SoloCSOOverflows.NoOfSoloCSOOverflows;
    this.highSoloCsoOverflows = value.SoloCSOOverflows.HighSeverity;
    this.moderateSoloCsoOverflows = value.SoloCSOOverflows.ModerateSeverity;
    this.lowSoloCsoOverflows = value.SoloCSOOverflows.LowSeverity;

    this.totalCombinedOverflows = value.TotCombinedOverflows.totalCombined;
    this.highCombinedOverflows = value.TotCombinedOverflows.HighSeverity;
    this.moderateCombinedOverflows = value.TotCombinedOverflows.ModerateSeverity;
    this.lowCombinedOverflows = value.TotCombinedOverflows.LowSeverity;

    this.soloCSOEvents = SpillageEvents.parseReducedCsv(value.SoloCSOOverflows_csv);
    this.soloSPSEvents = SpillageEvents.parseReducedCsv(value.SoloSPSOverflows_csv);
    this.overlappedEvents = SpillageEvents.parseReducedCsv(value.OverlappedOverflows_csv);        
  }
}
