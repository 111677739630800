import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../classes/cognito.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  username: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;


  /**********************************
  * constructor
  **********************************/
  constructor(private cognitoService: CognitoService) {
    
    this.username = "";
    this.password = "";
    this.newPassword = "";
    this.confirmNewPassword = "";
  }


  /**********************************
  * ngOnInit
  **********************************/
  ngOnInit() {

    this.username = sessionStorage.getItem('awsUsername');
  }


  /**********************************
  * onSubmit
  **********************************/
  onSubmit(event: any) {

    document.body.style.cursor = 'wait';

    if (this.password != this.password.trim()) {
      alert('Password must not contain leading or trailing spaces');
      return;
    }

    if (this.newPassword != this.newPassword.trim()) {
      alert('New Password must not contain leading or trailing spaces');
      return;
    }

    if (this.newPassword.length < this.cognitoService.MIN_PASSWORD_LEN) {
      alert('New Password must be at least ' + this.cognitoService.MIN_PASSWORD_LEN +  ' characters long');
      return;
    }

    if (this.newPassword != this.confirmNewPassword) {
      alert('New Password and Confirm New Password must be the same');
      return;
    }

    this.cognitoService.changePassword( this.username, this.password, this.newPassword, this.changePasswordCallback);
  }


  /**********************************
  * changePasswordCallback
  **********************************/
  changePasswordCallback(err?: Error, result?: string) {

    document.body.style.cursor = 'default';

    if (err) {
      alert('Password change failed with error : ' + err.message);
    }
    else {
      alert('Password change succeeded');
    }          
  }


}
