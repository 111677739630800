import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CognitoService } from '../../../classes/cognito.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(private router: Router,
              private cognitoService: CognitoService) {
  }


  ngOnInit() {
  }


  logout(event: any) {

    if (event) { event.preventDefault(); }

    this.cognitoService.clearTokens();
  
    this.router.navigate(['./']);
  }


  changePassword(event: any) {

    if (event) { event.preventDefault(); }
  
    this.router.navigate(['change-password']);
  }

}
