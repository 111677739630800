import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';

import { ApiService } from '../../classes/api.service';
import { ChartSPS } from '../../classes/chart-sps';
import { VisualisationData } from '../../classes/visualisation-data';
import { CsoSpsCombined } from '../../classes/cso-sps-combined';

import * as moment from 'moment';

@Component({
  selector: 'app-site-cso-sps-combined',
  templateUrl: './site-cso-sps-combined.component.html',
  styleUrls: ['./site-cso-sps-combined.component.scss']
})
export class SiteCsoSpsCombinedComponent implements OnInit {

  @Output() emitterCombinedChartsRenderingComplete = new EventEmitter();

  csoName: string;
  assocSPS: string;

  csoSpsCombined: CsoSpsCombined;
  
  subSPSCSO: Subscription;
  
  chartSPS: ChartSPS;
  chartCSO: ChartSPS;

  timer: any;

  constructor(private apiService: ApiService) { 
    this.csoSpsCombined = new CsoSpsCombined();
  }


  ngOnInit() {
  }


  ngOnDestroy() {
    if (this.subSPSCSO) { this.subSPSCSO.unsubscribe(); }
  }


  setAssetNames(name: string, assocSPS: string) {

    this.csoName = name;
    this.assocSPS = assocSPS;
    this.configureCharts(this.assocSPS);
  }


  setSpsCombined(csoSpsCombined: CsoSpsCombined) {
    this.csoSpsCombined = csoSpsCombined;
  }  


  configureCharts(spsName: string) {

    //return;

    if (this.chartSPS) {
      this.chartSPS.c3Chart.destroy();
      this.chartSPS.rendered = false;
    }

    if (this.chartCSO) {
      this.chartCSO.c3Chart.destroy();
      this.chartCSO.rendered = false;
    }

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    // let startDateHist = moment().subtract(2, 'year').subtract(12, 'month').format('DD/MM/YYYY HH:mm');;
    // let endDateHist = moment().subtract(2, 'year').format('DD/MM/YYYY HH:mm');
    //let startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');;
    //let endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    let startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');;
    let endDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    
    let subjSPS = this.apiService.getVisualisationData(this.assocSPS, startDateHist, endDateHist, false);
    let subjCSO = this.apiService.getVisualisationData(this.csoName, startDateHist, endDateHist, false);

    this.subSPSCSO = forkJoin(subjSPS, subjCSO)
      .subscribe((visDatas: VisualisationData[]) => { 

        for (let i = 0; i < visDatas[0].pumpsData.length; i++) {
          visDatas[0].pumpsData[i] = ""; 
        }
        this.chartSPS = new ChartSPS();
        this.chartSPS.createChart(320, 'combinedSpillsSPS', 'Historic', visDatas[0]);
               
        for (let i = 0; i < visDatas[1].pumpsData.length; i++) {
          visDatas[1].pumpsData[i] = ""; 
        }
        this.chartCSO = new ChartSPS();
        let mirrorCharts = [this.chartSPS.c3Chart];
        this.chartCSO.createChart(420, 'combinedSpillsCSO', 'Historic', visDatas[1], mirrorCharts);
    });  

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }


  clearWaitCursorWhenRendered() {      

    if (this.chartCSO && this.chartCSO.rendered &&
        this.chartSPS && this.chartSPS.rendered) {

      clearInterval(this.timer);
      this.emitterCombinedChartsRenderingComplete.emit();
    }
  }

  // there is a bug in c3 or chrome that makes reloading the visualisation data
  // really slow when these 2 charts are visible (Firefox is okay tho)
  // hence this function to hide the charts during a visualisation data reload
  hideCharts(hidden: boolean) {

    document.getElementById('combinedSpillsSPS').hidden = hidden;
    document.getElementById('combinedSpillsCSO').hidden = hidden;
  }

}
