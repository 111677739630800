import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';

declare var apigClientFactory: any;

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
export class Login2Component implements OnInit {

  username: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;

  forceNewPassword: boolean;

  subRuntimeConfig: Subscription;

  queryParams: any;


  /**********************************
  * constructor
  **********************************/
  constructor(private router: Router,
              private route: ActivatedRoute,
              private apiService: ApiService,
              private cognitoService: CognitoService) {
    
    this.username = "";
    this.password = "";
    this.newPassword = "";
    this.confirmNewPassword = "";
    this.forceNewPassword = false;

    this.queryParams = this.route.snapshot.queryParams;
  }


  /**********************************
  * ngOnInit
  **********************************/
  ngOnInit() {

    // clear all session storage to ensure no state is held between separate logins
    sessionStorage.clear();
  }


  /**********************************
  * ngAfterViewInit
  **********************************/
  ngAfterViewInit() {

    // there is some sort of bug relating to angular and video element playback
    // where the video freezes if you reload the page
    // the following is a workaround :

    let e = <HTMLVideoElement>document.getElementById('login-video');
    let parent = e.parentElement;
    e.remove();

    let e2 = <HTMLVideoElement>document.createElement("video");
    e2.setAttribute('id', 'login-video');
    e2.setAttribute('src', '/mint/img/rain.mp4');
    e2.autoplay = true;
    e2.muted = true;
    e2.loop = true;
    e2.load();
    parent.prepend(e2);

    //setTimeout(()=>{this.login();}, 1);
  }


  /**********************************
  * ngOnDestroy
  **********************************/
  ngOnDestroy() {

    if (this.subRuntimeConfig) {
      this.subRuntimeConfig.unsubscribe();
    }
  }


  /**********************************
  * onSubmit
  **********************************/
  onSubmit(event: any) {

    document.body.style.cursor = 'wait';

    if (this.username != this.username.trim()) {
      alert('User Name must not contain leading or trailing spaces');
      return;
    }

    if (this.password != this.password.trim()) {
      alert('Password must not contain leading or trailing spaces');
      return;
    }

    if (this.forceNewPassword) {

      if (this.newPassword != this.newPassword.trim()) {
        alert('New Password must not contain leading or trailing spaces');
        return;
      }

      if (this.newPassword.length < this.cognitoService.MIN_PASSWORD_LEN) {
        alert('New Password must be at least ' + this.cognitoService.MIN_PASSWORD_LEN +  ' characters long');
        return;
      }

      if (this.newPassword != this.confirmNewPassword) {
        alert('New Password and Confirm New Password must be the same');
        return;
      }
    }

    this.cognitoService.getIdentityToken(
      this.username,
      this.password,
      this.newPassword,
      this.forceNewPassword,
      this,
      this.idTokenSuccess,
      this.idTokenFailure,
      this.idNewPasswordRequired
    );
  }


  /**********************************
  * idTokenSuccess
  **********************************/
  idTokenSuccess() {
         
    this.subRuntimeConfig = this.apiService.getRuntimeConfig()
      .subscribe((valid: boolean) => { 
        document.body.style.cursor = 'default';
        if (valid) {

          let allowUrls = JSON.parse(sessionStorage.getItem('allowUrls'));
          let allowUrl = false;

          if (Array.isArray(allowUrls)) {
            allowUrls.forEach(url => {
              if (window.location.href.includes(url)) { allowUrl =  true; }
            });
          }

          if (!allowUrl) {
            let awsUsername = sessionStorage.getItem('awsUsername');
            alert(awsUsername + ' does not have permission to access ' + window.location.href + '. Please contact StormHarvester support.');
          }
          else if (this.queryParams && this.queryParams.route) {
            this.router.navigateByUrl('/' + this.queryParams.route);
          }
          else {
            this.router.navigate(['live-alerts']);
          }
        }
        else {
          alert('Account configuration is invalid. Please contact StormHarvester support.');
        }
    });

  }


  /**********************************
  * idTokenFailure
  **********************************/
  idTokenFailure(errorCode: string, errorMessage: string) {

    document.body.style.cursor = 'default';

    if (errorCode == "UserNotFoundException" || errorCode == "NotAuthorizedException") {
      alert('Authorisation failed. ' + errorMessage);
    }
    else {
      alert(errorCode + '. ' + errorMessage);
    }
  }


  /**********************************
  * idNewPasswordRequired
  **********************************/
  idNewPasswordRequired() {

    document.body.style.cursor = 'default';

    this.forceNewPassword = true;
  }

}
