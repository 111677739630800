export class SpillageLevel {
   
    constructor(public id: number, public text: string, public cssClass: string) { }
}

export class SpillageLevels {

  private severityLevels: SpillageLevel[] = [];
  
  constructor() {
    this.severityLevels.push(new SpillageLevel(0, 'NONE', 'alert-grey'));
    this.severityLevels.push(new SpillageLevel(1, 'LOW', 'alert-yellow'));
    this.severityLevels.push(new SpillageLevel(2, 'MODERATE', 'alert-orange'));
    this.severityLevels.push(new SpillageLevel(3, 'HIGH', 'alert-red'));
    
  }

  getLevelFromId(id: number): SpillageLevel {
    if (id >= 1 && id <= 3) {
      return this.severityLevels[id];
    }
    else {
      return this.severityLevels[0];
    }
  }

  getLevelFromText(text: string): SpillageLevel {
    let severityLevel = this.severityLevels.find(sl => sl.text == text);

    if (severityLevel) {
      return severityLevel;
    }
    else {
      return this.severityLevels[0];
    }
  }

  // FOR TESTING ONLY
  getRandom(): SpillageLevel {
    return this.severityLevels[Math.floor(Math.random() * 4)];
  }

}