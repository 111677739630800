export class SpillagesSummary {
  constructor(
    public siteName: string,
    public catchment: string,
    public year: number,
    public month: number,
    public numEvents: number,
    public litresSpilled: number,
    public daysDuration: number,
    public preventableEvents: number,
    public saveableLitres: number,
    public percentageSaveable: number,
    public numHigh: number,
    public numModerate: number,
    public numLow: number,
    public rainCause: number,
    public pumpCause: number,
    public blockageCause: number
  ) {}
}
