import { PageEvent } from '@angular/material/paginator';
import { SpillagesSummary } from './spillages-summary';
import { Paginator } from './paginator';

export class FilterSortSpillagesSummaries {

  public spillsReceived: SpillagesSummary[];
  public spillsFilteredSorted: SpillagesSummary[] = [];
  public spillsPageDisplay: SpillagesSummary[];

  public stationText: string = '';
  public stationTextUpper: string = '';

  public catchmentText: string = '';
  public catchmentTextUpper: string = '';

  public minActualEvents: number;
  public maxActualEvents: number;

  public minActualLitres: number;
  public maxActualLitres: number;
  
  public minActualDuration: number;
  public maxActualDuration: number;

  public minPreventEvents: number;
  public maxPreventEvents: number;
  
  public minPreventLitres: number;
  public maxPreventLitres: number;
  
  public minPreventPercent: number;
  public maxPreventPercent: number;

  public sortType: string = 'Severity';
  public sortOrder: string = 'Desc';

  public pagn8r: Paginator;

  constructor (pageSize: number) {
    this.pagn8r = new Paginator(pageSize);
  }

  public initialise(): void {

    this.minActualEvents = 0;
    this.maxActualEvents = 0;

    this.minActualLitres = 0;
    this.maxActualLitres = 0;

    this.minActualDuration = 0;
    this.maxActualDuration = 0;

    this.minPreventEvents = 0;
    this.maxPreventEvents = 0;
    
    this.minPreventLitres = 0;
    this.maxPreventLitres = 0;
    
    this.minPreventPercent = 0;
    this.maxPreventPercent = 0;

    this.spillsReceived.forEach(spillReceived => {

      if (spillReceived.numEvents > this.maxActualEvents) { this.maxActualEvents = spillReceived.numEvents; }
      if (spillReceived.litresSpilled > this.maxActualLitres) { this.maxActualLitres = spillReceived.litresSpilled; }
      if (spillReceived.daysDuration > this.maxActualDuration) { this.maxActualDuration = spillReceived.daysDuration; }

      if (spillReceived.preventableEvents > this.maxPreventEvents) { this.maxPreventEvents = spillReceived.preventableEvents; }
      if (spillReceived.saveableLitres > this.maxPreventLitres) { this.maxPreventLitres = spillReceived.saveableLitres; }
      if (spillReceived.percentageSaveable > this.maxPreventPercent) { this.maxPreventPercent = spillReceived.percentageSaveable; }
    });
  }

  public setSortBy(sortType: string, sortOrder: string): void {
    this.sortType = sortType;
    this.sortOrder = sortOrder;
    this.process();
  }

  public setFilterByStation(event: any): void {
    this.stationText = event.srcElement.value; 
    this.stationTextUpper = this.stationText.toUpperCase();
    this.process();
  }

  public setFilterByCatchment(event: any): void {
    this.catchmentText = event.srcElement.value; 
    this.catchmentTextUpper = this.catchmentText.toUpperCase();
    this.process();
  }

  public setFilterByActualEvents(min: number, max: number): void {
    this.minActualEvents = min;
    this.maxActualEvents = max;
  }

  public setFilterByActualLitres(min: number, max: number): void {
    this.minActualLitres = min;
    this.maxActualLitres = max;
  }

  public setFilterByActualDuration(min: number, max: number): void {
    this.minActualDuration = min;
    this.maxActualDuration = max;
  }

  public setFilterByPreventEvents(min: number, max: number): void {
    this.minPreventEvents = min;
    this.maxPreventEvents = max;
  }

  public setFilterByPreventLitres(min: number, max: number): void {
    this.minPreventLitres = min;
    this.maxPreventLitres = max;
  }

  public setFilterByPreventPercent(min: number, max: number): void {
    this.minPreventPercent = min;
    this.maxPreventPercent = max;
  }

  public resetFilters() {
    this.stationText = '';
    this.stationTextUpper = '';
    this.catchmentText = '';
    this.catchmentTextUpper = '';
    this.process();
  }

  public process(): void {

    // FILTER
    
    this.spillsFilteredSorted = [];
    this.spillsReceived.forEach(spill => {
      
      let addSpill = true;

      if (this.stationText !== '') {
        let siteNameUpper = spill.siteName.toUpperCase();
        if (!siteNameUpper.includes(this.stationTextUpper)) { addSpill = false; }
      }

      if (this.catchmentText !== '') {
        let catchmentUpper = spill.catchment.toUpperCase();
        if (!catchmentUpper.includes(this.catchmentTextUpper)) { addSpill = false; }
      }

      if (spill.numEvents < this.minActualEvents || spill.numEvents > this.maxActualEvents) { addSpill = false; }
      if (spill.litresSpilled < this.minActualLitres || spill.litresSpilled > this.maxActualLitres) { addSpill = false; }
      if (spill.daysDuration < this.minActualDuration || spill.daysDuration > this.maxActualDuration) { addSpill = false; }

      if (spill.preventableEvents < this.minPreventEvents || spill.preventableEvents > this.maxPreventEvents) { addSpill = false; }
      if (spill.saveableLitres < this.minPreventLitres || spill.saveableLitres > this.maxPreventLitres) { addSpill = false; }
      if (spill.percentageSaveable < this.minPreventPercent || spill.percentageSaveable > this.maxPreventPercent) { addSpill = false; }

      if (addSpill) { this.spillsFilteredSorted.push(spill); }
    });
        
    // SORT

    switch (this.sortType) {
      case 'Station' : 
      this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
        this.spillsFilteredSorted.sort((a, b) => a.siteName.localeCompare(b.siteName)) :
            this.spillsFilteredSorted.sort((a, b) => b.siteName.localeCompare(a.siteName));
            break;
      case 'Catchment' : 
      this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
        this.spillsFilteredSorted.sort((a, b) => a.catchment.localeCompare(b.catchment)) :
            this.spillsFilteredSorted.sort((a, b) => b.catchment.localeCompare(a.catchment));
            break;
      case 'SpillEvents' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.numEvents - b.numEvents) :
            this.spillsFilteredSorted.sort((a, b) => b.numEvents - a.numEvents);
        break;
      case 'SpillLitres' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.litresSpilled - b.litresSpilled):
            this.spillsFilteredSorted.sort((a, b) => b.litresSpilled - a.litresSpilled);
        break;
      case 'DurationDays' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.daysDuration - b.daysDuration) :
            this.spillsFilteredSorted.sort((a, b) => b.daysDuration - a.daysDuration);
        break;
      case 'PreventableEvents' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.preventableEvents - b.preventableEvents) :
            this.spillsFilteredSorted.sort((a, b) => b.preventableEvents - a.preventableEvents);
        break;
      case 'LitresSaved' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.saveableLitres - b.saveableLitres) :
            this.spillsFilteredSorted.sort((a, b) => b.saveableLitres - a.saveableLitres);
        break;
      case 'PercentSaved' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.percentageSaveable - b.percentageSaveable) :
            this.spillsFilteredSorted.sort((a, b) => b.percentageSaveable - a.percentageSaveable);
        break;
    }

    this.pagn8r.adjustForNewMaxIndex(this.spillsFilteredSorted.length);

    this.processPage();
  }

  public onPageUpdate(pageEvent: PageEvent) {

    this.pagn8r.pageIndex = pageEvent.pageIndex;

    this.processPage();
  }

  private processPage() {

    let numDisplayItems = this.pagn8r.calcNumDisplayRows(this.spillsFilteredSorted.length);

    this.spillsPageDisplay = new Array<SpillagesSummary>(numDisplayItems);

    this.pagn8r.createPageArray(this.spillsFilteredSorted, this.spillsPageDisplay, numDisplayItems);
  }

}
