import { ChartAPI, generate } from 'c3';
import { CsvUtil } from './csv-util';
import { VisualisationData } from './visualisation-data';

import * as moment from 'moment';

export class ChartSPS {

  c3Chart: ChartAPI = null;

  chart1data: any = null;
  chart2data: any = null;
  chart3data: any = null;
  chart4data: any = null;
  chart5data: any = null;
  chart6data: any = null;
  chart7data: any = null;
  chart8data: any = null;
  //chart9data: any = null;

  chartdataPredictedLevels: any = null;
  chartdataHiPredThresh: any = null;
  chartdataLoPredThresh: any = null;

  rendered: boolean;

  startTime: number = 0;
  endTime: number = 0;

  yAxisMin:number = 0;
  yAxisMax:number = 0;

  maxRainfall:number = 0;

  constructor() { }

  public createChart(height: number, chartContainerId: string, type: string, visData: VisualisationData, mirrorCharts?: ChartAPI[])
  {
    // console.log('SPS Chart render started');
    // this.startTime = (new Date()).valueOf();

    this.rendered = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    this.chart8data = CsvUtil.getCSVData(visData.slmData, 'x8','SLM Levels');
    this.adjustToTimezone(this.chart8data);


    //
    // TEMP added for demo
    //
    // High and Low historical prediction thresholds
    if (visData.lvlpredData != null) {
      this.chartdataHiPredThresh = CsvUtil.getLevelPredData(visData.lvlpredData, 'xHiPredThresh', 'Hi Pred Thresholds'); 
      this.chartdataLoPredThresh = CsvUtil.getLevelPredData(visData.lvlpredData, 'xLoPredThresh', 'Low Pred Thresholds');
    }

    // High and Low future prediction thresholds
    //if (type !== 'Blockage' && type !== 'Anomaly') {
      if (visData.lvlpredPredictionData != null) {
        if (this.chartdataHiPredThresh) {
          this.chartdataHiPredThresh = CsvUtil.addLevelPredData(this.chartdataHiPredThresh, visData.lvlpredPredictionData, 'Hi Pred Thresholds');
        }
        else {
          this.chartdataHiPredThresh = CsvUtil.getLevelPredData(visData.lvlpredPredictionData, 'xHiPredThresh', 'Hi Pred Thresholds');
        }
        if (this.chartdataLoPredThresh) {
          this.chartdataLoPredThresh = CsvUtil.addLevelPredData(this.chartdataLoPredThresh, visData.lvlpredPredictionData, 'Low Pred Thresholds');
        }
        else {
          this.chartdataLoPredThresh = CsvUtil.getLevelPredData(visData.lvlpredPredictionData, 'xLoPredThresh', 'Low Pred Thresholds');
        }
      }
    //}
    // High and Low prediction thresholds - adjust for timezone
    if (this.chartdataHiPredThresh) { this.adjustToTimezone(this.chartdataHiPredThresh); }
    if (this.chartdataLoPredThresh) { this.adjustToTimezone(this.chartdataLoPredThresh); }
    //
    // TEMP added for demo
    //


    // expand y axis to accommodate SLM spikes above 120 or anomalous negative values
    this.yAxisMin = 0;
    this.yAxisMax = 110;
    for (let i = 1; i < this.chart8data.ydata.length; ++i) {
      if (this.chart8data.ydata[i] > this.yAxisMax) {
        this.yAxisMax = (Math.floor(this.chart8data.ydata[i] / 10.0)) * 10; 
      }
      if (this.chart8data.ydata[i] < this.yAxisMin) {
        this.yAxisMin = (Math.floor(this.chart8data.ydata[i] / 10.0)) * 10; 
      }
    }

    this.chart2data= CsvUtil.getCSVData(visData.rainfallData, 'x2','Rainfall');

    //if (type === 'Prediction') {
      CsvUtil.addCSVData(this.chart2data, visData.rainfallPredictionData);
    //}
    
    if (type === 'Historic') {
      this.chart2data = CsvUtil.reduceChartData(this.chart2data);
    }

    this.adjustToTimezone(this.chart2data);

    this.maxRainfall = 5;

    for (let i = 1; i < this.chart2data.ydata.length; ++i) {
      if (this.chart2data.ydata[i] > this.maxRainfall) { this.maxRainfall = this.chart2data.ydata[i]; }
    }

    this.maxRainfall = Math.ceil(this.maxRainfall);

    let xAxisMin = undefined;
    let xAxisMax = undefined;

    if (type === 'Prediction') {

      xAxisMin = visData.startDate;
      xAxisMax = visData.endDate;

      // we want the chart to fully extend from -6hrs to +6hrs
      // so we may need to hack in null values at these times
      // as c3 chart does not do the right thing
      if (this.chart2data.xdata.length > 0) {

        let temp = { xdata: [], ydata: [] };

        temp.xdata.push(this.chart2data.xdata[0]);
        temp.ydata.push(this.chart2data.ydata[0]);

        if (this.chart2data.xdata.length > 1) {

          let startDate = moment(this.chart2data.xdata[1], 'YYYY-MM-DD HH:mm:ss').toDate();
          let minDate = moment(xAxisMin, 'YYYY-MM-DD HH:mm:ss').toDate();

          if (minDate < startDate) {
            temp.xdata.push(xAxisMin);
            temp.ydata.push(null);
          }
        }
        else {
          temp.xdata.push(xAxisMin);
          temp.ydata.push(null);
        }

        for (let i = 1; i < this.chart2data.xdata.length; i++ ) {

          temp.xdata.push(this.chart2data.xdata[i]);
          temp.ydata.push(this.chart2data.ydata[i]);
        }

        if (this.chart2data.xdata.length > 2) {

          let endDate = moment(this.chart2data.xdata[this.chart2data.xdata.length - 1], 'YYYY-MM-DD HH:mm:ss').toDate();
          let maxDate = moment(xAxisMax, 'YYYY-MM-DD HH:mm:ss').toDate();

          if (maxDate > endDate) {
            temp.xdata.push(xAxisMax);
            temp.ydata.push(null);
          }
        }
        else {
          temp.xdata.push(xAxisMax);
          temp.ydata.push(null);
        }

        this.chart2data = temp;
      }      
    }

    this.yAxisMax = (Math.ceil(this.yAxisMax / 10.0)) * 10;
    this.yAxisMin = (Math.floor(this.yAxisMin / 10.0)) * 10;
    
    //let yOffsetBasis = this.maxRainfall;
    let yOffsetBasis = this.yAxisMax;

    this.chart1data = CsvUtil.getCSVOflowData(visData.overflowData, yOffsetBasis * 0.9, 'x1','Spillage');
    this.adjustToTimezone(this.chart1data);
    
    if (type === 'Prediction') {
      this.chart7data = CsvUtil.getPredictedOflowData(visData.overflowPredictionData, yOffsetBasis * 0.9, 'x7','Predicted Spillage');
      this.adjustToTimezone(this.chart7data);
    }

    this.chart3data = CsvUtil.getCSVGapData(visData.pumpsData[0], yOffsetBasis * 0.825, 'x3','Pump One On');
    this.chart4data = CsvUtil.getCSVGapData(visData.pumpsData[1], yOffsetBasis * 0.775, 'x4','Pump Two On');
    this.chart5data = CsvUtil.getCSVGapData(visData.pumpsData[2], yOffsetBasis * 0.725, 'x5','Pump Three On');
    this.chart6data = CsvUtil.getCSVGapData(visData.pumpsData[3], yOffsetBasis * 0.675, 'x6','Pump Four On');

    this.adjustToTimezone(this.chart3data);
    this.adjustToTimezone(this.chart4data);
    this.adjustToTimezone(this.chart5data);
    this.adjustToTimezone(this.chart6data);

    // predicted slm levels
    //if (type !== 'Blockage' && type !== 'Anomaly') {
      if (visData.lvlpredPredictionData != null) {
        this.chartdataPredictedLevels = CsvUtil.getCSVData(visData.slmPredictionData, 'xPredLevels', 'Predicted Levels');
        this.adjustToTimezone(this.chartdataPredictedLevels);

        // bridge any gap between slm data and predicted slm data using historic slm predictions
        let histPredData = null;

        if (type !== 'Prediction' && visData.lvlpredData != null) {
          histPredData = CsvUtil.getLevelPredData(visData.lvlpredData, 'N/A', 'Predictions');
          this.adjustToTimezone(histPredData);
        }

        if (histPredData) {
          let slmMaxDate = '0001-01-01 00:00:00';
          if (this.chart8data && this.chart8data.xdata.length >= 2) {
            slmMaxDate = this.chart8data.xdata[this.chart8data.xdata.length - 1];
          }
          this.chartdataPredictedLevels = this.prependHistPreds(this.chartdataPredictedLevels, histPredData, slmMaxDate);
        }

        // expand y axis to accommodate predicted SLM spikes above 120
        for (let i = 1; i < this.chartdataPredictedLevels.ydata.length; ++i) {
          if (parseFloat(this.chartdataPredictedLevels.ydata[i]) > this.yAxisMax) {
            this.yAxisMax = parseFloat(this.chartdataPredictedLevels.ydata[i]); 
          }
          if (parseFloat(this.chartdataPredictedLevels.ydata[i]) < this.yAxisMin) {
            this.yAxisMin = parseFloat(this.chartdataPredictedLevels.ydata[i]); 
          }
        }
      }
    //}
    
    if (type === 'Historic') {		
      // this.chart9data = CsvUtil.getCSVGapData(spilldata, yOffsetBasis * 0.9, 'x8','StormHarvesterActive');
    }

    // C3 does not like empty arrays, so...
    let xs = {};
    let columns = [];
    let pattern = [];
    let types = {};

    //
    // disable for the moment for WW
    //
    if (false) {
    //if (this.chart1data.xdata.length >= 3) {
      xs['Spillage'] = 'x1';
      columns.push(this.chart1data.xdata);
      columns.push(this.chart1data.ydata);
      pattern.push('#6cd073');
      types['Spillage'] = 'area';
    }

    if (this.chart2data.xdata.length >= 2) { 
      xs['Rainfall'] = 'x2';
      columns.push(this.chart2data.xdata);
      columns.push(this.chart2data.ydata);
      pattern.push('#119ade');
    }

    if (this.chart3data.xdata.length >= 3) { 
      xs['Pump One On'] = 'x3';
      columns.push(this.chart3data.xdata);
      columns.push(this.chart3data.ydata);
      pattern.push('#ff0000');
    }
    
    if (this.chart4data.xdata.length >= 3) { 
      xs['Pump Two On'] = 'x4';
      columns.push(this.chart4data.xdata);
      columns.push(this.chart4data.ydata);
      pattern.push('#1200ff');
    }
    
    if (this.chart5data.xdata.length >= 3) { 
      xs['Pump Three On'] = 'x5';
      columns.push(this.chart5data.xdata);
      columns.push(this.chart5data.ydata);
      pattern.push('#ff12ff');
    }

    if (this.chart6data.xdata.length >= 3) { 
      xs['Pump Four On'] = 'x6';
      columns.push(this.chart6data.xdata);
      columns.push(this.chart6data.ydata);
      pattern.push('#884444');
    }

    if (type === 'Prediction') {
      if (this.chart7data.xdata.length >= 3) {
        xs['Predicted Spillage'] = 'x7';
        columns.push(this.chart7data.xdata);
        columns.push(this.chart7data.ydata);
        pattern.push('#9a82fb');
        types['Predicted Spillage'] = 'area';
      }
    }

    if (this.chart8data.xdata.length >= 3) {
      xs['SLM Levels'] = 'x8';
      columns.push(this.chart8data.xdata);
      columns.push(this.chart8data.ydata);
      pattern.push('#A0522D');
    }

    if (this.chartdataPredictedLevels && this.chartdataPredictedLevels.xdata.length >= 2) { 
      xs['Predicted Levels'] = 'xPredLevels';
      columns.push(this.chartdataPredictedLevels.xdata);
      columns.push(this.chartdataPredictedLevels.ydata);
      pattern.push('#228844');
    }

    if (this.chartdataHiPredThresh && this.chartdataHiPredThresh.xdata.length >= 2) { 
      xs['Hi Pred Thresholds'] = 'xHiPredThresh';
      columns.push(this.chartdataHiPredThresh.xdata);
      columns.push(this.chartdataHiPredThresh.ydata);
      pattern.push('#aaaaaa');
    }

    if (this.chartdataLoPredThresh && this.chartdataLoPredThresh.xdata.length >= 2) { 
      xs['Low Pred Thresholds'] = 'xLoPredThresh';
      columns.push(this.chartdataLoPredThresh.xdata);
      columns.push(this.chartdataLoPredThresh.ydata);
      pattern.push('#aaaaaa');
    }

    // don't let any historic data through
    if (type === 'Prediction') {

      this.chartdataPredictedLevels = this.trimToCurrentTime(this.chartdataPredictedLevels);
      this.chart2data = this.trimToCurrentTime(this.chart2data);
      this.chartdataHiPredThresh = this.trimToCurrentTime(this.chartdataHiPredThresh);
      this.chartdataLoPredThresh = this.trimToCurrentTime(this.chartdataLoPredThresh);
    }

    let regions = [];
    let grid = {};

    if (type === 'Prediction') {

      let grid0 = visData.currentDate;
      let grid1 = CsvUtil.getGridTime(visData.currentDate, 1, 'hour');
      let grid2 = CsvUtil.getGridTime(visData.currentDate, 2, 'hour');
      let grid3 = CsvUtil.getGridTime(visData.currentDate, 3, 'hour');
      let grid4 = CsvUtil.getGridTime(visData.currentDate, 4, 'hour');
      let grid5 = CsvUtil.getGridTime(visData.currentDate, 5, 'hour');
      let grid6 = CsvUtil.getGridTime(visData.currentDate, 6, 'hour');

      regions.push({axis: 'x', start: visData.currentDate, end: visData.endDate, class: 'region-predicted'});
      grid['x'] = {
        lines: [
          {value: grid0, text: 'Now'},
          {value: grid1, text: '+1hr'},
          {value: grid2, text: '+2hrs'},
          {value: grid3, text: '+3hrs'},
          {value: grid4, text: '+4hrs'},
          {value: grid5, text: '+5hrs'},
          {value: grid6, text: '+6hrs'}
        ]
      }; 
    }

    let zoomConfig: any = { enabled: false };
    let tooltipGrouped = false;
    let tickConfig: any = { culling: { max: 16 }, count: 16, format: '%H:%M' };
    let subchartConfig: any = { show: false };

    if (type === 'Historic') {
      // if (chartContainerId != 'combinedSpillsSPS' && chartContainerId != 'combinedSpillsCSO') {
      //   zoomConfig = { enabled: true, rescale: true, type: 'drag' };
      // }
      tooltipGrouped = false;
      //tickConfig = { culling: { max: 20 }, count: 20, format: '%d/%m/%Y' };
      tickConfig = { format: '%d/%m/%Y %H:%M', fit: false }; 

      let controlledNetwInterdep = (chartContainerId.substr(0, 24) == 'netw-interdep-chart-cont' && !mirrorCharts);

      if (chartContainerId != 'combinedSpillsSPS' && !controlledNetwInterdep){
        subchartConfig.show = true;
      }
    }   

    if (mirrorCharts) {
      subchartConfig.show = true;
      subchartConfig.onbrush = d => {
        mirrorCharts.forEach(mirrorChart => mirrorChart.zoom(d));
      }
    }

    // initially hide slm predictions
    let hide = undefined;
    if (this.chartdataPredictedLevels && this.chartdataPredictedLevels.xdata.length >= 2 && type !== 'Prediction') {
      hide = ['Predicted Levels'];
    }

    this.c3Chart = generate({
      bindto: '#' + chartContainerId,
      size: { height: height },
      //padding: { right: 40 },
      data: {
        xFormat: '%Y-%m-%d %H:%M:%S',
        xs: xs,
        columns: columns,     
        types: types,
        axes: {
          'SLM Levels' : 'y',
          'Rainfall' : 'y2',
        },
        hide: hide
      },
      color: { pattern: pattern },		    
      legend: { show: true },     
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          label: {
            text: 'Time/Duration',
            position: 'outer-center'
          },		            
          tick: tickConfig,
          min: xAxisMin,
          max: xAxisMax
        },
        // y:{		  
        //   show: true,
        //   min: 0,
        //   max: this.maxRainfall,            
        //   label: { text: 'Rainfall (mm)', position: 'outer-middle' }
        // },
        y:{		  
          show:true,  
          min: this.yAxisMin,
          max: this.yAxisMax,        
          label: { text: 'SLM Levels', position: 'outer-middle' }
        },
        y2:{	
          show:true,
          min: 0,
          max: this.maxRainfall,	            
          label: { text: 'Rainfall (mm)', position: 'outer-middle' }
        }
      },
      point: {
        show: false
      },
      zoom: zoomConfig,
      tooltip: {
        show: true,
        grouped: tooltipGrouped,
        position: (data, width, height, element) => {
          if (data[0].id === 'Rainfall' ||
              data[0].id === 'Spillage' ||
              data[0].id === 'Pump One On' ||
              data[0].id === 'Pump Two On' ||
              data[0].id === 'Pump Three On' ||
              data[0].id === 'Pump Four On' ||
              data[0].id === 'Predicted Spillage' ||
              data[0].id === 'SLM Levels'){
            return { top: 40, left: 0 };
          }
          return { top: -1000, left: 0 };
        } 
      },
      regions: regions,
      grid: grid,
      subchart: subchartConfig,
      onrendered: () => { 
        this.rendered = true;
        if (this.startTime > 0) {
          //this.endTime = (new Date()).valueOf();
          this.endTime = (new Date('2020-07-25T12:55:00')).valueOf();
          console.log('SPS Chart creation took ' + (this.endTime - this.startTime) + 'ms');
          this.startTime = 0;
        }
      }		
    });  
  }


  public impactActive(active: boolean) {

    // if (active) {
    //   this.c3Chart.load({
    //     xs: { 'StormHarvesterActive': 'x9' },
    //     colors: { 'StormHarvesterActive': '#17122C' },      
    //     columns: [ this.chart9data.xdata, this.chart9data.ydata ],
    //     ids: 'StormHarvesterActive'
    //     types: { 'StormHarvesterActive' : 'area' }
    //   });	
    // }
    // else {
    //   this.c3Chart.unload({ ids: 'StormHarvesterActive' });	
    // }
  }


  private trimToCurrentTime(chartdata: any) : any {

    if (chartdata.xdata.length > 0) {
        
      let temp = { xdata: [], ydata: [] };
      temp.xdata.push(chartdata.xdata[0]);
      temp.ydata.push(chartdata.ydata[0]);
      
      let momentLocalTimeNow = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss');
      
      for (let i = 1; i < chartdata.xdata.length; i++) {
        
        let momentLocalTime = moment(chartdata.xdata[i], 'YYYY-MM-DD HH:mm:ss');

        // trim any old predictions
        if (momentLocalTime > momentLocalTimeNow) {
          temp.xdata.push(chartdata.xdata[i]);
          temp.ydata.push(chartdata.ydata[i]);
        }
      }
      return temp;
    }

    return chartdata;
  }


  private prependHistPreds(chartdata, histPredData, slmMaxDate) {

    let temp = { xdata: [], ydata: [] };

    temp.xdata.push(chartdata.xdata[0]);
    temp.ydata.push(chartdata.ydata[0]);

    // prepend the historical predictions
    for (let i = 1; i < histPredData.xdata.length; i++) {
      // we can do a string compare here due to the date format
      if (histPredData.xdata[i] > slmMaxDate) {
        temp.xdata.push(histPredData.xdata[i]);
        temp.ydata.push(histPredData.ydata[i]);
      }
    }

    // append the predictions
    for (let i = 1; i < chartdata.xdata.length; i++) {
      if (chartdata.xdata[i] > slmMaxDate) {
        temp.xdata.push(chartdata.xdata[i]);
        temp.ydata.push(chartdata.ydata[i]);
      }
    }

    return temp;
  }


  private adjustToTimezone(chartdata: any) {

    for (let i = 1; i < chartdata.xdata.length; i++) { 
      let momentLocalTime = moment.utc(chartdata.xdata[i], 'YYYY-MM-DD HH:mm:ss').local();
      chartdata.xdata[i] = momentLocalTime.format('YYYY-MM-DD HH:mm:ss');
    }
  }

  
  public setYAxisZoom(newMin, newMax) {

    this.c3Chart.axis.min({y: newMin, y2: 0});
    this.c3Chart.axis.max({y: newMax, y2: this.maxRainfall});
  }
  
}
