import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpillageEvent } from '../../classes/spillage-event';
import { ApiService } from '../../classes/api.service';
import { FilterSortSpillageEvents } from '../../classes/filter-sort-spillage-events';
import { JqUtils } from '../../classes/jq-utils';
import { TimestampPair } from '../../classes/timestamp-pair';

import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-site-sps-spillage-events',
  templateUrl: './site-sps-spillage-events.component.html',
  styleUrls: ['./site-sps-spillage-events.component.scss']
})
export class SiteSpsSpillageEventsComponent implements OnInit {

  @Output() emitterJumpToVisualisation = new EventEmitter<TimestampPair>();

  filterSortSpillageEvents: FilterSortSpillageEvents;

  subSpillEvents: Subscription;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef) {
    this.filterSortSpillageEvents = new FilterSortSpillageEvents(10);
  }

  ngOnInit() {

    let siteName = this.route.snapshot.paramMap.get('name');
    
    this.subSpillEvents = this.apiService.getSpillEvents(siteName).subscribe(
      (spills: SpillageEvent[]) => {
        this.filterSortSpillageEvents.spillsReceived = spills;
        this.filterSortSpillageEvents.initialise();
        this.filterSortSpillageEvents.process();
        this.initFilterControls();
    }); 
  }

  ngOnDestroy() {

    if (this.subSpillEvents) { this.subSpillEvents.unsubscribe() };
  }

  initFilterControls() {

    let _this = this;

    $("#range-slider-litres").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxLitres,
      values: [ 0, this.filterSortSpillageEvents.maxLitres ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByLitres(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-duration").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxDuration,
      values: [ 0, this.filterSortSpillageEvents.maxDuration ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByDuration(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-rainfall").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxRainfall,
      values: [ 0, this.filterSortSpillageEvents.maxRainfall ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByRainfall(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });
 
    let callback = (function(fromDate, toDate) {

      if (fromDate) { this.filterSortSpillageEvents.setFilterByMinTimestamp(fromDate); }
      if (toDate) { this.filterSortSpillageEvents.setFilterByMaxTimestamp(moment(toDate).add(1, 'day').toDate()); }
      this.filterSortSpillageEvents.process();

    }).bind(this);

    JqUtils.datePickerSetup("#date-picker-from", "#date-picker-to", callback);

  }


  resetFilters() {

    $("#range-slider-litres").slider("values", [0, $("#range-slider-litres").slider("option", "max")]);
    $("#range-slider-duration").slider("values", [0, $("#range-slider-duration").slider("option", "max")]);
    $("#range-slider-rainfall").slider("values", [0, $("#range-slider-rainfall").slider("option", "max")]);
    this.filterSortSpillageEvents.setFilterByLitres(0, $("#range-slider-litres").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByDuration(0, $("#range-slider-duration").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByRainfall(0, $("#range-slider-rainfall").slider("option", "max"));

    $("#date-picker-from").datepicker("setDate", null);
    $("#date-picker-to").datepicker("setDate", null);
    this.filterSortSpillageEvents.setFilterByMinTimestamp(null);
    this.filterSortSpillageEvents.setFilterByMaxTimestamp(null);

    this.filterSortSpillageEvents.resetFilters();
  }


  jumpToVisualisation(startTimestamp: Date, endTimestamp: Date) {
    
    let timestampPair = new TimestampPair(startTimestamp, endTimestamp);

    this.emitterJumpToVisualisation.emit(timestampPair);
  }

}
