import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyrightYear: Date;

  constructor() { }

  ngOnInit() {
    //this.copyrightYear = new Date();
    this.copyrightYear = new Date('2020-07-25T12:55:00');
  }

}
