import { SpillageAlert } from './spillage-alert';
import { BlockageAlert } from './blockage-alert';
import { PumpAlert } from './pump-alert';
import { AnomalyAlert } from './anomaly-alert';
import { ChamberInfo } from './chamber-info';

export class Site {
  constructor(
    public id: string,
    public id2: string,
    public name: string,
    public facility: string,
    public catchment: string,
    public latitude: number,
    public longitude: number,
    public address: string,
    public spillageAlert: SpillageAlert,
    public blockageAlert: BlockageAlert,
    public pumpAlert: PumpAlert,
    public anomalyAlert: AnomalyAlert,
    public spillageAlerts: SpillageAlert[],
    public blockageAlerts: BlockageAlert[],
    public pumpAlerts: PumpAlert[],
    public anomalyAlerts: AnomalyAlert[],
    public chamberInfo: ChamberInfo,
    public anomalyPumpSpillage: string,
    public link: string,
    public mapUrl: string,
    public marker: google.maps.Marker,
    public assocSPSs: string[],
    public assocCSOs: string[]
  ) {}
}