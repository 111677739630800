import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';

import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { SpillagesSummary } from '../classes/spillages-summary';
import { BarChart } from '../classes/bar-chart';
import { BarChartNetwork } from '../classes/bar-chart-network';
import { BarChartYearOnYear } from '../classes/bar-chart-year-on-year';
import { Site } from '../classes/site';
import { NetworkSpillagesSummariesComponent } from './network-spillages-summaries/network-spillages-summaries.component';
import { NetworkSpillageEventsComponent } from './network-spillage-events/network-spillage-events.component';

@Component({
  selector: 'app-network-analysis',
  templateUrl: './network-analysis.component.html',
  styleUrls: ['./network-analysis.component.scss']
})
export class NetworkAnalysisComponent implements OnInit {

  @ViewChild(NetworkSpillagesSummariesComponent) ntwrkSpillsSummsCmpnt:NetworkSpillagesSummariesComponent;
  @ViewChild(NetworkSpillageEventsComponent) ntwrkSpillEventsCmpnt:NetworkSpillageEventsComponent;

  currentYear: number;

  timer: any;

  lastYearReducedSpillEvents: number;
  lastYearReducedDurationDays: number;
  lastYearReducedSpillageLitres: number;
  lastYearReducedSpillagePercentage: number;

  yearMinus1BtnText: string;
  yearMinus2BtnText: string;
  yearMinus3BtnText: string;
  yearMinus4BtnText: string;

  subSiteSummsYrMinus: Subscription[];
  subAggSummsLast5Yrs: Subscription;
  subAggSummsLast2YrsInMths: Subscription;

  yearMinusSiteSumms: SpillagesSummary[][];

  noSpillsChart: BarChart;
  qtySpillsChart: BarChart; 
  durSpillsChart: BarChart;

  durSpillsChart2: BarChart;
  savedSpillsChart: BarChart;
  noSpillsChart2: BarChart; 

  yearMinus1Chart: BarChartNetwork;
  yearMinus2Chart: BarChartNetwork;
  yearMinus3Chart: BarChartNetwork;
  yearMinus4Chart: BarChartNetwork;

  yearOnYearSpillsChart: BarChartYearOnYear;

  constructor(private apiService: ApiService,
              private cognitoService: CognitoService,
              private router: Router) {

    //this.currentYear = new Date().getFullYear();
    this.currentYear = new Date('2020-07-25T12:55:00').getFullYear();
    

    this.yearMinus1BtnText = 'Spills';
    this.yearMinus2BtnText = 'Spills';
    this.yearMinus3BtnText = 'Spills';
    this.yearMinus4BtnText = 'Spills';

    this.lastYearReducedSpillEvents = 0;
    this.lastYearReducedDurationDays = 0;
    this.lastYearReducedSpillageLitres = 0;
    this.lastYearReducedSpillagePercentage = 0;

    this.yearMinusSiteSumms = new Array<SpillagesSummary[]>(4);

    this.subSiteSummsYrMinus = new Array<Subscription>(4);
  }


  ngOnInit() {
    
    document.head.title = "Network Analysis - StormHarvester";
    document.body.className = "dashboard";

    this.cognitoService.refreshIdTokenStart();
  }
  
  
  ngOnDestroy() {

    // TEMP FOR WW DEMO
    //for (let i = 0; i < 4; i++) { this.subSiteSummsYrMinus[i].unsubscribe(); }
    for (let i = 0; i < 2; i++) { this.subSiteSummsYrMinus[i].unsubscribe(); }

    this.subAggSummsLast5Yrs.unsubscribe();
    this.subAggSummsLast2YrsInMths.unsubscribe();

    // only call unsubscribe on the subject once on the top level component
    this.apiService.sitesSubject.unsubscribe();

    this.cognitoService.refreshIdTokenStop();

    document.body.style.cursor = 'default';
  }


  ngAfterViewInit() {

    // IMPORTANT ! - not allowed to change angular component attributes in here
    // this chart stuff is okay as it is direct dom manipulation independent of angular

    document.body.style.cursor = 'wait';

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);

    this.CreateAggregateCharts();
    this.CreatePerSiteCharts();
  }


  CreateAggregateCharts() {

    let subjAggSummsYrMinus: Subject<SpillagesSummary>[] = [];

    let subjAggSummsYrMinusMth: Subject<SpillagesSummary>[] = [];

    subjAggSummsYrMinus.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1)); 
    subjAggSummsYrMinus.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2));
    subjAggSummsYrMinus.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 3));
    subjAggSummsYrMinus.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 4));
    subjAggSummsYrMinus.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 5));

    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 1));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 2));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 3));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 4));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 5));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 6));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 7));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 8));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 9));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 10));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 11));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 1, 12));

    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 1));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 2));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 3));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 4));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 5));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 6));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 7));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 8));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 9));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 10));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 11));
    subjAggSummsYrMinusMth.push(this.apiService.getSpillsSummary('ALL', this.currentYear - 2, 12));

    this.subAggSummsLast5Yrs = forkJoin(...subjAggSummsYrMinus)
      .subscribe((sss: SpillagesSummary[]) => {

        if (sss.length == 0) { return; }
        
        // TEMP for NWL
        // this.lastYearReducedSpillEvents += sss[0].preventableEvents;
        // this.lastYearReducedSpillageLitres = sss[0].saveableLitres;
              
        // let fractionSaved = 0;
        
        // if (sss[0].litresSpilled > 0) { fractionSaved = sss[0].saveableLitres / sss[0].litresSpilled; }

        // this.lastYearReducedSpillagePercentage = fractionSaved * 100;
        // this.lastYearReducedDurationDays = fractionSaved * sss[0].daysDuration;
        this.lastYearReducedSpillEvents += sss[1].preventableEvents;
        this.lastYearReducedSpillageLitres = sss[1].saveableLitres;
              
        let fractionSaved = 0;
        
        if (sss[1].litresSpilled > 0) { fractionSaved = sss[1].saveableLitres / sss[1].litresSpilled; }

        this.lastYearReducedSpillagePercentage = fractionSaved * 100;
        this.lastYearReducedDurationDays = fractionSaved * sss[1].daysDuration;

        
    
        this.noSpillsChart = new BarChart();
        this.noSpillsChart.createChart('noOfSpills', sss);
    
        this.qtySpillsChart = new BarChart();
        this.qtySpillsChart.createChart('qtyOfSpills', sss);
    
        this.durSpillsChart = new BarChart();
        this.durSpillsChart.createChart('durOfSpills', sss);

        this.durSpillsChart2 = new BarChart();
        this.durSpillsChart2.createChart('durOfSpills2', sss);

        this.savedSpillsChart = new BarChart();
        this.savedSpillsChart.createChart('ltrsSavedSpills', sss);

        this.noSpillsChart2 = new BarChart();
        this.noSpillsChart2.createChart('noOfSpills2', sss);
      }
    );

    this.subAggSummsLast2YrsInMths = forkJoin(  ...subjAggSummsYrMinusMth)
      .subscribe((sss: SpillagesSummary[]) => {

      this.yearOnYearSpillsChart = new BarChartYearOnYear();
      this.yearOnYearSpillsChart.createChart('year-on-year-container', sss, this.currentYear - 2, this.currentYear - 1);
    });
  }


  CreatePerSiteCharts() {

    let subjSiteSummsYrMinus: Subject<SpillagesSummary[]>[] = [];

    subjSiteSummsYrMinus.push(this.apiService.getSpillsSummaries(this.currentYear - 1));
    subjSiteSummsYrMinus.push(this.apiService.getSpillsSummaries(this.currentYear - 2));
    subjSiteSummsYrMinus.push(this.apiService.getSpillsSummaries(this.currentYear - 3));
    subjSiteSummsYrMinus.push(this.apiService.getSpillsSummaries(this.currentYear - 4));

    this.yearMinus1Chart = new BarChartNetwork();
    this.yearMinus2Chart = new BarChartNetwork();
    this.yearMinus3Chart = new BarChartNetwork();
    this.yearMinus4Chart = new BarChartNetwork();

    this.subSiteSummsYrMinus[0] = subjSiteSummsYrMinus[0].subscribe((sss: SpillagesSummary[]) => {
    
      this.yearMinusSiteSumms[0] = sss;
      this.yearMinus1Chart.createChart('year-minus-1-container', sss, 'Spills', this.router);  

      // sharing this data with child component so need to explicitly process 
      // TEMP doing 2018 for NWL
      //this.ntwrkSpillsSummsCmpnt.filterSort(this.yearMinusSiteSumms[0]);
    });

    this.subSiteSummsYrMinus[1] = subjSiteSummsYrMinus[1].subscribe((sss: SpillagesSummary[]) => {
    
      this.yearMinusSiteSumms[1] = sss;
      this.yearMinus2Chart.createChart('year-minus-2-container', sss, 'Spills', this.router);  

      // TEMP doing 2018 for NWL
      this.ntwrkSpillsSummsCmpnt.filterSort(this.yearMinusSiteSumms[1]);
    });

    // this.subSiteSummsYrMinus[2] = subjSiteSummsYrMinus[2].subscribe((sss: SpillagesSummary[]) => {
    
    //   this.yearMinusSiteSumms[2] = sss;
    //   this.yearMinus3Chart.createChart('year-minus-3-container', sss, 'Spills', this.router);  
    // });

    // this.subSiteSummsYrMinus[3] = subjSiteSummsYrMinus[3].subscribe((sss: SpillagesSummary[]) => {
    
    //   this.yearMinusSiteSumms[3] = sss;
    //   this.yearMinus4Chart.createChart('year-minus-4-container', sss, 'Spills', this.router);  
    // });

    this.apiService.getSites();
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.yearMinus1Chart.setSites(sites);
      this.yearMinus2Chart.setSites(sites);
      this.yearMinus3Chart.setSites(sites);
      this.yearMinus4Chart.setSites(sites);

      this.ntwrkSpillEventsCmpnt.setSites(sites);
      this.ntwrkSpillsSummsCmpnt.setSites(sites);
    });
  }


  yearMinusSelect(event: any, viewType: string, yearOffset: number) {

    if (event) { event.preventDefault(); }

    document.body.style.cursor = 'wait';

    switch (yearOffset) {
      case -1: {
        this.yearMinus1BtnText = viewType;
        this.yearMinus1Chart.c3Chart.destroy();
        this.yearMinus1Chart.rendered = false;
        this.yearMinus1Chart.createChart('year-minus-1-container', this.yearMinusSiteSumms[0], viewType, this.router);
        break;
      }
      case -2: {
        this.yearMinus2BtnText = viewType;
        this.yearMinus2Chart.c3Chart.destroy();
        this.yearMinus2Chart.rendered = false;
        this.yearMinus2Chart.createChart('year-minus-2-container', this.yearMinusSiteSumms[1], viewType, this.router);
        break;
      }
      case -3: {
        this.yearMinus3BtnText = viewType;
        this.yearMinus3Chart.c3Chart.destroy();
        this.yearMinus3Chart.rendered = false;
        this.yearMinus3Chart.createChart('year-minus-3-container', this.yearMinusSiteSumms[2], viewType, this.router);
        break;
      }
      case -4: {
        this.yearMinus4BtnText = viewType;
        this.yearMinus4Chart.c3Chart.destroy();
        this.yearMinus4Chart.rendered = false;
        this.yearMinus4Chart.createChart('year-minus-4-container', this.yearMinusSiteSumms[3], viewType, this.router);
        break;
      }
    }

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }


  clearWaitCursorWhenRendered() {      

    // *****************                        ***************** 
    // ***************** TEMP CHANGE FOR WESSEX ***************** 
    // *****************                        ***************** 
    // if (this.noSpillsChart  && this.noSpillsChart.rendered  &&
    //     this.qtySpillsChart && this.qtySpillsChart.rendered &&
    //     this.durSpillsChart && this.durSpillsChart.rendered &&
    //     this.yearMinus1Chart && this.yearMinus1Chart.rendered &&
    //     this.yearMinus2Chart && this.yearMinus2Chart.rendered &&
    //     this.yearMinus3Chart && this.yearMinus3Chart.rendered &&
    //     this.yearMinus4Chart && this.yearMinus4Chart.rendered &&
    //     this.yearOnYearSpillsChart && this.yearOnYearSpillsChart.rendered &&
    //     this.durSpillsChart2 && this.durSpillsChart2.rendered &&
    //     this.noSpillsChart2 && this.noSpillsChart2.rendered) {
    if (true) {

      clearInterval(this.timer);
      document.body.style.cursor = 'default';
    }
  }

}
