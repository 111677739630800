import { SpillageLevel } from './spillage-levels';

export class SpillageAlert {
  constructor(
    public spillageLevel: SpillageLevel,
    public expected: string,
    public duration: string,
    public durationMinutes: number,
    public quantity: number
  ) { }
}
