import { Component, OnInit } from '@angular/core';
import { Site } from '../../classes/site';
import { ApiService } from '../../classes/api.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';

@Component({
  selector: 'app-pump-stations',
  templateUrl: './pump-stations.component.html',
  styleUrls: ['./pump-stations.component.scss']
})
export class PumpStationsComponent implements OnInit {

  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService) {
    this.filterSortSites = new FilterSortSites(20);
  }

  ngOnInit() {

    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.removeNonAffected('sps');
      this.filterSortSites.process();
    }); 
  }

}
