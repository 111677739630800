import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoRefreshToken, CognitoUserSession, CognitoIdToken } from 'amazon-cognito-identity-js';

import * as moment from 'moment';

const _1_MIN_IN_MILLISECONDS =      60 * 1000;
const _5_MINS_IN_MILLISECONDS = 5 * 60 * 1000;


@Injectable({ providedIn: 'root' })
export class CognitoService {

  MIN_PASSWORD_LEN = 12;

  poolData = { 
    UserPoolId : 'eu-west-1_yKx3AyPMu',
    ClientId : '2tt71rc8lta92inu5tfkgfjoe3'
  };

  refreshTimer: any;


  /**********************************
  * getCognitoUser
  **********************************/
  getCognitoUser(userId: string) : CognitoUser {

    let userPool = new CognitoUserPool(this.poolData);
    let userData = {
      Username : userId,
      Pool : userPool
    };
    return new CognitoUser(userData);
  }


  /**********************************
  * getIdentityToken
  **********************************/
  getIdentityToken( userId: string,
                    password: string,
                    newPassword: string,
                    forceNewPassword: boolean,
                    caller: object,
                    successFn: (s1: string, s2: string, s3: string) => void,
                    failureFn: (s1: string, s2: string) => void,
                    newPasswordFn: () => void) {

    let cognitoUser = this.getCognitoUser(userId);

    let authenticationDetails = new AuthenticationDetails({ Username : userId, Password : password });

    let _this = this;

    console.log('forceNewPassword : ' + forceNewPassword);

    cognitoUser.authenticateUser(authenticationDetails, {

        onSuccess: function (session) {

          console.log('onSuccess');

          sessionStorage.setItem('awsUsername', userId);
          
          _this.storeTokens(session);

          successFn.call(caller);
        },

        onFailure: function(err) {

          console.log('onFailure');

          failureFn.call(caller, err.code, err.message);
        },

        newPasswordRequired: function(userAttributes, requiredAttributes) {

          console.log('newPasswordRequired');

          if (!forceNewPassword) {
            newPasswordFn.call(caller);
          }
          else {
            userAttributes.name = userId;
            cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
          }
        }

    });
  }


  /**********************************
  * storeTokens
  **********************************/
  storeTokens(session: CognitoUserSession) {
        
    if (!sessionStorage) {
      alert('browser does not support session storage to allow use of cognito identity tokens!');
      return;
    }

    let jwtIdentity = session.getIdToken().getJwtToken();
    let jwtAccess = session.getAccessToken().getJwtToken();
    let tokenRefresh = session.getRefreshToken().getToken();

    // console.log('jwtIdentity');
    // console.log(jwtIdentity);
    // console.log('jwtAccess');
    // console.log(jwtAccess);
    // console.log('tokenRefresh');
    // console.log(tokenRefresh);

    sessionStorage.setItem('awsIdentityToken', jwtIdentity);
    sessionStorage.setItem('awsAccessToken', jwtAccess);
    sessionStorage.setItem('awsRefreshToken', tokenRefresh);
  }


  /**********************************
  * clearTokens
  **********************************/
  clearTokens() {
          
    if (!sessionStorage) {
      alert('browser does not support session storage to allow use of cognito identity tokens!');
      return;
    }

    sessionStorage.setItem('awsIdentityToken', '');
    sessionStorage.setItem('awsAccessToken', '');
    sessionStorage.setItem('awsRefreshToken', '');
  }


  /**********************************
  * refreshIdToken
  **********************************/
  refreshIdToken() {

    let cognitoUser = this.getCognitoUser(sessionStorage.getItem('awsUsername'));

    let refreshToken = new CognitoRefreshToken({ RefreshToken: sessionStorage.getItem('awsRefreshToken') });

    let _this = this;

    // the old token is still valid up to its expiry time, so we don't need to 
    // worry about api calls happening between refreshSession and storeTokens
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      
      if (err) {
        alert('Error while attempting to refresh session login token !');
        return;
      }

      _this.storeTokens(session);

      console.log('cognito login token successfully refreshed');
    });
  }

  
  /**********************************
  * refreshIdTokenStart
  **********************************/
  refreshIdTokenStart() {

    // identity token expires after 60 minutes so regularly (every minute ?) check for expiry
    this.refreshTimer = setInterval(() => {
  
      if (this.isIdentityTokenAboutToExpire(sessionStorage.getItem('awsIdentityToken'))) {
        this.refreshIdToken();
      }
    }, _1_MIN_IN_MILLISECONDS);
  }


  /**********************************
  * refreshIdTokenStop
  **********************************/
  refreshIdTokenStop() {

    if (this.refreshTimer) { clearInterval(this.refreshTimer); }
  }


  /**********************************
  * isIdentityTokenExpired
  **********************************/
  isIdentityTokenExpired( idTokenString: string) : boolean {

    let idToken = new CognitoIdToken({ IdToken: idTokenString });
    let expirationTimeMs = idToken.getExpiration() * 1000;
    let nowTimeMs = Date.now();

    // let nowMoment = moment(nowTimeMs);
    // let expireMoment = moment(expirationTimeMs);
    // console.log('nowMoment : ' + nowMoment.format('YYYY-MM-DD HH:mm:ss'));
    // console.log('expireMoment : ' + expireMoment.format('YYYY-MM-DD HH:mm:ss'));
    
    return (nowTimeMs > expirationTimeMs);
  }


  /**********************************
  * isIdentityTokenAboutToExpire
  **********************************/
  isIdentityTokenAboutToExpire( idTokenString: string) : boolean {

    let idToken = new CognitoIdToken({ IdToken: idTokenString });
    let expirationTimeMs = idToken.getExpiration() * 1000;
    let nowTimeMs = Date.now();
    
    return ((expirationTimeMs - nowTimeMs) < _5_MINS_IN_MILLISECONDS);
  }


  /**********************************
  * changePassword
  **********************************/
  changePassword( userId: string,
                  oldPassword: string,
                  newPassword: string,
                  callbackFn: (err?: Error, result?: string) => void) : void {

    let cognitoUser = this.getCognitoUser(userId);

    cognitoUser.getSession(function (err) {
      if (err) {
        callbackFn(err);
      }
      else {
        cognitoUser.changePassword(oldPassword, newPassword, callbackFn);
      }
    });
  }

}