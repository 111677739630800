import { ChartAPI, generate } from 'c3';
import { SpillagesSummary } from './spillages-summary';

export class BarChart {

  c3Chart: ChartAPI = null;

  rendered: boolean;

  constructor() { }

  public createChart(chartContainerId: string, spillsSummaries: SpillagesSummary[])
  {
    if (spillsSummaries.length != 5) { return; }

    this.rendered = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    if (chartContainerId.includes('noOfSpills')) {

      let  xdata=[], y1data=[], y2data=[], y3data=[];
      xdata.push('Year');
      y1data.push('High');
      y2data.push('Moderate');
      y3data.push('Low');

      spillsSummaries.forEach(ss => {
        if (ss.siteName != '') {
          xdata.push(ss.year);
          y1data.push(ss.numHigh);
          y2data.push(ss.numModerate);
          y3data.push(ss.numLow);
        }
      });

      this.c3Chart = generate({
        bindto: '#' + chartContainerId,
        size: { height: 300 },
        data: {
          x: 'Year',      
          columns: [ xdata, y1data, y2data, y3data ],
          type: 'bar',
          groups: [ ['High', 'Moderate', 'Low'] ],
          order: null	      
        },
        color: { pattern: ['#E5332A','#F29100','#FFD032'] },
        onrendered: () => { this.rendered = true; }	
      });
    }
    else {

      let xdata=[], ydata=[];

      xdata.push('Year');
      switch (chartContainerId) {
        case 'qtyOfSpills':       { ydata.push('Spillage (litres)'); break; }
        case 'ltrsSavedSpills':   { ydata.push('Saved (litres)'); break; }
        default:                  { ydata.push('Duration (days)'); break; }
      } 

      spillsSummaries.forEach(ss => {
        if (ss.siteName != '') {
          xdata.push(ss.year);
          switch (chartContainerId) {
            case 'qtyOfSpills':       { ydata.push(ss.litresSpilled); break; }
            case 'ltrsSavedSpills':   { ydata.push(ss.saveableLitres); break; }
            default:                  { ydata.push(ss.daysDuration); break; }
          }
        }      	
      });

      this.c3Chart  = generate({
        bindto: '#' + chartContainerId,
        size: { height: 300 },
        data: {
          x: 'Year',    
          columns: [ xdata, ydata ],
          type: 'bar',
        },
        onrendered: () => { this.rendered = true; }	
      });
    }
    
  } 
  
}
