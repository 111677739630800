import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum MessageId { FILTER_BLOCKAGES_NONE, FILTER_BLOCKAGES_HIGH, FILTER_BLOCKAGES_MODERATE, FILTER_BLOCKAGES_LOW, 
                        NAVIGATE_SPILLAGE_PREDICTIONS, NAVIGATE_PUMP_ALERTS, NAVIGATE_ANOMALY_ALERTS,
                        PERMITTED_ALERTS_HIDE, PERMITTED_ALERTS_SHOW }

@Injectable({ providedIn: 'root' })
export class MessageService {

  msgSubject: Subject<MessageId>;


  /**********************************
  * constructor
  **********************************/
  constructor() {

    this.msgSubject = new Subject<MessageId>();
  }


  /**********************************
  * sendMessage
  **********************************/
  sendMessage(msgId: MessageId) {

    this.msgSubject.next(msgId);
  }

}
