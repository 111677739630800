import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPlusMinus'
})
export class NumberPlusMinusPipe implements PipeTransform {

  transform(value: number): string {
    return (value > 0) ? '+' + value.toString() : value.toString();
  }

}
