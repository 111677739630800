import { Component, OnInit, ViewChildren, QueryList  } from '@angular/core';
import { ExpandedRowComponent } from '../../shared-components/expanded-row/expanded-row.component';
import { Site } from '../../classes/site';
import { ApiService } from '../../classes/api.service';
import { MessageService, MessageId } from '../../classes/message.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';


@Component({
  selector: 'app-blockage-alerts',
  templateUrl: './blockage-alerts.component.html',
  styleUrls: ['./blockage-alerts.component.scss']
})
export class BlockageAlertsComponent implements OnInit {

  @ViewChildren(ExpandedRowComponent) expandedRows: QueryList<ExpandedRowComponent>;

  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService,
              private messageService: MessageService) {
    this.filterSortSites = new FilterSortSites(20);
  }

  ngOnInit() {  
    
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.removeNonAffected('blockage');
      this.filterSortSites.process();
    });

    this.messageService.msgSubject.subscribe((msgId: MessageId) => {
      this.processMessage(msgId);
    });
  }

  createDynamicElements(site: Site) {

    let expandedRow = this.expandedRows.find(er => er.site.id == site.id);

    expandedRow.createChart(site, 'blockChartCont');
    expandedRow.createMap(site);
  }

  processMessage(msgId: MessageId) {

    let allowedMessages = [
      MessageId.FILTER_BLOCKAGES_NONE, 
      MessageId.FILTER_BLOCKAGES_HIGH,
      MessageId.FILTER_BLOCKAGES_MODERATE,
      MessageId.FILTER_BLOCKAGES_LOW
    ];

    if (!allowedMessages.includes(msgId)) {
      return;
    }

    this.filterSortSites.severityCheckboxes.forEach(scb => {
      if ((scb.text === 'HIGH' && msgId === MessageId.FILTER_BLOCKAGES_HIGH) ||
          (scb.text === 'MODERATE' && msgId === MessageId.FILTER_BLOCKAGES_MODERATE) ||
          (scb.text === 'LOW' && msgId === MessageId.FILTER_BLOCKAGES_LOW)) {

        scb.checked = true;
      }
      else {
        scb.checked = false;
      }
    });

    this.filterSortSites.process();
  }

}
