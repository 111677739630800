import {} from 'googlemaps';
import { Subscription } from 'rxjs';
import { LoadService } from '../classes/load.service';
import { Site } from '../classes/site';
import { RuntimeConfig } from '../classes/runtime-config';

export class SiteMap {

  subscription: Subscription = null;

  /*********************
    constructor
  *********************/
  constructor(private loadService: LoadService, 
              private runtimeConfig: RuntimeConfig,
              private site: Site,
              private singleMap: boolean = false) {

    if (!this.loadService.scriptLoading) {
      this.loadService.loadScript('https://maps.googleapis.com/maps/api/js?key=' + this.runtimeConfig.getGoogleMapsApiKey());
    }

    this.subscription = this.loadService.successSubject.subscribe(success => { if (success) { this.onLoad(this.site); } });
  }


  /*********************
    abort
  *********************/
  abort() {

    if (this.subscription) { this.subscription.unsubscribe(); }
  }


  /*********************
    onLoad
  *********************/
  onLoad(site: Site) {

    let center = new google.maps.LatLng(site.latitude, site.longitude);

    let mapOptions: google.maps.MapOptions = {
      zoom: 17,
      center: center,
      disableDefaultUI: true,
      scrollwheel: false,
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.SATELLITE
    };

    let elementId = this.singleMap ? 'map_canvas' : 'map_canvas_' + site.id2;

    let map = new google.maps.Map(document.getElementById(elementId), mapOptions);
    
    let markers: google.maps.Marker[] = [];

    let color = '#4bef8e'

    //switch(site.spillageAlert.spillageLevel.id) {
      switch(site.blockageAlert.blockageLevel.id) {
        case 1: color = '#FFD032'; break; // LOW
        case 2: color = '#F29100'; break; // MODERATE
        case 3: color = '#E5332A'; break; // HIGH
    };

    let scaleno = 0.25;
    let anchor = new google.maps.Point(50, 50);
    let ipath = '';

    switch(site.facility) {
      case 'SPS':
        ipath = 'M 50 0 L 100 100 H 0 z'; // triangle
        break;
      case 'CSO':
        ipath = 'M 12 12 H 88 V 88 H 12 z'; // square
        break;
      case 'WWTW':
        ipath = 'M 0 50 A 50 50, 0, 1, 0, 100 50 A 50 50, 0, 1, 0, 0 50'; // circle
        break;			        			        			        			        
    };

    markers.push(new google.maps.Marker({
      map: map,
      position: center,
      icon: {
        //path: 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z',
        path: ipath,
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 1.5,
        strokeColor: '#ffffff',
        //scale: 0.05, //size of the marker, careful! this scale also affects anchor and labelOrigin
        scale: scaleno,
        //anchor: new google.maps.Point(200,510), //position of the icon, careful! this is affected by scale		        
        anchor: anchor
      }        
    }));
    
  }

}
