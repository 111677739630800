import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { ApiService } from '../../classes/api.service';
import { FilterSortSpillageEvents } from '../../classes/filter-sort-spillage-events';
import { JqUtils } from '../../classes/jq-utils';
import { Site } from '../../classes/site';

import * as moment from 'moment';
import { SpillageEvent } from 'src/app/classes/spillage-event';

declare var $: any;

@Component({
  selector: 'app-network-spillage-events',
  templateUrl: './network-spillage-events.component.html',
  styleUrls: ['./network-spillage-events.component.scss']
})
export class NetworkSpillageEventsComponent implements OnInit {

  filterSortSpillageEvents: FilterSortSpillageEvents;

  subSpillEvents: Subscription;

  sites: Site[] = [];

  
  constructor(private apiService: ApiService,
              private changeDetectorRef: ChangeDetectorRef,
              private router: Router) {

    this.filterSortSpillageEvents = new FilterSortSpillageEvents(10);
  }


  ngOnInit() {
    this.RetrieveAllSiteDetails();
  }


  ngOnDestroy() {

    if (this.subSpillEvents) { this.subSpillEvents.unsubscribe() };
  }


  RetrieveAllSiteDetails() {

    this.subSpillEvents = this.apiService.getSpillEvents('ALL')
      .subscribe(ses => {

        this.filterSortSpillageEvents.spillsReceived = ses;
        this.filterSortSpillageEvents.initialise();
        this.filterSortSpillageEvents.process();
        this.initFilterControls();
      }
    ); 
  } 


  initFilterControls() {

    let _this = this;

    $("#range-slider-litres").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxLitres,
      values: [ 0, this.filterSortSpillageEvents.maxLitres ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByLitres(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-duration").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxDuration,
      values: [ 0, this.filterSortSpillageEvents.maxDuration ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByDuration(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-rainfall").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillageEvents.maxRainfall,
      values: [ 0, this.filterSortSpillageEvents.maxRainfall ],
      slide: function(event, ui) { _this.filterSortSpillageEvents.setFilterByRainfall(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillageEvents.process(); _this.changeDetectorRef.detectChanges(); }
    });

    let callback = (function(fromDate, toDate) {

      if (fromDate) { this.filterSortSpillageEvents.setFilterByMinTimestamp(fromDate); }
      if (toDate) { this.filterSortSpillageEvents.setFilterByMaxTimestamp(moment(toDate).add(1, 'day').toDate()); }
      this.filterSortSpillageEvents.process();

    }).bind(this);

    JqUtils.datePickerSetup("#date-picker-from", "#date-picker-to", callback);

  }


  resetFilters() {

    $("#range-slider-litres").slider("values", [0, $("#range-slider-litres").slider("option", "max")]);
    $("#range-slider-duration").slider("values", [0, $("#range-slider-duration").slider("option", "max")]);
    $("#range-slider-rainfall").slider("values", [0, $("#range-slider-rainfall").slider("option", "max")]);
    this.filterSortSpillageEvents.setFilterByLitres(0, $("#range-slider-litres").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByDuration(0, $("#range-slider-duration").slider("option", "max"));
    this.filterSortSpillageEvents.setFilterByRainfall(0, $("#range-slider-rainfall").slider("option", "max"));

    $("#date-picker-from").datepicker("setDate", null);
    $("#date-picker-to").datepicker("setDate", null);
    this.filterSortSpillageEvents.setFilterByMinTimestamp(null);
    this.filterSortSpillageEvents.setFilterByMaxTimestamp(null);

    this.filterSortSpillageEvents.resetFilters();
  }


  public setSites(sites: Site[]) : void 
  {
    this.sites = sites;
  }


  jumpToVisualisation(spill: SpillageEvent) {

    let start = spill.startTimestamp.toISOString();
    let end = spill.endTimestamp.toISOString();

    let site = this.sites.find(site => site.name == spill.siteName);
    let url = 'site' + site.facility + '/' + site.name;
    this.router.navigate([url], { fragment: 'dataVisHeader', queryParams: { start: start, end: end } })
  }

}
