import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-rainfall-radar',
  templateUrl: './rainfall-radar.component.html',
  styleUrls: ['./rainfall-radar.component.scss']
})
export class RainfallRadarComponent implements OnInit {

  @Output() sliderValue = new EventEmitter<number>();
  @Output() radarToggle = new EventEmitter<boolean>();

  radarTimes: string[] = ['+ 0 mins','+ 15 mins',  '+ 30 mins',  '+ 45 mins',
                          '+ 1 hr',  '+ 1.15 hrs', '+ 1.30 hrs', '+ 1.45 hrs',
                          '+ 2 hrs', '+ 2.15 hrs', '+ 2.30 hrs', '+ 2.45 hrs',
                          '+ 3 hrs', '+ 3.15 hrs', '+ 3.30 hrs', '+ 3.45 hrs',
                          '+ 4 hrs', '+ 4.15 hrs', '+ 4.30 hrs', '+ 4.45 hrs',
                          '+ 5 hrs', '+ 5.15 hrs', '+ 5.30 hrs', '+ 5.45 hrs']

  radarTime: string = this.radarTimes[0];
  radarOn: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  sliderChanged(event: any) {
    let timeOffset = parseInt(event.target.value);

    if (timeOffset >= 0 && timeOffset < this.radarTimes.length) {
      this.radarTime = this.radarTimes[timeOffset];
      this.sliderValue.emit(timeOffset);
      this.radarOn = true;
    }
  }

  radarToggled(event: any) {
    this.radarOn = !this.radarOn;
    this.radarToggle.emit(this.radarOn);
  }

}
