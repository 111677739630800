import { ChartAPI, generate } from 'c3';
import * as moment from 'moment';
import { SpillagesSummary } from './spillages-summary';

export class BarChartYearOnYear {

  c3Chart: ChartAPI = null;

  rendered: boolean;

  constructor() { }

  public createChart(chartContainerId: string, spillsSummaries: SpillagesSummary[], yearMinus2: number, yearMinus1: number)
  {
    if (spillsSummaries.length < 1) { return; }

    this.rendered = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    let xdata = new Array(13);
    let yM2H = new Array(13);
    let yM2M = new Array(13);
    let yM2L = new Array(13);
    let yM1H = new Array(13);
    let yM1M = new Array(13);
    let yM1L = new Array(13);

    xdata[0] = 'Month';
    yM2H[0] = 'yM2H';
    yM2M[0] = 'yM2M';
    yM2L[0] = 'yM2L';
    yM1H[0] = 'yM1H';
    yM1M[0] = 'yM1M';
    yM1L[0] = 'yM1L';

    for (let i = 1; i <= 12; i++) {
      xdata[i] = moment({ month: i-1}).format('MMM');
    }

    spillsSummaries.forEach(ss => {
      if (ss.year === yearMinus2) {
        yM2H[ss.month] = ss.numHigh;
        yM2M[ss.month] = ss.numModerate;
        yM2L[ss.month] = ss.numLow;
      }
      else {
        yM1H[ss.month] = ss.numHigh;
        yM1M[ss.month] = ss.numModerate;
        yM1L[ss.month] = ss.numLow;
      }
    });

    this.c3Chart = generate({
      bindto: '#' + chartContainerId,
      size: { height: 300 },
      data: {
        x: 'Month',     
        columns: [ xdata, yM2H, yM2M, yM2L, yM1H, yM1M, yM1L ],
        type: 'bar',
        groups: [
          ['yM2H', 'yM2M', 'yM2L'],
          ['yM1H', 'yM1M', 'yM1L']
        ],
        colors: {
          yM2H: '#E5332A',
          yM2M: '#F29100',
          yM2L: '#FFD032',
          yM1H: '#E5332A',
          yM1M: '#F29100',
          yM1L: '#FFD032',					
        },
        names: {
          yM2H: yearMinus2.toString() + '-High',
          yM2M: yearMinus2.toString() + '-Moderate',
          yM2L: yearMinus2.toString() + '-Low',
          yM1H: yearMinus1.toString() + '-High',
          yM1M: yearMinus1.toString() + '-Moderate',
          yM1L: yearMinus1.toString() + '-Low',					
        },
        order: null
      },
      axis: { x: { type: 'category' } },		    
      bar: { space: 0.25 },
      zoom: { enabled: true },
      onrendered: () => { this.rendered = true; }
    });       
  } 
}
