import { Component, OnInit } from '@angular/core';
import { Site } from '../../classes/site';
import { ApiService } from '../../classes/api.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';

@Component({
  selector: 'app-csos',
  templateUrl: './csos.component.html',
  styleUrls: ['./csos.component.scss']
})
export class CsosComponent implements OnInit {

  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService) {
    this.filterSortSites = new FilterSortSites(20);
  }

  ngOnInit() {

    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.removeNonAffected('cso');
      this.filterSortSites.process();
    }); 
  }

}
