import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FloodWarningData } from '../../../classes/flood-warning-data';
import { ApiService } from '../../../classes/api.service';
import { MessageService, MessageId } from '../../../classes/message.service';

@Component({
  selector: 'app-status-floods',
  templateUrl: './status-floods.component.html',
  styleUrls: ['./status-floods.component.scss']
})
export class StatusFloodsComponent implements OnInit {

  subsfloodWarnings: Subscription;
  floodWarningData: FloodWarningData = new FloodWarningData({items:[]});
  hidePermittedAlerts: boolean = false;

  constructor(private apiService: ApiService,
              private messageService: MessageService) {}

  ngOnInit() {
    this.subsfloodWarnings = this.apiService.getFloodWarnings('Yorkshire')
      .subscribe(fwd => this.floodWarningData = fwd);
  }

  ngOnDestroy() {
    this.subsfloodWarnings.unsubscribe();
  }

  navigateToFloodServiceWebsite() {
    window.location.href = 'https://flood-warning-information.service.gov.uk/warnings';
  }

  permittedAlertsToggle() {
    this.hidePermittedAlerts = !this.hidePermittedAlerts;
    if (this.hidePermittedAlerts) {
      this.messageService.sendMessage(MessageId.PERMITTED_ALERTS_HIDE);
    }
    else {
      this.messageService.sendMessage(MessageId.PERMITTED_ALERTS_SHOW);
    }
  }

  buttonText() {
    if (!this.hidePermittedAlerts) {
      return "Mute Permitted Alerts";
    }
    else {
      return "Unmute Permitted Alerts";
    }
  }

  buttonStyle() {
    return {
      width: '13em',
      height: '3.5em',
      marginTop: '-5em',
      background: 'none',
      backgroundColor: this.hidePermittedAlerts ? '#455470' : '#8AA9D6',
      fontSize: '1em'
    };
  }

}
