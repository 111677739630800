export class FloodWarningData {

  numAlerts: number = 0;
  numWarnings: number = 0;
  numSevere: number = 0;

  alertStrings: string[] = [];
  warningStrings: string[] = [];
  severeStrings: string[] = [];

  alertDescriptions: string = '';
  warningDescriptions: string = '';
  severeDescriptions: string = '';
    
  constructor(data: any) {

    if (!data || !data.items) {
      console.error('Flood Warning Data Retrieval - Invalid response from Environment Agency API');
      return;
    }
          
    data.items.forEach(item => {

      if (item.description && item.severityLevel) {

        switch (item.severityLevel) {
          case 3 : {
            this.numAlerts++;
            this.alertStrings.push(item.description);
            break;
          }
          case 2 : {
            this.numWarnings++;
            this.warningStrings.push(item.description);
            break;
          }
          case 1 : {
            this.numSevere++;
            this.severeStrings.push(item.description);
            break;
          }
        }
      }
      
    });

    this.alertDescriptions = this.alertStrings.join(' \u2026 ');
    this.warningDescriptions = this.warningStrings.join(' \u2026 ');
    this.severeDescriptions = this.severeStrings.join(' \u2026 ');

        
  }
}