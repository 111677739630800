import * as moment from 'moment';

export class CsvUtil {

  static makeHttpRequest (url) : Promise<string> {

    let request = new XMLHttpRequest();
  
    return new Promise(function (resolve, reject) {
  
      request.onreadystatechange = () => {
  
        if (request.readyState !== 4) {
          return;
        }
        
        if (request.status == 200) {
          resolve(request.responseText);
        }
        else {
          reject(request.status);
        }
      };
  
      request.open('GET', url, true);
      request.send();
    });
  }


  static getCSVGapData(csvdata, yval, xname, yname)
  {
    let data = { xdata: [], ydata: []};

    data.xdata.push(xname);
    data.ydata.push(yname);  

    return CsvUtil.addCSVGapData(data, csvdata, yval);   
  }


  static addCSVGapData(data, csvdata, yval)
  {
    let jsonObject = csvdata.split(/\r?\n|\r/);

    for (let i = 1; i < jsonObject.length - 1; i+=2) {

      if (jsonObject[i].indexOf(',') > -1 &&
          jsonObject[i+1].indexOf(',') > -1) {
        
        let linedata1 = jsonObject[i].split(',');
        let linedata2 = jsonObject[i+1].split(',');       

        let v1 = linedata1[0];
        let v2 = linedata2[0];

        data.xdata.push(v1);
        data.ydata.push(yval);

        data.xdata.push(v2);
        data.ydata.push(yval);

        data.xdata.push(v2);
        data.ydata.push('null');							
      }
    }

    return data;
  }


  static getCSVOflowData(csvdata, yval, xname, yname)
  {
    let data = { xdata: [], ydata: []};

    data.xdata.push(xname);
    data.ydata.push(yname);  

    return CsvUtil.addCSVOFlowData(data, csvdata, yval);   
  }


  static addCSVOFlowData(data, csvdata, yval)
  {
    let jsonObject = csvdata.split(/\r?\n|\r/);

    for (let i = 1; i < jsonObject.length - 1; i+=2) {

      if (jsonObject[i].indexOf(',') > -1 &&
          jsonObject[i+1].indexOf(',') > -1) {
        
        let linedata1 = jsonObject[i].split(',');
        let linedata2 = jsonObject[i+1].split(',');       

        let v1 = linedata1[0];
        let v2 = linedata2[0];

        data.xdata.push(v1);
        data.ydata.push(0);

        data.xdata.push(v1);
        data.ydata.push(yval);

        data.xdata.push(v2);
        data.ydata.push(yval);

        data.xdata.push(v2);
        data.ydata.push('null');				
        //data.ydata.push(0);				
      }
    }

    return data;
  }


  static getLevelPredData(csvdata, xname, yname)
  {
    let data = { xdata: [], ydata: [] };

    data.xdata.push(xname);
    data.ydata.push(yname);
    
    return CsvUtil.addLevelPredData(data, csvdata, yname);   
  }


  static addLevelPredData(data, csvdata, yname)
  {
    let use_index = 0;

    if (yname === 'Predictions') {
      use_index = 1;
    }
    else if (yname === 'Hi Pred Thresholds') {
      use_index = 2;
    }
    else if (yname === 'Low Pred Thresholds') {
      use_index = 3;
    } 
    else {
      return data;
    }

    let jsonObject = csvdata.split(/\r?\n|\r/);

    for (let i = 1; i < jsonObject.length; i++) {

      if (jsonObject[i].indexOf(',') > -1) {
        
        let linedata = jsonObject[i].split(',');
      
        data.xdata.push(linedata[0]);
        data.ydata.push(linedata[use_index]);	
      }
    }

    return data;
  }


  static getCSVData(csvdata, xname, yname)
  {
    let data = { xdata: [], ydata: [] };

    data.xdata.push(xname);
    data.ydata.push(yname);  

    return CsvUtil.addCSVData(data, csvdata);    
  }


  static addCSVData(data, csvdata)
  {
    let jsonObject = csvdata.split(/\r?\n|\r/);

    for (let i = 1; i < jsonObject.length; i++) {
      if (jsonObject[i].indexOf(',') > -1) {
        let linedata = jsonObject[i].split(',')
        data.xdata.push(linedata[0]);
        data.ydata.push(linedata[1]);
      }
    }

    return data;
  }


  static getPredictedOflowData(csvdata, yval, xname, yname)
  {
    let data = { xdata: [], ydata: []};

    data.xdata.push(xname);
    data.ydata.push(yname);  

    return CsvUtil.addPredictedOflowData(data, csvdata, yval);   
  }


  static addPredictedOflowData(data, csvdata, yval)
  {
    let lines = csvdata.split(/\r?\n|\r/);

    for (let i = 1; i < lines.length; i++) {

      if (lines[i].indexOf(',') > -1) {
        
        let line = lines[i].split(',');      

        let v1 = line[0];
        let v2 = line[1];

        data.xdata.push(v1);
        data.ydata.push(0);

        data.xdata.push(v1);
        data.ydata.push(yval);

        data.xdata.push(v2);
        data.ydata.push(yval);

        data.xdata.push(v2);
        //data.ydata.push('null');		
        data.ydata.push(0);				
      }
    }

    return data;
  }


  static getCSVDataIgnoreHeader(csvdata, dcol1, dcol2, xname, yname)
  {
    let xdata=[], ydata=[];
    xdata.push(xname);
    ydata.push(yname);  

    let jsonObject = csvdata.split(/\r?\n|\r/);

    for (let i = 0; i < jsonObject.length; i++) {
      if (i > 0 && jsonObject[i].indexOf(',') > -1) {
        let linedata = jsonObject[i].split(',')
        xdata.push(linedata[dcol1-1]);
        ydata.push(linedata[dcol2-1]);			
      }
    }

    return {
      xdata: xdata,
      ydata: ydata
    }
  }


  static getCSV3Data(csvdata, dcol1, dcol2, dcol3 ,xname,y1name,y2name)
  {
    var xdata=[], y1data=[], y2data=[];
    xdata.push(xname);
    y1data.push(y1name);
    y2data.push(y2name);

    var jsonObject = csvdata.split(/\r?\n|\r/);
    // 1 to skip titles
    for (var i = 1; i < jsonObject.length; i++) {
      if (jsonObject[i].indexOf(',') > -1) {
        let linedata = jsonObject[i].split(',')
        xdata.push(linedata[dcol1-1]);
        y1data.push(linedata[dcol2-1]);
        y2data.push(linedata[dcol3-1]);
        
      }
    }

    return { xdata: xdata, y1data: y1data, y2data: y2data };
  }


  static getRegStartDate(strDate, strTime, strPeriod)
  {
    let dt1 = moment(strDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    let dt2 = moment(dt1).subtract(strTime, strPeriod).format('YYYY-MM-DD HH:mm:ss');
    return dt2;
  }

  
  static getRegEndDate(strDate, strTime, strPeriod)
  {
    let dt1 = moment(strDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    let dt2 = moment(dt1).add(strTime, strPeriod).format('YYYY-MM-DD HH:mm:ss');
    return dt2;
  }


  static getGridTime(strDate, strTime, strPeriod)
  {
    let gtime1 = moment(strDate, 'YYYY-MM-DD HH:mm:ss').toDate();
    let gtime2 = moment(gtime1).add(strTime, strPeriod).format('YYYY-MM-DD HH:mm:ss');
    return gtime2;
  }

  
  static reduceChartData(chartdata: any) : any  {

    let ORIG_LEN = chartdata.xdata.length;
        
    let temp = { xdata: [], ydata: [] };

    temp.xdata.push(chartdata.xdata[0]);
    temp.ydata.push(chartdata.ydata[0]);

    if (ORIG_LEN > 3) {

      temp.xdata.push(chartdata.xdata[1]);
      temp.ydata.push(chartdata.ydata[1]);

      let yPrev = chartdata.ydata[1];
      let xCurr = chartdata.xdata[2];
      let yCurr = chartdata.ydata[2];
      
      for (let i = 3; i < ORIG_LEN; i++) {

        let xNext = chartdata.xdata[i];
        let yNext = chartdata.ydata[i];

        if (yCurr > 0 || (yCurr == 0 && (yPrev > 0 || yNext > 0))) {
          
          temp.xdata.push(xCurr);
          temp.ydata.push(yCurr);
        }
          
        yPrev = yCurr;
        xCurr = xNext;
        yCurr = yNext;
      }

      temp.xdata.push(chartdata.xdata[ORIG_LEN - 1]);
      temp.ydata.push(chartdata.ydata[ORIG_LEN - 1]);

      chartdata = temp;
    }

    return chartdata;

    // leave downsampling for now as it is non-trivial to get it working with

    // // downsample while maintaining min and max for any group
    // let NEW_LEN = chartdata.xdata.length;
    // let CHART_WIDTH = 960.0;
    // let STEP = Math.floor((ORIG_LEN / CHART_WIDTH) / 2); // 2 point step because max/min

    // // need to process at least 3 points for every min/max pair
    // if (STEP >= 3) {

    //   temp = { xdata: [], ydata: [] };
      
    //   temp.xdata.push(chartdata.xdata[0]);
    //   temp.ydata.push(chartdata.ydata[0]);
    
    //   let idx = 1;

    //   while (true) {
        
    //     while (chartdata.ydata[idx] == 0) {
    //       temp.xdata.push(chartdata.xdata[idx]);
    //       temp.ydata.push(chartdata.ydata[idx]);
    //       idx++;
    //       if (idx == NEW_LEN) { break; }
    //     }

    //     if (idx == NEW_LEN) { break; }

    //     let minY = 1000;
    //     let maxY = 0;
    //     let minIdx = -1;
    //     let maxIdx = -1;
    //     let minX = '';
    //     let maxX = '';

    //     let start = idx;
    //     let end = start + STEP;
    //     if (end > NEW_LEN) { end = NEW_LEN; }

    //     while (idx < end) {

    //       if (chartdata.ydata[idx] < minY) {
    //         minY = chartdata.ydata[idx];
    //         minIdx = idx;
    //         minX = chartdata.xdata[idx];
    //       }
    //       if (chartdata.ydata[idx] > maxY) { 
    //         maxY = chartdata.ydata[idx];
    //         maxIdx = idx;
    //         maxX = chartdata.xdata[idx];
    //       }

    //       idx++;
    //       if (idx == NEW_LEN || chartdata.ydata[idx] == 0) { break; }
    //     }

    //     if (minIdx == maxIdx) {
    //       temp.xdata.push(minX);
    //       temp.ydata.push(minY);
    //     }
    //     else {
    //       if (minIdx < maxIdx) {
    //         temp.xdata.push(minX);
    //         temp.ydata.push(minY);
    //         temp.xdata.push(maxX);
    //         temp.ydata.push(maxY);
    //       }
    //       else {
    //         temp.xdata.push(maxX);
    //         temp.ydata.push(maxY);
    //         temp.xdata.push(minX);
    //         temp.ydata.push(minY);
    //       }
    //     }

    //     if (idx == NEW_LEN) { break; }
    //   }

    //   return temp;
    // }
  }

}