import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadService {

  public successSubject: AsyncSubject<boolean>;
  public scriptLoading: boolean = false;

  constructor() { }

  loadScript(url: string) {

    this.scriptLoading = true;
    this.successSubject = new AsyncSubject<boolean>();
    let script = document.createElement("script");
    script.onload = () => { this.successSubject.next(true); this.successSubject.complete(); };
    script.onerror = () => { this.successSubject.next(false); this.successSubject.complete(); };
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    
  }
}
