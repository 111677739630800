import { SpillagesSummary } from './spillages-summary';
import { Site } from './site';

export class SpillagesSummaries {
  
  static parse(data: any, year: number, month?: number) : SpillagesSummary[] {

    let arr: SpillagesSummary[] = [];

    if (!data || data.retcode == undefined || data.rettext == undefined ||
        data.value == undefined || data.value == null || typeof data.value != 'string') {
      console.error('Spillages Summary Retrieval - Invalid response from API Gateway');
      return arr;
    }

    if (data.retcode !== 1) {
      console.error('Spillages Summary Retrieval - Error returned : ' + data.rettext);
      return arr;
    }
      
    let lines = data.value.split(/\r?\n|\r/);

    if (lines.length < 2 || lines[1].indexOf(',') === -1) {
      return arr;
    }

    for (let i = 1; i < lines.length; i++) {

      let linedata = lines[i].split(',');

      if (linedata.length == 13) {
        
        let spillagesSummary = new SpillagesSummary(

          linedata[0].replace(/\+/g, ' '), // siteName
          '',
          year,
          month ? month : 0,
          parseInt(linedata[1]), // numEvents
          parseInt(linedata[2]), // litresSpilled
          parseFloat(linedata[3]), // daysDuration
          parseInt(linedata[4]), // preventableEvents
          parseInt(linedata[5]), // saveableLitres
          parseFloat(linedata[6]), // percentageSaveable
          parseInt(linedata[7]), // numHigh
          parseInt(linedata[8]), // numModerate
          parseInt(linedata[9]), // numLow
          parseInt(linedata[10]), // rainCause
          parseInt(linedata[11]), // pumpCause
          parseInt(linedata[12])  // blockageCause     
        );

        arr.push(spillagesSummary);
      }      
    }

    return arr; 
  }


  static assign(spillagesSummaries: SpillagesSummary[], sites: Site[]): void {

    spillagesSummaries.forEach(spillagesSummary => {
      let site = sites.find(site => site.name == spillagesSummary.siteName);
      if (site) {
        spillagesSummary.catchment = site.catchment;
      }
    });
  }

  
  static getEmptySpillagesSummary() : SpillagesSummary {
    
    return new SpillagesSummary('', '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }

}