import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncSubject, Subscription } from 'rxjs';

import { Site } from '../classes/site';
import { Sites } from '../classes/sites';
import { SiteMap } from '../classes/site-map';
import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { LoadService } from '../classes/load.service';
import { ChartCSO } from '../classes/chart-cso';
import { VisualisationData } from '../classes/visualisation-data';
import { CsoSpsCombined } from '../classes/cso-sps-combined';
import { RuntimeConfig } from '../classes/runtime-config';
import { JqUtils } from '../classes/jq-utils';
import { TimestampPair } from '../classes/timestamp-pair';
import { SiteCsoSpsCombinedComponent } from './site-cso-sps-combined/site-cso-sps-combined.component';
import { SiteCsoCombinedSpillageEventsComponent } from './site-cso-combined-spillage-events/site-cso-combined-spillage-events.component';

import * as moment from 'moment';

declare var $: any;

var CUSTOM_DATE_IDX = 5;
var LAST_IDX_SPILLAGE_TABLE = -1;

@Component({
  selector: 'app-site-cso',
  templateUrl: './site-cso.component.html',
  styleUrls: ['./site-cso.component.scss']
})
export class SiteCsoComponent implements OnInit {

  //@ViewChild(SiteCsoSpsCombinedComponent) siteCsoSpsCombinedComponent;
  //@ViewChild(SiteCsoCombinedSpillageEventsComponent) siteCsoCombinedSpillageEventsComponent;

  site: Site;
  siteName: string;
  assocSPSs: string[];

  timer: any;
  
  dataVisBtnText: string;
  spsSelectBtnText: string;

  displayImpact: boolean = false;
  
  // CHANGE THIS BACK WHEN RE-ENABLING siteCsoSpsCombinedComponent, siteCsoCombinedSpillageEventsComponent and subCsoSpsCombined
  //combinedChartsRendered: boolean = false;
  combinedChartsRendered: boolean = true;

  subVisDataPred: Subscription;
  subVisDataHists: Subscription[] = [null, null, null, null, null, null];
  //subCsoSpsCombined: Subscription;

  subjVisDataHists: AsyncSubject<VisualisationData>[] = [null, null, null, null, null, null];

  chart: ChartCSO;
  dataVisChart: ChartCSO;

  siteMap: SiteMap;

  showDateRange: boolean;
  dateRangeLoading: boolean;
  lastIdx: number;
  startDatesHist: string[] = [];
  endDatesHist: string[] = [];
  selectedStartDate: Date;
  selectedEndDate: Date;

  activatedWithCustomDataVis = false;

  yZoomMin: Number = 0;
  yZoomMax: Number = 110;


  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private cognitoService: CognitoService,
              private loadService: LoadService,
              private runtimeConfig: RuntimeConfig) {

    this.dataVisBtnText = 'Last Week';
    this.spsSelectBtnText = '';
    this.lastIdx = 0;
    this.showDateRange = false;
    this.dateRangeLoading = false;

    this.dataVisChart = new ChartCSO();

    if (this.route.snapshot['queryParams']['start'] && this.route.snapshot['queryParams']['end']) {
      this.activatedWithCustomDataVis = true;
    }
  }


  ngOnInit() {

    this.siteName = this.route.snapshot.paramMap.get('name');

    this.site = Sites.getEmptySite();

    this.apiService.getSite(this.siteName);

    this.apiService.siteSubject.subscribe((site: Site) => { 
      this.site = site;
      this.siteMap = new SiteMap(this.loadService, this.runtimeConfig, site, true);

      if (site.assocSPSs.length > 0) {
        this.assocSPSs = site.assocSPSs;
        this.spsSelectBtnText = this.assocSPSs[0];
        //this.siteCsoSpsCombinedComponent.setAssetNames(site.name, this.assocSPSs[0]);
        this.initiateCombinedRetrieval();
      }
      else {
        this.combinedChartsRendered = true;
      }
    });

    this.cognitoService.refreshIdTokenStart();
  }


  ngAfterViewInit() {

    // IMPORTANT ! - not allowed to change angular component attributes in here
    // this chart stuff is okay as it is direct dom manipulation independent of angular

    document.body.style.cursor = 'wait'; 

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    // let startDatePred = moment().subtract(3, 'month').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    // let endDatePred = moment().subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    // let startDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    // let endDatePred = moment('2019-06-19 09:00:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');   

    //let startDatePred = moment().subtract(6, 'hour').format('DD/MM/YYYY HH:mm');
    let startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('DD/MM/YYYY HH:mm'); // !!!! TEMP FOR WW !!!!
    let endDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');   
    
    this.subVisDataPred = this.apiService.getVisualisationData(this.siteName, startDatePred, endDatePred, true)
      .subscribe((visData: VisualisationData) => {

        visData.currentDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');;
        //visData.startDate = moment().subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
        visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(15, 'minute').format('YYYY-MM-DD HH:mm:ss'); // !!!! TEMP FOR WW !!!!
        visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
            
        this.chart = new ChartCSO();
        this.chart.createChart(320, 'predictionCont', 'Prediction', visData);
    });

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    // let startDateHist = moment().subtract(2, 'year').subtract(3, 'month').format('DD/MM/YYYY HH:mm');;
    // let endDateHist = moment().subtract(2, 'year').format('DD/MM/YYYY HH:mm');
    // let startDateHist = moment().subtract(3, 'month').subtract(3, 'month').format('DD/MM/YYYY HH:mm');;
    // let endDateHist = moment().subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    let startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    let endDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    //let startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(1, 'week').format('DD/MM/YYYY HH:mm');
    //let endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
      
    this.subjVisDataHists[0] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    if (!this.activatedWithCustomDataVis) {
      this.subVisDataHists[0] = this.subjVisDataHists[0].subscribe((visData: VisualisationData) => { 
        this.dataVisChart.createChart(420, 'dataVisContainer', 'Historic', visData);
        this.configureSlider();
      });
    }
    else {
      setTimeout(() => {
        let timestampPair = new TimestampPair(new Date(this.route.snapshot['queryParams']['start']),
                                              new Date(this.route.snapshot['queryParams']['end']));
        this.onJumpToVisualisation(timestampPair);
      }, 1);
    } 

    // pre-emptive load last 3 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[1] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load last 6 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[2] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load last 12 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[3] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // pre-emptive load prev 12 months
    startDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(24, 'month').format('DD/MM/YYYY HH:mm');
    endDateHist = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    //startDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(24, 'month').format('DD/MM/YYYY HH:mm');
    //endDateHist = moment('2019-06-30 23:59:59', 'YYYY-MM-DD HH:mm:ss').subtract(12, 'month').format('DD/MM/YYYY HH:mm');
    this.startDatesHist.push(startDateHist);
    this.endDatesHist.push(endDateHist);
    
    this.subjVisDataHists[4] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);

    // let visData = new VisualisationData(this.loadData1TEMP('GLASSHOUSE_SPS-Rainfall-24.csv', 'GLASSHOUSE_SPS-CSO_levels-24.csv'), true); 
    // this.chart = new ChartCSO();
    // this.chart.createChart(320, 'predictionCont', 'Prediction', visData);

    // let visData2 = new VisualisationData(this.loadData1TEMP('GLASSHOUSE_SPS-Rainfall-3mths.csv', 'GLASSHOUSE_SPS-CSO_levels-3mths.csv'), true); 
    // this.dataVisChart = new ChartCSO();
    // this.dataVisChart.createChart(420, 'dataVisContainer', 'Historic', visData2);

    if (this.timer == null) {
      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }


  // loadData1TEMP(fn1: string, fn2: string) : any {

  //   let rainfile = '/TEMP/' + fn1;
  //   let levelsfile = '/TEMP/' + fn2;

  //   let requestRain = new XMLHttpRequest();  
  //   requestRain.open("GET", rainfile, false);   
  //   requestRain.send(null);  

	// 	let requestLevels = new XMLHttpRequest();  
  //   requestLevels.open("GET", levelsfile, false);   
  //   requestLevels.send(null); 

  //   let data:any = {};

  //   data.actuals = {};
  //   data.actuals.retcode = 1;
  //   data.actuals.value = new Array<any>(4);
  //   data.actuals.value[0] = {};
  //   data.actuals.value[1] = {};

  //   data.actuals.value[0].file_type = 'rain';
  //   data.actuals.value[1].file_type = 'oflow';

  //   data.actuals.value[0].csv_data = requestRain.responseText;
  //   data.actuals.value[1].csv_data = requestLevels.responseText;

  //   data.predictions = {};
  //   data.predictions.retcode = 1;
  //   data.predictions.value = new Array<any>(2);
  //   data.predictions.value[0] = {};
  //   data.predictions.value[1] = {};

  //   data.predictions.value[0].ftype = 'rain';
  //   data.predictions.value[1].ftype = 'oflow';

  //   data.predictions.value[0].csv_data = "";
  //   data.predictions.value[1].csv_data = "";

  //   return data;
  // }


  ngOnDestroy() {

    // only call unsubscribe on the subject once on the top level component
    this.apiService.siteSubject.unsubscribe();

    this.subVisDataPred.unsubscribe();
    
    this.unsubscribeVisDataHists();

    //if (this.subCsoSpsCombined) { this.subCsoSpsCombined.unsubscribe(); }

    this.cognitoService.refreshIdTokenStop();

    if (this.siteMap) { this.siteMap.abort(); }

    document.body.style.cursor = 'default';
  }


  unsubscribeVisDataHists() {

    for (let i = 0; i < 6; i++) {
      if (this.subVisDataHists[i]) { this.subVisDataHists[i].unsubscribe(); }
    }

    this.dateRangeLoading = false;
  }


  clearWaitCursorWhenRendered() {      

    if (this.chart          && this.chart.rendered &&
        this.dataVisChart   && this.dataVisChart.rendered &&
        this.combinedChartsRendered) {

      clearInterval(this.timer);
      
      this.timer = null;
      
      this.dateRangeLoading = false;

      document.body.style.cursor = 'default';

      // this is a chrome/c3 bug workaround
      //this.siteCsoSpsCombinedComponent.hideCharts(false);
    }
  }


  dataVisSelect(event: any, idx: number) {

    if (event) { event.preventDefault(); }

    if      (idx == 0)  { this.dataVisBtnText = 'Last Week'; }
    else if (idx == 1)  { this.dataVisBtnText = 'Last 3 Months'; }
    else if (idx == 2)  { this.dataVisBtnText = 'Last 6 Months'; }
    else if (idx == 3)  { this.dataVisBtnText = 'Last 12 Months'; }
    else if (idx == 4)  { this.dataVisBtnText = 'Previous 12 Months'; }
    else if (idx == 5)  { this.dataVisBtnText = 'Custom Date Range'; }
    else    { return; }

    this.unsubscribeVisDataHists();

    // this is a chrome/c3 bug workaround
    //this.siteCsoSpsCombinedComponent.hideCharts(true);

    if (idx < CUSTOM_DATE_IDX) {

      this.showDateRange = false;

      this.lastIdx = idx;

      this.populateNewVisDataHist(idx);
    }
    else {

      this.showDateRange = true;

      // delay to allow angular to create the elements for the datepickers
      setTimeout(() => { this.configureDatePickers(this.lastIdx); }, 1);
    }
  }


  configureDatePickers(lastIdx: number, timestampPair?: TimestampPair) {  

    let callback = (function(fromDate, toDate) {
      this.selectedStartDate = fromDate;
      this.selectedEndDate = toDate;
    }).bind(this);

    JqUtils.datePickerSetup("#vis-data-date-from", "#vis-data-date-to", callback);

    if (lastIdx == LAST_IDX_SPILLAGE_TABLE) {
      $("#vis-data-date-from").datepicker("setDate", JqUtils.getDateFromDDMMYY(timestampPair.start));
      $("#vis-data-date-to").datepicker("setDate", JqUtils.getDateFromDDMMYY(timestampPair.end));
    }
    else {
      $("#vis-data-date-from").datepicker("setDate", JqUtils.getDateFromDDMMYY(this.startDatesHist[lastIdx]));
      $("#vis-data-date-to").datepicker("setDate", JqUtils.getDateFromDDMMYY(this.endDatesHist[lastIdx]));
    }
  }


  configureSlider() {

    let _this = this;

    this.yZoomMin = this.dataVisChart.yAxisMin;
    this.yZoomMax = this.dataVisChart.yAxisMax;

    $("#vis-data-scale").slider({
      range: true,
      min: this.yZoomMin,
      max: this.yZoomMax,
      values: [ this.yZoomMin, this.yZoomMax ],
      step: 1,
      slide: function(event, ui) {
        if (ui.values[0] >= ui.values[1]) {
          return false;
        }
        else {
          _this.yZoomMin = ui.values[0];
          _this.yZoomMax = ui.values[1];
          _this.dataVisChart.setYAxisZoom(_this.yZoomMin, _this.yZoomMax);
        }
      },
    });
  }


  loadCustomData(event: any) {

    let dateFrom = $("#vis-data-date-from").datepicker("getDate");
    let dateTo = $("#vis-data-date-to").datepicker("getDate");

    if (!dateFrom || !dateTo) {
      alert("You must specify a Start and End date !");
      return;
    }

    let maxDateTo = moment(dateFrom).add(1, 'year').toDate();

    if (dateTo.getTime() > maxDateTo.getTime()) {
      alert("You cannot specify a custom date range greater than 1 year !");
      return;
    }

    this.dateRangeLoading = true;

    this.populateNewVisDataHist(CUSTOM_DATE_IDX);
  }


  populateNewVisDataHist(idx: number) {  

    if (this.dataVisChart.c3Chart) {
      this.dataVisChart.c3Chart.destroy();
      this.dataVisChart.rendered = false;
    }

    if (idx == CUSTOM_DATE_IDX) {

      let dateFrom = $("#vis-data-date-from").datepicker("getDate");
      let dateTo = $("#vis-data-date-to").datepicker("getDate");

      let startDateHist = moment(dateFrom).format('DD/MM/YYYY HH:mm');
      let endDateHist = moment(dateTo).add(1, 'day').format('DD/MM/YYYY HH:mm');

      this.subjVisDataHists[idx] = this.apiService.getVisualisationData(this.siteName, startDateHist, endDateHist, false);
    }

    this.subVisDataHists[idx] = this.subjVisDataHists[idx].subscribe((visData: VisualisationData) => {
      // a significant delay is required here for the UI to sort itself out before the chart starts rendering
      setTimeout(() => {
        this.dataVisChart.createChart(420, 'dataVisContainer', 'Historic', visData);

        this.yZoomMin = this.dataVisChart.yAxisMin;
        this.yZoomMax = this.dataVisChart.yAxisMax;
        $("#vis-data-scale").slider("option", {min: this.yZoomMin, max: this.yZoomMax});
        $("#vis-data-scale").slider("values", [this.yZoomMin, this.yZoomMax]);
      }, 100);
    });

    document.body.style.cursor = 'wait';

    $("#dataVisContainer").text("Loading, please wait ...");

    if (this.timer == null) {
      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }


  assocSpsSelect(event: any, sps: string) {

    if (event) { event.preventDefault(); }

    document.body.style.cursor = 'wait';

    this.spsSelectBtnText = sps;

    //this.siteCsoSpsCombinedComponent.setAssetNames(this.siteName, sps);

    this.initiateCombinedRetrieval();

    this.combinedChartsRendered = false;

    if (this.timer == null) {
      this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
    }
  }


  initiateCombinedRetrieval() {

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 MONTHS TO GET SOME DATA
    let startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(2, 'month').subtract(12, 'month').format('DD/MM/YYYY HH:mm');;
    let endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(2, 'month').format('DD/MM/YYYY HH:mm');

    // this.subCsoSpsCombined = this.apiService.getCsoSpsCombined(this.siteName, this.spsSelectBtnText, startDate, endDate)
    //   .subscribe((csoSpsCombined: CsoSpsCombined) => { 
    //     this.siteCsoSpsCombinedComponent.setSpsCombined(csoSpsCombined);
    //     this.siteCsoCombinedSpillageEventsComponent.setSpsCombined(csoSpsCombined);
    // });
  }


  onCombinedChartsRenderingComplete() {
      this.combinedChartsRendered = true;
  }


  onJumpToVisualisation(timestampPair: TimestampPair) {

    this.dataVisBtnText = 'Custom Date Range';
    this.showDateRange = true;

    setTimeout(() => { 
      this.configureDatePickers(LAST_IDX_SPILLAGE_TABLE, timestampPair);
    }, 1);

    // delay to allow angular to create the elements for the datepickers
    setTimeout(() => { 

      $("#vis-data-date-from").datepicker("setDate", timestampPair.start);
      $("#vis-data-date-to").datepicker("setDate", timestampPair.end);
      this.loadCustomData(null);
      this.router.navigate(['./'], { fragment: 'dataVisHeader', relativeTo: this.route })
    }, 1);

  }

}
