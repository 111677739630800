import { } from 'googlemaps';

export class USGSOverlay {

    bounds_: google.maps.LatLngBounds;
    image_: string;
    map_: google.maps.Map;

    overlay: any;

    constructor(bounds: google.maps.LatLngBounds, image: string, map: google.maps.Map) {
        this.bounds_ = bounds;
        this.image_ = image;
        this.map_ = map;

        this.initOverlay();
    }

    setMap(map) {
        this.map_ = map;
        this.overlay.setMap(this.map_);
    }

    show() {
        this.overlay.show();
    }

    hide() {
        this.overlay.hide();
    }

    initOverlay() {

        this.overlay = new class extends google.maps.OverlayView {

            bounds_: google.maps.LatLngBounds;
            image_: string;
            map_: google.maps.Map;

            div_: any;

            constructor(bounds: google.maps.LatLngBounds, image: string, map: google.maps.Map) {
                super();

                this.bounds_ = bounds;
                this.image_ = image;
                this.map_ = map;

                this.div_ = null;

                this.setMap(map);
            }


            /*********************
             onAdd
            *********************/
            onAdd() {
                //console.log('onAdd');
                //console.trace();
                let div = document.createElement('div');
                div.style.borderStyle = 'none';
                div.style.borderWidth = '0px';
                div.style.position = 'absolute';
            
                // Create the img element and attach it to the div.
                let img = document.createElement('img');
                img.src = this.image_;
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.position = 'absolute';
                img.style.opacity = '0.6';
                div.appendChild(img);
            
                this.div_ = div;
            
                let panes = this.getPanes();
                panes.overlayLayer.appendChild(div);
            };
            
            
            /*********************
                draw
            *********************/
            draw() {
                // We use the south-west and north-east
                // coordinates of the overlay to peg it to the correct position and size.
                // To do this, we need to retrieve the projection from the overlay.
                let overlayProjection = this.getProjection();
            
                // Retrieve the south-west and north-east coordinates of this overlay
                // in LatLngs and convert them to pixel coordinates.
                // We'll use these coordinates to resize the div.
                let sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
                let ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());
            
                // Resize the image's div to fit the indicated dimensions.
                let div = this.div_;
                div.style.left = sw.x + 'px';
                div.style.top = ne.y + 'px';
                div.style.width = (ne.x - sw.x) + 'px';
                div.style.height = (sw.y - ne.y) + 'px';
            };
            
            
            /*********************
                onRemove
            *********************/
            onRemove() {
                this.div_.parentNode.removeChild(this.div_);
                this.div_ = null;
            };
            
            
            /*********************
                hide
            *********************/
            hide() {
                if (this.div_) {
                    // The visibility property must be a string enclosed in quotes.
                    this.div_.style.visibility = 'hidden';
                }
            };
            
            
            /*********************
                show
            *********************/
            show() {
                if (this.div_) {
                    this.div_.style.visibility = 'visible';
                }
            };
            
            
            /*********************
                toggle
            *********************/
            toggle() {
                if (this.div_) {
                    if (this.div_.style.visibility === 'hidden') {
                        this.show();
                    } else {
                        this.hide();
                    }
                }
            };
            
            
            /*********************
                toggleDOM
            *********************/
            toggleDOM() {
                if (this.getMap()) {
                    // Note: setMap(null) calls OverlayView.onRemove()
                    this.setMap(null);
                } else {
                    this.setMap(this.map_);
                }
            };

        }(this.bounds_, this.image_, this.map_);
    }

}