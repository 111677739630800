import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RuntimeConfig {

  constructor() { }


  parse(data: any) : boolean {

    if (data) {

      if (!data.s3_bucket_is_valid) {
        return false;
      }

      if (data.flood_alerts_config &&
          data.flood_alerts_config.centre_latitude && 
          data.flood_alerts_config.centre_longitude &&
          data.flood_alerts_config.radius) {

        sessionStorage.setItem('faLatitude', data.flood_alerts_config.centre_latitude.toString());
        sessionStorage.setItem('faLongitude', data.flood_alerts_config.centre_longitude.toString());
        sessionStorage.setItem('faRadius', data.flood_alerts_config.radius.toString());
      }

      if (data.google_maps_api_key) {

        sessionStorage.setItem('gmApiKey', data.google_maps_api_key);
      }

      if (data.allow_urls) {

        sessionStorage.setItem('allowUrls', JSON.stringify(data.allow_urls));
      }

      if (data.s3_bucket) {

        sessionStorage.setItem('s3Bucket', data.s3_bucket);
      }

      if (data.map_config) {

        sessionStorage.setItem('mapLat', data.map_config.latitude);
        sessionStorage.setItem('mapLon', data.map_config.longitude);
        sessionStorage.setItem('mapZoom', data.map_config.zoom);
      }

      return true;
    }
    else {
      return false;
    }
  }


  getFloodAlertLatitude() : string {

    let latitude = sessionStorage.getItem('faLatitude');
    if (latitude) {
      if (!isNaN(parseFloat(latitude))) {
        return latitude;
      }
    }

    // default central UK latitude 
    return "54.327";
  }


  getFloodAlertLongitude() : string {

    let longtitude = sessionStorage.getItem('faLongitude');
    if (longtitude) {
      if (!isNaN(parseFloat(longtitude))) {
        return longtitude;
      }
    }

    // default central UK longitude 
    return "-2.776";
  }


  getFloodAlertRadius() : string {

    let radius = sessionStorage.getItem('faRadius');
    if (radius) {
      if (!isNaN(parseFloat(radius))) {
        return radius;
      }
    }

    // default full UK radius 
    return "750";
  }


  getGoogleMapsApiKey() : string {

    let gmApiKey = sessionStorage.getItem('gmApiKey');
    if (gmApiKey) {
      return gmApiKey;
    }

    return "";
  }

}