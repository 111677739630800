import { Site } from './site';
import { SpillageAlert } from './spillage-alert';
import { SpillageLevels } from './spillage-levels';
import { BlockageAlert } from './blockage-alert';
import { BlockageLevels } from './blockage-levels';
import { PumpAlert } from './pump-alert';
import { PumpAlertLevels } from './pump-alert-levels';
import { AnomalyAlert } from './anomaly-alert';
import { InflowRateLevels } from './inflow-rate-levels';
import { ChamberInfo } from './chamber-info';


export class Sites {

  static spillageLevels = new SpillageLevels();
  static blockageLevels = new BlockageLevels();
  static pumpAlertLevels = new PumpAlertLevels();
  static inflowRateLevels = new InflowRateLevels();
  
  
  static parse(data: any, singleSite: boolean) : Site[] {

    let arr: Site[] = [];
      
    if (!data || data.retcode == undefined || data.rettext == undefined ||
        data.value == undefined || data.value == null || typeof data.value != 'object') {
      console.error('Sites Retrieval - Invalid response from API Gateway');
      return arr;
    }this

    if (data.retcode !== 1) {
      console.error('Sites Retrieval - Error returned : ' + data.rettext);
      return arr;
    }

    let objs: any[] = [];

    if (singleSite) {
      objs.push(data.value);
    }
    else {
      objs = data.value;
    }
    
    objs.forEach(obj => {

      let id = obj.name.replace(/ /g, '_');
      let address = obj.address.streetname1 + ' ' + obj.address.streetname2 + ' ' + obj.address.city + ' ' + obj.address.postcode;
      let pb = ' ';	
      let link = '';

      // aggregate multiple alerts
      let spillageAlert = new SpillageAlert(this.spillageLevels.getLevelFromText('NONE'), '', '', 0, 0);
      // let numAlerts = obj.alerts.length;
      // if (numAlerts > 0) {

      //   let level = this.spillageLevels.getLevelFromText('NONE').id;
      //   let startTime = obj.alerts[0].StartTime.substring(11, 16);
      //   let endTime = obj.alerts[numAlerts - 1].EndTime.substring(11, 16);
      //   let minutes = 0;
      //   let quantity = 0;
      //   obj.alerts.forEach(alert => {

      //     if (this.spillageLevels.getLevelFromText(alert.Severity).id > level) {
      //       level = this.spillageLevels.getLevelFromText(alert.Severity).id;
      //     }

      //     minutes += Sites.calcDurationMinutes(alert.Duration);
      //     quantity += alert.Quantity;
      //   })

      //   spillageAlert = new SpillageAlert(
      //     this.spillageLevels.getLevelFromId(level), 
      //     startTime + ' - ' + endTime, 
      //     Sites.calcDurationFromMinutes(minutes), 
      //     minutes, 
      //     quantity);
      // }

      let site = new Site(
        id,
        id,
        obj.name,
        obj.type,
        obj.catchment,
        obj.lat,
        obj.lon,
        address,
        spillageAlert,
        // new BlockageAlert(Sites.blockageLevels.getRandom()),
        // new PumpAlert(Sites.pumpAlertLevels.getRandom(), Sites.pumpAlertLevels.getRandom(), 0, 0, 0),
        new BlockageAlert(Sites.blockageLevels.getLevelFromText('NONE'), new Date(), new Date(), '', ''),
        new PumpAlert(Sites.pumpAlertLevels.getLevelFromText('NONE'), Sites.pumpAlertLevels.getLevelFromText('NONE'), 0, 0, 0),
        new AnomalyAlert('', new Date(), new Date(), '', ''),
        [], [], [], [],
        new ChamberInfo(Sites.inflowRateLevels.getLevelFromId(2),
                        8112, 47921, 98110, 193992,
                        Math.PI * obj.wetwelldiameter * obj.wetwelldiameter * 0.25 * obj.wetwellheight,
                        obj.wetwellhighlevel,
                        obj.overflowlevel,
                        obj.pump1level,
                        obj.pump2level,
                        obj.pumpstoplevel,
                        obj.pumpspecs),
        pb,
        link,
        '', // mapUrl
        null,
        obj.assoc_spses,
        obj.assoc_csos);

      site.mapUrl = '/per_site/maps/' + site.id + '.jpg';

      arr.push(site);
    });

    return arr;
  }


  static calcDurationMinutes(duration: string) : number {
    let a = duration.split(':');
    if (a.length !== 2) { return 0; }
    return (parseInt(a[0]) * 60) + parseInt(a[1]); 
  }  


  static buildDurationText(minutes: number) : string {
     let hrs = Math.floor(minutes / 60);
     let mins = minutes - (hrs * 60);
     return hrs.toString().padStart(2, '0') + ':' +  mins.toString().padStart(2, '0');
  }  


  static getEmptySite() : Site {
    return new Site(
      '', '', '', '', '', 0, 0, '',
      new SpillageAlert(Sites.spillageLevels.getLevelFromId(0), '', '', 0, 0),
      new BlockageAlert(Sites.blockageLevels.getLevelFromId(0), new Date(), new Date(), '', ''),
      new PumpAlert(Sites.pumpAlertLevels.getLevelFromId(0), Sites.pumpAlertLevels.getLevelFromId(0), 0, 0, 0),
      new AnomalyAlert('', new Date(), new Date(), '', ''),
      [], [], [], [],
      new ChamberInfo(Sites.inflowRateLevels.getLevelFromId(0), 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0),
      '', '', '',
      null,
      [], []
    );
  }


  static buildAlertsSiteArray(sites: Site[], alertType: string) : Site[] {

    let createAlertSite = (site: Site, id2: number) : Site => {
      let alertSite = Sites.getEmptySite();
      alertSite.id = site.id;
      alertSite.id2 = id2.toString();
      alertSite.name = site.name;
      alertSite.facility = site.facility;
      alertSite.catchment = site.catchment;
      alertSite.latitude = site.latitude;
      alertSite.longitude = site.longitude;
      alertSite.address = site.address;
      return alertSite;
    }

    let alertSites : Site[] = [];
    let id2 = 1;

    sites.forEach((site) => {

      if (alertType == 'Spillage') {
        site.spillageAlerts.forEach((spillageAlert) => {
          let alertSite = createAlertSite(site, id2);
          alertSite.spillageAlert = spillageAlert;
          alertSites.push(alertSite);
          id2++;
        });
      }
      else if (alertType == 'Anomaly') {
        site.anomalyAlerts.forEach((anomalyAlert) => {
          let alertSite = createAlertSite(site, id2);
          alertSite.anomalyAlert = anomalyAlert;
          alertSites.push(alertSite);
          id2++;
        });
      }
    });

    return alertSites;
  }
  
}