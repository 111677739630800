import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-alerts-status-bar',
  templateUrl: './alerts-status-bar.component.html',
  styleUrls: ['./alerts-status-bar.component.scss']
})
export class AlertsStatusBarComponent implements OnInit {

  @Input() statusType: string;

  constructor() { }

  ngOnInit() {
  }

}
