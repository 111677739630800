import { PageEvent } from '@angular/material/paginator';

export class Paginator {

  public pageData: PageEvent;
  private maxPageIndex: number;

  constructor(pageSize: number) {
    this.pageData = new PageEvent();
    this.pageData.previousPageIndex = 0;
    this.pageData.pageIndex = 0;
    this.pageData.pageSize = pageSize;
    this.pageData.length = 0;
    this.maxPageIndex = 0;
  }

  get previousPageIndex(): number { return this.pageData.previousPageIndex; }
  set previousPageIndex(ppi: number) { this.pageData.previousPageIndex = ppi; }

  get pageIndex(): number { return this.pageData.pageIndex; }
  set pageIndex(pi: number) { this.pageData.pageIndex = pi; }

  get pageSize(): number { return this.pageData.pageSize; }
  set pageSize(ps: number) { this.pageData.pageSize = ps; }

  get length(): number { return this.pageData.length; }
  set length(l: number) { this.pageData.length = l; } 

  public adjustForNewMaxIndex(arrayLength: number) {

    this.pageData.length = arrayLength;
    this.maxPageIndex = Math.floor(this.pageData.length / this.pageData.pageSize);
    if (this.pageData.pageIndex > this.maxPageIndex) { this.pageData.pageIndex = this.maxPageIndex; }
  }

  public calcNumDisplayRows(arrLength: number) : number {

    if (this.pageData.pageIndex == this.maxPageIndex) {
      return arrLength - (this.pageData.pageIndex * this.pageData.pageSize);
    }
    else {
      return this.pageData.pageSize;
    }
  }

  public createPageArray(arrFilteredSorted: any[], arrPageDisplay: any[], numDisplayItems: number) {

    let startIdx = this.pageData.pageSize * this.pageData.pageIndex;
    let endIdx =  startIdx + numDisplayItems - 1;

    for (let i = startIdx; i <= endIdx; i++) {
      arrPageDisplay[i - startIdx] = arrFilteredSorted[i];
    }
  }

}