import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ExpandedRowPumpComponent } from '../../shared-components/expanded-row-pump/expanded-row-pump.component';
import { Site } from 'src/app/classes/site';
import { ApiService } from '../../classes/api.service';
import { FilterSortSites } from '../../classes/filter-sort-sites';


@Component({
  selector: 'app-pump-alerts',
  templateUrl: './pump-alerts.component.html',
  styleUrls: ['./pump-alerts.component.scss']
})
export class PumpAlertsComponent implements OnInit {

  @ViewChildren(ExpandedRowPumpComponent) expandedRowsPump: QueryList<ExpandedRowPumpComponent>;

  filterSortSites: FilterSortSites;

  constructor(private apiService: ApiService) {
    this.filterSortSites = new FilterSortSites(20);
  }

  ngOnInit() {

    this.apiService.sitesSubject.subscribe((sites: Site[]) => {
      
      this.filterSortSites.sitesReceived = sites;
      this.filterSortSites.removeNonAffected('pump');
      this.filterSortSites.process();
    }); 
  }

  createChart(site: Site, chartContainerId: string) {
    this.expandedRowsPump.find(erp => erp.site.id == site.id).createChart(site, chartContainerId);
  }

}
