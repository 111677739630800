import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { CognitoService } from './cognito.service';

@Injectable({ providedIn: 'root' })
export class AuthService implements CanActivate {

  /**********************************
  * constructor
  **********************************/
  constructor(private cognitoService: CognitoService,
              private router: Router) {
  }


  /**********************************
  * canActivate
  **********************************/
  canActivate() : boolean {
    
    let awsIdentityToken = sessionStorage.getItem('awsIdentityToken');
    let canActivate = true;

    if (awsIdentityToken == null) {
      canActivate = false;
    }
    else if (awsIdentityToken == '') {
      canActivate = false;
    }
    else if (this.cognitoService.isIdentityTokenExpired(awsIdentityToken)) {
      canActivate = false;
    }

    if (!canActivate) {
      this.router.navigate(['./']);
    }

    return canActivate;
  }

}
