import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../classes/api.service';
import { MessageService, MessageId } from '../../classes/message.service';
import { Site } from '../../classes/site';

@Component({
  selector: 'app-status-alerts',
  templateUrl: './status-alerts.component.html',
  styleUrls: ['./status-alerts.component.scss']
})
export class StatusAlertsComponent implements OnInit {

  @Input() statusType: string;

  totalCount: number = 0;
  highCount: number = 0;
  moderateCount: number = 0;
  lowCount: number = 0;
  spillageAlerts: number = 0;
  pumpAlerts: number = 0;
  anomalyAlerts: number = 0;

  constructor(private apiService: ApiService,
              private messageService: MessageService,
              private route: ActivatedRoute) { }

  ngOnInit() {

    let siteName = this.route.snapshot.paramMap.get('name');

    if (siteName === null) {
      this.processAllSites();
    }
    else {
      this.processOneSite();
    }     
  }


  resetStats() {
    this.totalCount = 0;
    this.highCount = 0;
    this.moderateCount = 0;
    this.lowCount = 0;
    this.spillageAlerts = 0;
    this.pumpAlerts = 0;
    this.anomalyAlerts = 0;
  }


  processOneSite() {

    this.apiService.siteSubject.subscribe((site: Site) => {

      this.processSite(site);
    });
  }


  processAllSites() {
    
    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.resetStats();
      sites.forEach(site => { this.processSite(site); });
    });
  }


  processSite(site: Site) {

    switch (site.blockageAlert.blockageLevel.id) {
      case 1 :  this.lowCount++; break;
      case 2 :  this.moderateCount++; break;
      case 3 :  this.highCount++; break;
    }

    if (site.blockageAlert.blockageLevel.id > 0) {
      this.totalCount++;
    }

    if (site.spillageAlert.spillageLevel.id > 0) {
      this.spillageAlerts++;
    }

    if (site.pumpAlert.pump1AlertLevel.id > 0 ||
        site.pumpAlert.pump2AlertLevel.id > 0) {
      this.pumpAlerts++;
    }

    if (site.anomalyAlert.Description != '') {
      this.anomalyAlerts++;
    }
  }


  filterNone() {
    this.messageService.sendMessage(MessageId.FILTER_BLOCKAGES_NONE);
  }


  filterHigh() {
    this.messageService.sendMessage(MessageId.FILTER_BLOCKAGES_HIGH);
  }


  filterModerate() {
    this.messageService.sendMessage(MessageId.FILTER_BLOCKAGES_MODERATE);
  }


  filterLow() {
    this.messageService.sendMessage(MessageId.FILTER_BLOCKAGES_LOW);
  }


  navigateToSpillages() {
    this.messageService.sendMessage(MessageId.NAVIGATE_SPILLAGE_PREDICTIONS);
  }


  navigateToPumpAlerts() {
    this.messageService.sendMessage(MessageId.NAVIGATE_PUMP_ALERTS);
  }

  navigateToAnomalyAlerts() {
    this.messageService.sendMessage(MessageId.NAVIGATE_ANOMALY_ALERTS);
  }

}
