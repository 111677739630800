import { ChartAPI, generate } from 'c3';
import { CsvUtil } from './csv-util';

import * as moment from 'moment';

export class ChartWWTW {
  
  c3Chart: ChartAPI = null;

  //chartData: any = null;
  chart1Data: any = null;
  chart2Data: any = null;

  chartdataPredictedLevels: any = null;
  chartdataHiPredThresh: any = null;
  chartdataLoPredThresh: any = null;

  rendered: boolean;

  maxRainfall:number = 0;

  yAxisMin:number = 0;
  yAxisMax:number = 0;

  SCALE_LEVELS = 0.1;

  constructor() { }

  //public createChart(height: number, fileName: String, chartContainerId: string)
  public createChart(height: number, flowFileName: String, rainFileName: String, chartContainerId: string)
  {
    this.rendered = false;

    this.yAxisMin = 0;
    this.yAxisMax = 110;

    this.yAxisMax *= this.SCALE_LEVELS;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    let chartType = 'Hist6Mths+';
    if (flowFileName.includes('3_mths')) {
      chartType = 'Hist3Mths';
    }
    else if (flowFileName.includes('6_hrs')) {
      chartType = 'Pred6Hrs';
    }

    //let pathData = '/TEMP/' + fileName;
    let requests = [CsvUtil.makeHttpRequest('/TEMP/' + flowFileName),
                    CsvUtil.makeHttpRequest('/TEMP/' + rainFileName),
                    CsvUtil.makeHttpRequest('/TEMP/WWTW_Dummy_Preds_ALIGNED.csv'),
                    CsvUtil.makeHttpRequest('/TEMP/WWTW_Dummy_Preds_Hist_ALIGNED.csv')];

    //Promise.all([CsvUtil.makeHttpRequest(pathData)])
    Promise.all(requests)
      .then((data: string[]) => {
        if (data.length != 4) {
          return;
        }
        else {
          this.createChart2(height, chartContainerId, data, chartType);
        }
      })
      .catch(err => console.error('Inflow chart data request returned error ' + err));
  }

  //private createChart2(height: number, chartContainerId: string, data: any)
  private createChart2(height: number, chartContainerId: string, data: any[], chartType: string)
  {
    //this.chartData = CsvUtil.getCSV3Data(data, 1, 2, 3, 'Date/Time','Rain (mm)','Flow Rate (m3/s)');
    
    this.chart1Data = CsvUtil.getCSVData(data[0], 'x1','Flow Rate (m3/s)');
    this.chart2Data = CsvUtil.getCSVData(data[1], 'x2','Rain (mm)');

    // calc max rainfall
    this.maxRainfall = 5;
    for (let i = 1; i < this.chart2Data.ydata.length; ++i) {
      if (this.chart2Data.ydata[i] > this.maxRainfall) { this.maxRainfall = this.chart2Data.ydata[i]; }
    }
    this.maxRainfall = Math.ceil(this.maxRainfall);

    // expand y axis to accommodate SLM spikes above 120 or anomalous negatives
    for (let i = 1; i < this.chart1Data.ydata.length; ++i) {
      this.chart1Data.ydata[i] *= this.SCALE_LEVELS;
      this.chart1Data.ydata[i] = this.chart1Data.ydata[i].toFixed(2);
      if (parseFloat(this.chart1Data.ydata[i]) > this.yAxisMax) {
        this.yAxisMax = parseFloat(this.chart1Data.ydata[i]); 
      }
      if (parseFloat(this.chart1Data.ydata[i]) < this.yAxisMin) {
        this.yAxisMin = parseFloat(this.chart1Data.ydata[i]); 
      }
    }

    this.adjustToTimezone(this.chart1Data);
    this.adjustToTimezone(this.chart2Data);

    this.chartdataPredictedLevels = CsvUtil.getCSVData(data[2], 'xPredLevels', 'Predicted Flow Rate (m3/s)');
    this.adjustToTimezone(this.chartdataPredictedLevels);
    
    this.chartdataHiPredThresh = CsvUtil.getLevelPredData(data[2], 'xHiPredThresh', 'Hi Pred Thresholds'); 
    this.chartdataLoPredThresh = CsvUtil.getLevelPredData(data[2], 'xLoPredThresh', 'Low Pred Thresholds');
        
    if (chartType == 'Hist3Mths') {
      if (this.chartdataHiPredThresh) {
        this.chartdataHiPredThresh = CsvUtil.addLevelPredData(this.chartdataHiPredThresh, data[3], 'Hi Pred Thresholds');
      }
      else {
        this.chartdataHiPredThresh = CsvUtil.getLevelPredData(data[3], 'xHiPredThresh', 'Hi Pred Thresholds');
      }
      if (this.chartdataLoPredThresh) {
        this.chartdataLoPredThresh = CsvUtil.addLevelPredData(this.chartdataLoPredThresh, data[3], 'Low Pred Thresholds');
      }
      else {
        this.chartdataLoPredThresh = CsvUtil.getLevelPredData(data[3], 'xLoPredThresh', 'Low Pred Thresholds');
      }
    }

    if (this.chartdataHiPredThresh) { this.adjustToTimezone(this.chartdataHiPredThresh); }
    if (this.chartdataLoPredThresh) { this.adjustToTimezone(this.chartdataLoPredThresh); }

    for (let i = 1; i < this.chartdataPredictedLevels.ydata.length; ++i) {
      this.chartdataPredictedLevels.ydata[i] *= this.SCALE_LEVELS;
      this.chartdataPredictedLevels.ydata[i] = this.chartdataPredictedLevels.ydata[i].toFixed(2);
    }
    for (let i = 1; i < this.chartdataHiPredThresh.ydata.length; ++i) {
      this.chartdataHiPredThresh.ydata[i] *= this.SCALE_LEVELS;
      this.chartdataHiPredThresh.ydata[i] = this.chartdataHiPredThresh.ydata[i].toFixed(2);
    }
    for (let i = 1; i < this.chartdataLoPredThresh.ydata.length; ++i) {
      this.chartdataLoPredThresh.ydata[i] *= this.SCALE_LEVELS;
      this.chartdataLoPredThresh.ydata[i] = this.chartdataLoPredThresh.ydata[i].toFixed(2);
    }
    
    // let xdata = this.chartData.xdata;
		// let y1data = this.chartData.y1data;
    // let y2data = this.chartData.y2data;
    
    let xs = {};
    let columns = [];
    let pattern = [];

    if (chartType != 'Pred6Hrs') {
      if (this.chart1Data.xdata.length >= 3) {
        xs['Flow Rate (m3/s)'] = 'x1';
        columns.push(this.chart1Data.xdata);
        columns.push(this.chart1Data.ydata);
        pattern.push('#A0522D');
      }
    }

    if (this.chart2Data.xdata.length >= 2) { 
      xs['Rain (mm)'] = 'x2';
      columns.push(this.chart2Data.xdata);
      columns.push(this.chart2Data.ydata);
      pattern.push('#119ade');
    }

    if (this.chartdataPredictedLevels && this.chartdataPredictedLevels.xdata.length >= 2) { 
      xs['Predicted Flow Rate (m3/s)'] = 'xPredLevels';
      columns.push(this.chartdataPredictedLevels.xdata);
      columns.push(this.chartdataPredictedLevels.ydata);
      pattern.push('#228844');
    }

    if (this.chartdataHiPredThresh && this.chartdataHiPredThresh.xdata.length >= 2) { 
      xs['Hi Pred Thresholds'] = 'xHiPredThresh';
      columns.push(this.chartdataHiPredThresh.xdata);
      columns.push(this.chartdataHiPredThresh.ydata);
      pattern.push('#aaaaaa');
    }

    if (this.chartdataLoPredThresh && this.chartdataLoPredThresh.xdata.length >= 2) { 
      xs['Low Pred Thresholds'] = 'xLoPredThresh';
      columns.push(this.chartdataLoPredThresh.xdata);
      columns.push(this.chartdataLoPredThresh.ydata);
      pattern.push('#aaaaaa');
    }

    let regions = [];
    let grid = {};
    let subchartConfig = { show: true };
    if (chartType == 'Pred6Hrs') {
      
      subchartConfig.show = false;  

      let grid0 = '2020-07-25 13:00';
      let grid1 = CsvUtil.getGridTime(grid0, 1, 'hour');
      let grid2 = CsvUtil.getGridTime(grid0, 2, 'hour');
      let grid3 = CsvUtil.getGridTime(grid0, 3, 'hour');
      let grid4 = CsvUtil.getGridTime(grid0, 4, 'hour');
      let grid5 = CsvUtil.getGridTime(grid0, 5, 'hour');
      let grid6 = CsvUtil.getGridTime(grid0, 6, 'hour');

      regions.push({axis: 'x', start: grid0, end: grid6, class: 'region-predicted'});
      grid['x'] = {
        lines: [
          {value: grid0, text: 'Now'},
          {value: grid1, text: '+1hr'},
          {value: grid2, text: '+2hrs'},
          {value: grid3, text: '+3hrs'},
          {value: grid4, text: '+4hrs'},
          {value: grid5, text: '+5hrs'},
          {value: grid6, text: '+6hrs'}
        ]
      }; 
    }

    // initially hide predictions in non-6hrs chart
    let hide = undefined;
    if (chartType != 'Pred6Hrs') {
      if (this.chartdataPredictedLevels && this.chartdataPredictedLevels.xdata.length >= 2) {
        hide = ['Predicted Flow Rate (m3/s)'];
      }
    }

    this.c3Chart = generate({
      bindto: '#' + chartContainerId,
      size: { height: height },
      data: { 
        //x: 'Date/Time',
        //xFormat: '%d/%m/%Y %H:%M',
        xFormat: '%Y-%m-%d %H:%M:%S',
        xs: xs,
        //columns: [ xdata, y1data, y2data ],
        columns: columns,
        //axes: { 'Rain (mm)': 'y', 'Flow Rate (m3/s)': 'y2' }
        axes: { 'Flow Rate (m3/s)': 'y', 'Rain (mm)': 'y2' },		
        hide: hide      
      },
      //color: { pattern: [ '#119ade','#6cd073','#1200ff' ] },
      color: { pattern: pattern },		    		    
      legend: { show: true },    
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          label: {
            text: 'Time/Duration',
            position: 'outer-center'
          },		            
          tick: {
            fit: false,
            rotate: 7,
            count: 6,
            format: '%d/%m/%Y %H:%M'
          }
        },
        y: {		            
          label: {
            text: 'Flow Rate (m3/s)',
            position: 'outer-middle'
          },
          show: true,
          min: this.yAxisMin,
          max: this.yAxisMax
        },
        y2: {
          label: {
            text: 'Rainfall (mm)',
            position: 'outer-middle'
          },
          show: true,
          min: 0,
          max: this.maxRainfall
        }
      },
      point: { show: false },
      zoom: { enabled: true, rescale: true },
      onrendered: () => { this.rendered = true; },		
      subchart: subchartConfig,
      regions: regions,
      grid: grid
    });

  }

  private adjustToTimezone(chartdata: any) {

    for (let i = 1; i < chartdata.xdata.length; i++) { 
      let momentLocalTime = moment.utc(chartdata.xdata[i], 'YYYY-MM-DD HH:mm:ss').local();
      chartdata.xdata[i] = momentLocalTime.format('YYYY-MM-DD HH:mm:ss');
    }
  }

}


