import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Site } from '../../classes/site';
import { ChartSPS } from '../../classes/chart-sps';
import { VisualisationData } from '../../classes/visualisation-data';
import { ApiService } from '../../classes/api.service';

import * as moment from 'moment';

@Component({
  selector: 'app-expanded-row-pump',
  templateUrl: './expanded-row-pump.component.html',
  styleUrls: ['./expanded-row-pump.component.scss']
})
export class ExpandedRowPumpComponent implements OnInit {

  @Input() site: Site;
  @Input() chartContainerId: string;

  chart: ChartSPS;
  timer: any;

  subVisDataPred: Subscription;


  constructor(private apiService: ApiService) { }


  ngOnInit() {
  }

  
  ngOnDestroy() {

    if (this.subVisDataPred) { this.subVisDataPred.unsubscribe() };
  }


  createChart(site: Site, chartContainerId: string) {

    document.body.style.cursor = 'wait';

    // ######## !!!!!!!! TEMPORARY SUBTRACT 2 YEARS TO GET SOME DATA
    // let startDatePred = moment().subtract(2, 'year').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');;
    // let endDatePred = moment().subtract(2, 'year').format('DD/MM/YYYY HH:mm');
    let startDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').subtract(6, 'hour').format('DD/MM/YYYY HH:mm');;
    let endDatePred = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(3, 'month').format('DD/MM/YYYY HH:mm');

    this.subVisDataPred = this.apiService.getVisualisationData(site.name, startDatePred, endDatePred, true)
      .subscribe((visData: VisualisationData) => { 

        visData.currentDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');;
        visData.startDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').subtract(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
        visData.endDate = moment('2020-07-25 12:55:00', 'YYYY-MM-DD HH:mm:ss').add(6, 'hour').format('YYYY-MM-DD HH:mm:ss');
            
        this.chart = new ChartSPS();
        this.chart.createChart(200, chartContainerId + site.id, 'Prediction', visData);
    });

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }  


  clearWaitCursorWhenRendered() {

    if (this.chart && this.chart.rendered) {

      clearInterval(this.timer);
      document.body.style.cursor = 'default';
    }
  }

}
