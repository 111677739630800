import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';

import { Site } from '../classes/site';
import { Sites } from '../classes/sites';
import { SiteMap } from '../classes/site-map';
import { ApiService } from '../classes/api.service';
import { CognitoService } from '../classes/cognito.service';
import { LoadService } from '../classes/load.service';
import { ChartWWTW } from '../classes/chart-wwtw';
import { RuntimeConfig } from '../classes/runtime-config';

import * as moment from 'moment';

@Component({
  selector: 'app-site-wwtw',
  templateUrl: './site-wwtw.component.html',
  styleUrls: ['./site-wwtw.component.scss']
})
export class SiteWwtwComponent implements OnInit {

  site: Site;
  siteName: string;
  timer: any;
  dataVisBtnText: string;

  //subVisDataPred: Subscription;
  //subVisDataHist: Subscription;

  chart: ChartWWTW;
  dataVisChart: ChartWWTW;

  siteMap: SiteMap;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apiService: ApiService,
              private cognitoService: CognitoService,
              private loadService: LoadService,
              private runtimeConfig: RuntimeConfig) {

    this.dataVisBtnText = 'Last 3 Months';
  }


  ngOnInit() {

    this.siteName = this.route.snapshot.paramMap.get('name');

    this.site = Sites.getEmptySite();

    this.apiService.getSite(this.siteName);

    this.apiService.siteSubject.subscribe((site: Site) => { 
      this.site = site;
      this.siteMap = new SiteMap(this.loadService, this.runtimeConfig, site, true);
    });

    this.cognitoService.refreshIdTokenStart();
  }


  ngAfterViewInit() {

    // IMPORTANT ! - not allowed to change angular component attributes in here
    // this chart stuff is okay as it is direct dom manipulation independent of angular

    document.body.style.cursor = 'wait';

    this.chart = new ChartWWTW();
    //this.chart.createChart(320, 'HOWDON_WWTW_Flow_Rates-12hrs.csv', 'predictionCont');
    this.chart.createChart(320, 'WWTW_Dummy_Data_6_hrs_ALIGNED.csv', 'WWTW_Dummy_Rain_Data_6_hrs_ALIGNED.csv', 'predictionCont');

    this.dataVisChart = new ChartWWTW();
    //this.dataVisChart.createChart(420, 'HOWDON_WWTW_Flow_Rates-3mth.csv', 'dataVisContainer');
    this.dataVisChart.createChart(420, 'WWTW_Dummy_Data_3_mths_ALIGNED.csv', 'WWTW_Dummy_Rain_Data_3_mths_ALIGNED.csv', 'dataVisContainer');

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }


  ngOnDestroy() {

    // only call unsubscribe on the subject once on the top level component
    this.apiService.siteSubject.unsubscribe();

    this.cognitoService.refreshIdTokenStop();
    
    if (this.siteMap) { this.siteMap.abort(); }

    document.body.style.cursor = 'default';
  }


  clearWaitCursorWhenRendered() {      

    if (this.chart          && this.chart.rendered &&
        this.dataVisChart   && this.dataVisChart.rendered) {

      clearInterval(this.timer);
      document.body.style.cursor = 'default';
    }
  }


  dataVisSelect(event: any, month: number) {

    if (event) { event.preventDefault(); }

    document.body.style.cursor = 'wait';

    this.dataVisBtnText = 'Last ' + month + ' Months';

    this.dataVisChart.c3Chart.destroy();
    this.dataVisChart.rendered = false;

    let flowFilename = '';
    let rainFilename = '';
    
    if (month == 3) {
      // filename = 'HOWDON_WWTW_Flow_Rates-3mth.csv';
      flowFilename = 'WWTW_Dummy_Data_3_mths_ALIGNED.csv';
      rainFilename = 'WWTW_Dummy_Rain_Data_3_mths_ALIGNED.csv';
    }
    else if (month == 6) {
      // filename = 'HOWDON_WWTW_Flow_Rates-6mth.csv';
      flowFilename = 'WWTW_Dummy_Data_6_mths_ALIGNED.csv';
      rainFilename = 'WWTW_Dummy_Rain_Data_6_mths_ALIGNED.csv';

    }
    else if (month == 12) {
      // filename = 'HOWDON_WWTW_Flow_Rates-yr.csv';
      flowFilename = 'WWTW_Dummy_Data_12_mths_ALIGNED.csv';
      rainFilename = 'WWTW_Dummy_Rain_Data_12_mths_ALIGNED.csv';
    }

    this.dataVisChart = new ChartWWTW();
    this.dataVisChart.createChart(420, flowFilename, rainFilename, 'dataVisContainer');

    this.timer = setInterval(() => { this.clearWaitCursorWhenRendered(); }, 100);
  }

}
