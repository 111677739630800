import { PageEvent } from '@angular/material/paginator';
import { SpillageEvent } from './spillage-event';
import { Checkbox } from './checkbox';
import { Paginator } from './paginator';

export class FilterSortSpillageEvents {

  public spillsReceived: SpillageEvent[];
  public spillsFilteredSorted: SpillageEvent[] = [];
  public spillsPageDisplay: SpillageEvent[] = [];

  public severityCheckboxes: Checkbox[] = [];

  public minLitres: number;
  public maxLitres: number;
  public minDuration: number;
  public maxDuration: number;
  public minRainfall: number;
  public maxRainfall: number;

  public minTimestamp: Date;
  public maxTimestamp: Date; 

  public rainfallNo: boolean;
  public rainfallYes: boolean;
  public pumpfailNo: boolean;
  public pumpfailYes: boolean;
  public blockageNo: boolean;
  public blockageYes: boolean;

  public stationText: string = '';
  public stationTextUpper: string = '';

  public sortType: string = 'Start';
  public sortOrder: string = 'Desc';

  private cmpFunc: (s:SpillageEvent, c:Checkbox)=>boolean = (s, c) => c.text === s.spillageLevel.text;
  private txtFunc: (s:SpillageEvent)=>string = s => s.spillageLevel.text; 

  public pagn8r: Paginator;

  constructor (pageSize: number) {
    this.pagn8r = new Paginator(pageSize);
   }

  public initialise(): void {

    this.severityCheckboxes = [];

    this.minLitres = 0;
    this.maxLitres = 0;
    this.minDuration = 0;
    this.maxDuration = 0;
    this.minRainfall = 0;
    this.maxRainfall = 0;
    
    for (let i = 0; i < this.spillsReceived.length; ++i) {

      let addSeverity = true;
      for (let j = 0; j < this.severityCheckboxes.length; ++j) {
        if (this.cmpFunc(this.spillsReceived[i], this.severityCheckboxes[j])) {
          addSeverity = false;
          break; 
        }
      }
      if (addSeverity) { this.severityCheckboxes.push(new Checkbox(this.txtFunc(this.spillsReceived[i]), false)); }

      if (this.spillsReceived[i].estimatedLitres > this.maxLitres) { this.maxLitres = this.spillsReceived[i].estimatedLitres; }
      if (this.spillsReceived[i].durationHours > this.maxDuration) { this.maxDuration = this.spillsReceived[i].durationHours; }
      if (this.spillsReceived[i].rainfallIntensity > this.maxRainfall) { this.maxRainfall = this.spillsReceived[i].rainfallIntensity; }
    }

    if (this.maxRainfall > Math.floor(this.maxRainfall)) {
      this.maxRainfall = Math.floor(this.maxRainfall) + 1;
    }

    if (this.maxDuration > Math.floor(this.maxDuration)) {
      this.maxDuration = Math.floor(this.maxDuration) + 1;
    }
  }

  public setSortBy(sortType: string, sortOrder: string): void {
    this.sortType = sortType;
    this.sortOrder = sortOrder;
    this.process();
  }

  public setFilterBySeverity(event: any, severityCheckbox: Checkbox): void {
    severityCheckbox.checked = event.srcElement.checked;    
    this.process();
  }

  public setFilterByRainfallNo(event: any): void {
    this.rainfallNo = event.srcElement.checked;    
    this.process();
  }

  public setFilterByRainfallYes(event: any): void {
    this.rainfallYes = event.srcElement.checked;    
    this.process();
  }

  public setFilterByPumpfailNo(event: any): void {
    this.pumpfailNo = event.srcElement.checked;    
    this.process();
  }

  public setFilterByPumpfailYes(event: any): void {
    this.pumpfailYes = event.srcElement.checked;    
    this.process();
  }

  public setFilterByBlockageNo(event: any): void {
    this.blockageNo = event.srcElement.checked;    
    this.process();
  }

  public setFilterByBlockageYes(event: any): void {
    this.blockageYes = event.srcElement.checked;    
    this.process();
  }

  public setFilterByStation(event: any): void {
    this.stationText = event.srcElement.value; 
    this.stationTextUpper = this.stationText.toUpperCase();
    this.process();
  }

  public setFilterByLitres(min: number, max: number): void {
    this.minLitres = min;
    this.maxLitres = max;
  }

  public setFilterByDuration(min: number, max: number): void {
    this.minDuration = min;
    this.maxDuration = max;
  }

  public setFilterByRainfall(min: number, max: number): void {
    this.minRainfall = min;
    this.maxRainfall = max;
  }

  public setFilterByMinTimestamp(timestamp: Date) {
    this.minTimestamp = timestamp;
  }

  public setFilterByMaxTimestamp(timestamp: Date) {
    this.maxTimestamp = timestamp;
  }

  public resetFilters() {
    this.severityCheckboxes.forEach(scb => { scb.checked = false; });
    this.rainfallNo = false;
    this.rainfallYes = false;
    this.pumpfailNo = false;
    this.pumpfailYes = false;
    this.blockageNo = false;
    this.blockageYes = false;
    this.stationText = '';
    this.stationTextUpper = '';
    this.process();
  }

  public process(): void {

    // FILTER

    this.spillsFilteredSorted = [];
    this.spillsReceived.forEach(spill => {
      
      let addSpill = true;

      let severityChecked = false;
      let severityAdd = false;
      for (let i = 0; i < this.severityCheckboxes.length; ++i) {
        if (this.severityCheckboxes[i].checked) { severityChecked = true; }
        if (this.severityCheckboxes[i].checked &&
            this.cmpFunc(spill, this.severityCheckboxes[i]) ) {
          severityAdd = true;
        } 
      }
      if (severityChecked && !severityAdd) { addSpill = false; }

      if (spill.estimatedLitres < this.minLitres || spill.estimatedLitres > this.maxLitres) { addSpill = false; }
      if (spill.durationHours < this.minDuration || spill.durationHours > this.maxDuration) { addSpill = false; }
      if (spill.rainfallIntensity < this.minRainfall || spill.rainfallIntensity > this.maxRainfall) { addSpill = false; }

      if (this.minTimestamp && (spill.startTimestamp < this.minTimestamp)) { addSpill = false; }
      if (this.maxTimestamp && (spill.startTimestamp > this.maxTimestamp)) { addSpill = false; }

      if (this.rainfallNo && !this.rainfallYes && spill.causeRain) { addSpill = false; }
      if (this.rainfallYes && !this.rainfallNo && !spill.causeRain) { addSpill = false; }
      if (this.pumpfailNo && !this.pumpfailYes && spill.causePump) { addSpill = false; }
      if (this.pumpfailYes && !this.pumpfailNo && !spill.causePump) { addSpill = false; }
      if (this.blockageNo && !this.blockageYes && spill.causeBlockage) { addSpill = false; }
      if (this.blockageYes && !this.blockageNo && !spill.causeBlockage) { addSpill = false; }

      if (this.stationText !== '') {
        let siteNameUpper = spill.siteName.toUpperCase();
        if (!siteNameUpper.includes(this.stationTextUpper)) { addSpill = false; }
      }

      if (addSpill) { this.spillsFilteredSorted.push(spill); }
    });
 
    //SORT

    switch (this.sortType) {
      case 'Severity' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.spillageLevel.id - b.spillageLevel.id) :
            this.spillsFilteredSorted.sort((a, b) => b.spillageLevel.id - a.spillageLevel.id);
        break;
      case 'Start' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.startTimestamp.getTime() - b.startTimestamp.getTime()) :
            this.spillsFilteredSorted.sort((a, b) => b.startTimestamp.getTime() - a.startTimestamp.getTime());
        break;
      case 'End' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.endTimestamp.getTime() - b.endTimestamp.getTime()) :
            this.spillsFilteredSorted.sort((a, b) => b.endTimestamp.getTime() - a.endTimestamp.getTime());
        break;
      case 'Duration' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.durationHours - b.durationHours) :
            this.spillsFilteredSorted.sort((a, b) => b.durationHours - a.durationHours);
        break;
      case 'EstimatedLitres' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.estimatedLitres - b.estimatedLitres) :
            this.spillsFilteredSorted.sort((a, b) => b.estimatedLitres - a.estimatedLitres);
        break;
      case 'LitresSaved' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.savedLitres - b.savedLitres) :
            this.spillsFilteredSorted.sort((a, b) => b.savedLitres - a.savedLitres);
        break;
      case 'PercentSaved' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.savedPercentage - b.savedPercentage) :
            this.spillsFilteredSorted.sort((a, b) => b.savedPercentage - a.savedPercentage);
        break;
      case 'DurationSaved' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.savedDuration.localeCompare(b.savedDuration)) :
            this.spillsFilteredSorted.sort((a, b) => b.savedDuration.localeCompare(a.savedDuration));
        break;
      case 'RainfallIntensity' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => a.rainfallIntensity - b.rainfallIntensity) :
            this.spillsFilteredSorted.sort((a, b) => b.rainfallIntensity - a.rainfallIntensity);
        break;
      case 'CauseRain' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => (a.causeRain && !b.causeRain) ? 1 : -1) :
            this.spillsFilteredSorted.sort((a, b) => (!a.causeRain && b.causeRain) ? 1 : -1);
        break;
      case 'CausePump' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => (a.causePump && !b.causePump) ? 1 : -1) :
            this.spillsFilteredSorted.sort((a, b) => (!a.causePump && b.causePump) ? 1 : -1);
        break;
      case 'CauseBlockage' : 
        this.spillsFilteredSorted = this.sortOrder == 'Asc' ?
          this.spillsFilteredSorted.sort((a, b) => (a.causeBlockage && !b.causeBlockage) ? 1 : -1) :
            this.spillsFilteredSorted.sort((a, b) => (!a.causeBlockage && b.causeBlockage) ? 1 : -1);
        break;
    }

    this.pagn8r.adjustForNewMaxIndex(this.spillsFilteredSorted.length);

    this.processPage();
  }

  public onPageUpdate(pageEvent: PageEvent) {

    this.pagn8r.pageIndex = pageEvent.pageIndex;

    this.processPage();
  }

  private processPage() {

    let numDisplayItems = this.pagn8r.calcNumDisplayRows(this.spillsFilteredSorted.length);

    this.spillsPageDisplay = new Array<SpillageEvent>(numDisplayItems);

    this.pagn8r.createPageArray(this.spillsFilteredSorted, this.spillsPageDisplay, numDisplayItems);
  }

}
