import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { SpillagesSummary } from '../../classes/spillages-summary';
import { ApiService } from '../../classes/api.service';
import { FilterSortSpillagesSummaries } from '../../classes/filter-sort-spillages-summaries';
import { Site } from '../../classes/site';

declare var $: any;

@Component({
  selector: 'app-network-spillages-summaries',
  templateUrl: './network-spillages-summaries.component.html',
  styleUrls: ['./network-spillages-summaries.component.scss']
})
export class NetworkSpillagesSummariesComponent implements OnInit {

  filterSortSpillagesSummaries: FilterSortSpillagesSummaries;

  sites: Site[] = [];


  constructor(private changeDetectorRef: ChangeDetectorRef,
              private router: Router) {
    this.filterSortSpillagesSummaries = new FilterSortSpillagesSummaries(20);
  }


  ngOnInit() {
  }


  filterSort(sss: SpillagesSummary[]) {

    this.filterSortSpillagesSummaries.spillsReceived = sss;
    this.filterSortSpillagesSummaries.initialise();
    this.filterSortSpillagesSummaries.process();
    this.initFilterControls();
  }


  initFilterControls() {

    let _this = this;

    $("#range-slider-actual-events").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxActualEvents,
      values: [ 0, this.filterSortSpillagesSummaries.maxActualEvents ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByActualEvents(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-actual-litres").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxActualLitres,
      values: [ 0, this.filterSortSpillagesSummaries.maxActualLitres ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByActualLitres(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-actual-duration").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxActualDuration,
      values: [ 0, this.filterSortSpillagesSummaries.maxActualDuration ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByActualDuration(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-prevent-events").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxPreventEvents,
      values: [ 0, this.filterSortSpillagesSummaries.maxPreventEvents ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByPreventEvents(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-prevent-litres").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxPreventLitres,
      values: [ 0, this.filterSortSpillagesSummaries.maxPreventLitres ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByPreventLitres(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });

    $("#range-slider-prevent-percent").slider({
      range: true,
      min: 0,
      max: this.filterSortSpillagesSummaries.maxPreventPercent,
      values: [ 0, this.filterSortSpillagesSummaries.maxPreventPercent ],
      slide: function(event, ui) { _this.filterSortSpillagesSummaries.setFilterByPreventPercent(ui.values[0], ui.values[1]); },
      // for some reason when mouse up is outside the control, angular change detection does not automatically run
      change: function(event, ui) { _this.filterSortSpillagesSummaries.process(); _this.changeDetectorRef.detectChanges(); }
    });
  }


  resetFilters() {

    $("#range-slider-actual-events").slider("values", [0, $("#range-slider-actual-events").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByActualEvents(0, $("#range-slider-actual-events").slider("option", "max"));

    $("#range-slider-actual-litres").slider("values", [0, $("#range-slider-actual-litres").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByActualLitres(0, $("#range-slider-actual-litres").slider("option", "max"));

    $("#range-slider-actual-duration").slider("values", [0, $("#range-slider-actual-duration").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByActualDuration(0, $("#range-slider-actual-duration").slider("option", "max"));

    $("#range-slider-prevent-events").slider("values", [0, $("#range-slider-prevent-events").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByPreventEvents(0, $("#range-slider-prevent-events").slider("option", "max"));

    $("#range-slider-prevent-litres").slider("values", [0, $("#range-slider-prevent-litres").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByPreventLitres(0, $("#range-slider-prevent-litres").slider("option", "max"));

    $("#range-slider-prevent-percent").slider("values", [0, $("#range-slider-prevent-percent").slider("option", "max")]);
    this.filterSortSpillagesSummaries.setFilterByPreventPercent(0, $("#range-slider-prevent-percent").slider("option", "max"));

    this.filterSortSpillagesSummaries.resetFilters();
  }


  public setSites(sites: Site[]) : void 
  {
    this.sites = sites;
  }


  jumpToSite(spill: SpillagesSummary) {

    let site = this.sites.find(site => site.name == spill.siteName);
    let url = 'site' + site.facility + '/' + site.name;
    this.router.navigate([url])
  }

}

