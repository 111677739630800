import { BlockageLevel } from './blockage-levels';

export class BlockageAlert {
  constructor(
    public blockageLevel: BlockageLevel,
    public startTime: Date,
    public endTime: Date,
    public startTimeDisplay: string,
    public endTimeDisplay: string
  ) { }
}