import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {} from 'googlemaps';
import * as MarkerClusterer from '@google/markerclusterer';
import { MapFilter } from '../../classes/map-filter';
import { USGSOverlay } from '../../classes/usgs-overlay';
import { ApiService } from '../../classes/api.service';
import { LoadService } from '../../classes/load.service';
import { Site } from '../../classes/site';
import { RuntimeConfig } from '../../classes/runtime-config';
import { MessageService, MessageId } from '../../classes/message.service';


/*********************
  Component
*********************/
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  @Input() filtersSetting: string;

  sites: Site[] = [];

  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
  overlay: USGSOverlay; 
  markers: google.maps.Marker[] = [];

  facilityMapFilters: MapFilter[] = [
    { filter: 'F', name: 'SPS',  id: 'SPS',  text: 'SPS',  colourClass: '', checked: false, preMuteState: false },
    { filter: 'F', name: 'WWTW', id: 'WWTW', text: 'WWTW', colourClass: '', checked: false, preMuteState: false },
    { filter: 'F', name: 'CSO',  id: 'CSO',  text: 'CSO',  colourClass: '', checked: false, preMuteState: false },
  ];

  catchmentMapFilters: MapFilter[] = [
  // filled dynamically
  ];

  alertMapFilters: MapFilter[] = [
    { filter: 'A', name: 'Blockage Alert High',      id: 'HIGH',       text: 'Blockage - High',      colourClass: 'red',     checked: false,  preMuteState: false },
    { filter: 'A', name: 'Blockage Alert Moderate',  id: 'MODERATE',   text: 'Blockage - Moderate',  colourClass: 'orange',  checked: false,  preMuteState: false },
    { filter: 'A', name: 'Blockage Alert Low',       id: 'LOW',        text: 'Blockage - Low',       colourClass: 'yellow',  checked: false,  preMuteState: false },
    { filter: 'A', name: 'Blockage Alert None',      id: 'NONE',       text: 'Blockage - None',      colourClass: 'green',   checked: false,  preMuteState: false },
    { filter: 'A', name: 'A',                        id: 'A',          text: 'Anomaly Alert',        colourClass: '',        checked: false,  preMuteState: false },
    { filter: 'A', name: 'P',                        id: 'P',          text: 'Pump Alert',           colourClass: '',        checked: false,  preMuteState: false },
    { filter: 'A', name: 'S',                        id: 'S',          text: 'Spillage Prediction',  colourClass: '',        checked: false,  preMuteState: false }
  ];


  /*********************
    constructor
  *********************/
  constructor(private apiService: ApiService,
              private loadService: LoadService,
              private messageService: MessageService,
              private runtimeConfig: RuntimeConfig,
              private router: Router) {
  }


  /*********************
    ngOnInit
  *********************/
  ngOnInit() {

    if (this.filtersSetting == 'Blockage') {
      this.alertMapFilters[0].checked = true;
      this.alertMapFilters[1].checked = true;
      this.alertMapFilters[2].checked = true;
      this.alertMapFilters[4].checked = true;
      this.alertMapFilters[5].checked = true;
    }
    else if (this.filtersSetting == 'Spillage') {
      this.alertMapFilters[6].checked = true;
    }
    else {
      this.facilityMapFilters[0].checked = true;
      this.facilityMapFilters[1].checked = true;
      this.facilityMapFilters[2].checked = true;
    }

    this.messageService.msgSubject.subscribe((msgId: MessageId) => this.processMessage(msgId));
  }


  /*********************
    ngAfterViewInit
  *********************/
  ngAfterViewInit() {

    if (!this.loadService.scriptLoading) {
      this.loadService.loadScript('https://maps.googleapis.com/maps/api/js?key=' + this.runtimeConfig.getGoogleMapsApiKey());
    }
          
    this.loadService.successSubject.subscribe(success => { if (success) { this.initializeMap(); } });
  }


  /*********************
    initializeMap
  *********************/
  initializeMap() {

    let mapLat = parseFloat(sessionStorage.getItem('mapLat'));
    let mapLon = parseFloat(sessionStorage.getItem('mapLon'));
    let mapZoom = parseInt(sessionStorage.getItem('mapZoom'));

    let center = new google.maps.LatLng(mapLat, mapLon);

    let mapOptions = {
      zoom: mapZoom,
      tilt: 0,
      center: center,
      disableDefaultUI: true,
      scrollwheel: false,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER
      }
    };

    this.map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);

    //this.bounds = new google.maps.LatLngBounds(new google.maps.LatLng(48, -12), new google.maps.LatLng(61, 5));
    this.bounds = 
      new google.maps.LatLngBounds( new google.maps.LatLng(51.3021, -2.4891),
                                    new google.maps.LatLng(51.4467, -2.2604));

    this.apiService.sitesSubject.subscribe((sites: Site[]) => {

      this.sites = sites;
      this.createCatchmentFilters();
      this.processMarkers();
      this.getRainImage(0);
      setTimeout(() => {this.overlay.hide();}, 2000);
    });
  }


  /*********************
    createCatchmentFilters
  *********************/
  createCatchmentFilters() {
    let catchments = Array.from(new Set(this.sites.map(site => site.catchment)));
    this.catchmentMapFilters = [];
    catchments.forEach(c => { this.catchmentMapFilters.push({ filter: 'C', name: c, id: c, text: c, colourClass: '', checked: false, preMuteState: false }); });
  }


  /*********************
    processMarkers
  *********************/
  processMarkers() {

    this.markers.forEach(marker => { marker.setMap(null); });

    this.markers = [];

    this.sites.forEach(site => { this.addMarker(site); this.markers.push(site.marker); });

    this.updateMarkersVisibility();

    // Add a marker clusterer to manage the markers.
    // new MarkerClusterer(this.map, this.markers, {
    //   imagePath: '/mint/img/maps/m',
    //   maxZoom: 10
    // });
  }

  
  /*********************
    addMarker
  *********************/
  addMarker(site: Site) {

    let pos = new google.maps.LatLng(site.latitude, site.longitude);

    let labelColorLight = '#ffffff';
    //let labelColorDark = '#444444';

    site.marker = new google.maps.Marker({
      title: site.name,
      position: pos,
      map: this.map,
      icon: this.pinSymbol(site.blockageAlert.blockageLevel.text, site.facility),
      label: {
        text: site.anomalyPumpSpillage,
        color: labelColorLight,
        fontSize: "16px",
        fontWeight: "normal"
      }
    });

    let icontent = "<div class='info-container'>";
    icontent += "<div class='ititle'>" + site.name + "</div>";
    icontent += "<div class='iaddress'>" + site.address + "</div>";
    icontent += "<ul class='ialerts'>";
    
    let alertsPresent = false;

    if (site.blockageAlert.blockageLevel.text != 'NONE') {
      icontent += "<li>Blockage Alert - " + site.blockageAlert.blockageLevel.text + "</li>";
      alertsPresent = true;
    }

    if (site.anomalyPumpSpillage == 'P') {
      icontent += "<li>Pump Alert</li>";
      alertsPresent = true;
    }
    else if (site.anomalyPumpSpillage == 'S') {
      icontent += "<li>Spillage Alert</li>";
      alertsPresent = true;
    }
    else if (site.anomalyPumpSpillage == 'A') {
      icontent += "<li>Anomaly Alert</li>";
      alertsPresent = true;
    }

    if (!alertsPresent) {
      icontent += "<li>No Alerts</li>";
    }
    
    icontent += "</ul'>";

    if (site.link != ''){
      icontent += "<a class='btn btn-sm btn-danger' href='/" + site.link + "'>View Details</a>";
    }
    icontent += "</div>";

    //site.marker['infowindow'] = new google.maps.InfoWindow({ content: icontent });

    let _this = this;
    
    google.maps.event.addListener(site.marker, 'click', function() {

      // if(this['infowindow'].getMap()){
      //   this['infowindow'].close();
      // } else {
      //   this['infowindow'].open(this.map, this);			    	
      // }

      for (let i = 0; i < _this.sites.length; i++) {

        let site = _this.sites[i];

        if (site.name == this.title) {

          let url = 'site' + site.facility + '/' + site.name;
          _this.router.navigate([url]);
          break;
        }
      }

    });

  }


  /*********************
    pinSymbol
  *********************/
  pinSymbol(severityText, facilityType) {
    
    let color = '#4bef8e'

    switch(severityText) {
      case 'HIGH':
        color = '#E5332A';
        break;
      case 'MODERATE':
        color = '#F29100';
        break;
      case 'LOW':
        color = '#FFD032';
        break;			        			        			        			        
    };

    // let ipath = 'M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z';
    // let scaleno = 0.07;
    // let laborigin = new google.maps.Point(205,190);
    // let anchor = new google.maps.Point(200,510);

    let ipath = '';

    switch(facilityType) {
      case 'SPS':
        ipath = 'M 50 0 L 100 100 H 0 z'; // triangle
        break;
      case 'CSO':
        ipath = 'M 12 12 H 88 V 88 H 12 z'; // square
        break;
      case 'WWTW':
        ipath = 'M 0 50 A 50 50, 0, 1, 0, 100 50 A 50 50, 0, 1, 0, 0 50'; // circle
        break;			        			        			        			        
    };

    
    let scaleno = 0.25;
    let laborigin = new google.maps.Point(50, 55);
    let anchor = new google.maps.Point(50, 50);

    if (facilityType == 'SPS') { laborigin = new google.maps.Point(50, 70); }

    return {
        path: ipath,
        fillColor: color, //color of the marker
        fillOpacity: 1,
        strokeWeight: 1.5,
        strokeColor: '#ffffff',
        scale: scaleno, //size of the marker, careful! this scale also affects anchor and labelOrigin
        anchor: anchor, //position of the icon, careful! this is affected by scale
        labelOrigin: laborigin //position of the label, careful! this is affected by scale		        
    };
  }


  /*********************
    getRainImage
  *********************/
  getRainImage(step){

    this.overlay = new USGSOverlay(this.bounds, '/TEMP/rain' + step + '.png', this.map);
  }  


  /*********************
    mapFilterClick
  *********************/
  mapFilterClick(mf: MapFilter) {

    switch (mf.filter) {

      case 'F' : {
        let fmf = this.facilityMapFilters.find(item => { return item.id == mf.id; });
        fmf.checked = !fmf.checked;
        break;
      }
      case 'C' : {
        let cmf = this.catchmentMapFilters.find(item => { return item.id == mf.id; });
        cmf.checked = !cmf.checked;
        break;
      }
      case 'A' : {
        let amf = this.alertMapFilters.find(item => { return item.id == mf.id; });
        amf.checked = !amf.checked;
        break;
      }
    }

    this.updateMarkersVisibility();
  }


  /*********************
    updateMarkersVisibility
  *********************/
  updateMarkersVisibility() {

    let applyFacility = false;
    let applyCatchment = false;
    let applyAlert = false;

    this.facilityMapFilters.forEach(fmf => { if (fmf.checked) { applyFacility = true; } });
    this.catchmentMapFilters.forEach(cmf => { if (cmf.checked) { applyCatchment = true; } });
    this.alertMapFilters.forEach(amf => { if (amf.checked) { applyAlert = true; } });

    this.sites.forEach(site => {

      let show = !(!applyFacility && !applyCatchment && !applyAlert);

      if (applyFacility) {
        if (this.facilityMapFilters.findIndex(fmf => { return (site.facility == fmf.id && fmf.checked); }) == -1) {
          show = false;
        }
      }

      if (show && applyCatchment) {
        if (this.catchmentMapFilters.findIndex(cmf => { return (site.catchment == cmf.id && cmf.checked); }) == -1) {
          show = false;
        }
      }

      if (show && applyAlert) {
        if (this.alertMapFilters.findIndex(amf => { return (site.blockageAlert.blockageLevel.text == amf.id && amf.checked); }) == -1 &&
            this.alertMapFilters.findIndex(amf => { return (site.anomalyPumpSpillage == amf.id && amf.checked); }) == -1) {
          show = false;
        }
      }

      site.marker.setVisible(show);
    });
  }


  /*********************
    toggleMarkers
  *********************/
  toggleMarkers(type) {

    let mapFilters: MapFilter[] = null;

    switch (type) {

      case 'F' : { mapFilters = this.facilityMapFilters; break; }
      case 'C' : { mapFilters = this.catchmentMapFilters; break; }
      case 'A' : { mapFilters = this.alertMapFilters; break; }
    }   

    // are we turning the current block on or off ?
    let num = 0;
    let set = 0;

    mapFilters.forEach(f => { num++; if (f.checked) set++; });
    let markerstatus = (set < (num / 2));

    // turn all blocks off
    // this.facilityMapFilters.forEach(f => { f.checked = false; });
    // this.catchmentMapFilters.forEach(f => { f.checked = false; });
    // this.alertMapFilters.forEach(f => { f.checked = false; });

    // turn current block on ?
    //if (markerstatus) { mapFilters.forEach(f => { f.checked = markerstatus; }); }
    mapFilters.forEach(f => { f.checked = markerstatus; });

    this.updateMarkersVisibility();

    event.preventDefault();
  }   


  /*********************
    onSliderValue
  *********************/
  onSliderValue(value: number) {
    this.overlay.setMap(null);
    this.getRainImage(value);
  }


  /*********************
    onRadarToggle
  *********************/
  onRadarToggle(show: boolean) {
    if (show) {
      this.overlay.show();
    }
    else {
      this.overlay.hide();
    }
  }


  /*********************
    isInArray
  *********************/
  isInArray(val, arr) : boolean {
      return arr.findIndex(item => { return item === val; }) != -1
  }


  /*********************
    mapReady
  *********************/
  mapReady() : boolean {
    if (this.map) return true;
    else return false;
  }


  /*********************
    setCursor
  *********************/
  setCursor(cursor) : void {
    this.map.setOptions({draggableCursor:cursor});
  }


  /*********************
    processMessage
  *********************/
  processMessage(msgId: MessageId) {

    let allowedMessages = [
      MessageId.PERMITTED_ALERTS_HIDE, 
      MessageId.PERMITTED_ALERTS_SHOW
    ];

    if (!allowedMessages.includes(msgId)) {
      return;
    }

    if (msgId == MessageId.PERMITTED_ALERTS_HIDE) {

      this.alertMapFilters.forEach(amf => { 
        amf.preMuteState = amf.checked;
        if (amf.name != 'P' && amf.name != 'B' && amf.name != 'A') { 
          amf.checked = false;
        }
      });
    }
    else if (msgId == MessageId.PERMITTED_ALERTS_SHOW) {

      this.alertMapFilters.forEach(amf => { 
        amf.checked = amf.preMuteState;
      });
    }

    this.updateMarkersVisibility();
  }
  
}
