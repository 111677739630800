export class InflowRateLevel {
   
  constructor(public id: number, public text: string, public cssClass: string) { }
}

export class InflowRateLevels {

  private inflowRateLevels: InflowRateLevel[] = [];

  constructor() {
    this.inflowRateLevels.push(new InflowRateLevel(0, 'NONE', 'alert-grey'));
    this.inflowRateLevels.push(new InflowRateLevel(1, 'LOW', 'alert-yellow'));
    this.inflowRateLevels.push(new InflowRateLevel(2, 'MODERATE', 'alert-orange'));
    this.inflowRateLevels.push(new InflowRateLevel(3, 'HIGH', 'alert-red'));
    
  }

  getLevelFromId(id: number): InflowRateLevel {
    if (id >= 1 && id <= 3) {
      return this.inflowRateLevels[id];
    }
    else {
      return this.inflowRateLevels[0];
    }
  }

  getLevelFromText(text: string): InflowRateLevel {
    let severityLevel = this.inflowRateLevels.find(sl => sl.text == text);

    if (severityLevel) {
      return severityLevel;
    }
    else {
      return this.inflowRateLevels[0];
    }
  }

  // FOR TESTING ONLY
  getRandom(): InflowRateLevel {
    return this.inflowRateLevels[Math.floor(Math.random() * 4)];
  }

}