import { ChartAPI, generate } from 'c3';
import { CsvUtil } from './csv-util';

export class InflowChart {
  
  c3Chart: ChartAPI = null;

  chartData: any = null;

  rendered: boolean;

  constructor() { }

  public createChart(siteId: String, chartContainerId: string)
  {
    this.rendered = false;

    let chartContainer = document.getElementById(chartContainerId);

    // exit if already created
    if (chartContainer.childElementCount !== 0) { return; }

    let pathData = '/TEMP/GLASSHOUSE_SPS_inflowrates.csv';

    Promise.all([CsvUtil.makeHttpRequest(pathData)])
      .then((data: string[]) => {
        if (data.length != 1) {
          return;
        }
        else {
          this.createChart2(chartContainerId, data[0]);
        }
      })
      .catch(err => console.error('Inflow chart data request returned error ' + err));
  }

  private createChart2(chartContainerId: string, data)
  {
    this.chartData = CsvUtil.getCSVDataIgnoreHeader(data, 1, 2, 'DateTime','Inflow Rate');

    this.c3Chart = generate({
      bindto: '#' + chartContainerId,
      size: { height: 300 },
      data: {
        x: 'DateTime',
        xFormat: '%d/%m/%Y %H:%M',
        columns: [
          this.chartData.xdata,
          this.chartData.ydata
        ],
      },
      axis: {
        x: {
          show: true,
          type: 'timeseries',
          label: {
            text: 'Date/Time',
            position: 'outer-center'
          },		            
          tick: {
            fit: false,
            rotate: 0,
            format: function (x: any) { 
              let days = ['Sun 00:00','Mon 00:00','Tues 00:00','Wed 00:00','Thur 00:00','Fri 00:00','Sat 00:00'];
              return days[x.getDay()]; 
            }
          },
        },
        y: {		            
          label: {
            text: 'Inflow Rate',
            position: 'outer-middle'
          },
          show: true,
        }
      },		    
      zoom: { enabled: true },
      onrendered: () => { this.rendered = true; }		
    });
  }

}