import { PageEvent } from '@angular/material/paginator';
import { Site } from './site';
import { Checkbox } from './checkbox';
import { Paginator } from './paginator';

export class FilterSortSites {

  public sitesReceived: Site[];
  public sitesAffected: Site[] = [];
  public sitesFilteredSorted: Site[] = [];
  public sitesPageDisplay: Site[] = [];

  public severityCheckboxes: Checkbox[] = [];
  public facilityCheckboxes: Checkbox[] = [];
  public catchmentCheckboxes: Checkbox[] = [];

  public stationText: string = '';
  public stationTextUpper: string = '';

  public sortType: string = 'Severity';
  public sortOrder: string = 'Desc';

  private severityCompareFunc1: (s:Site, c:Checkbox)=>boolean; 
  private severityCompareFunc2: (s:Site, c:Checkbox)=>boolean; 

  private severityTextFunc1: (s:Site)=>string; 
  private severityTextFunc2: (s:Site)=>string; 

  private srtAscSeverityFunc1: (s1:Site, s2:Site)=>number;
  private srtDscSeverityFunc1: (s1:Site, s2:Site)=>number;  
  private srtAscSeverityFunc2: (s1:Site, s2:Site)=>number; 
  private srtDscSeverityFunc2: (s1:Site, s2:Site)=>number; 

  public pagn8r: Paginator;

  constructor (pageSize: number) {
    this.pagn8r = new Paginator(pageSize);
  }

  private setupForSpillageSeverity(): void {

    this.severityCompareFunc1 = (s, c) => c.text === s.spillageAlert.spillageLevel.text;
    this.severityCompareFunc2 = () => false;
    this.severityTextFunc1 = s => s.spillageAlert.spillageLevel.text;
    this.srtAscSeverityFunc1 = (s1, s2) => s1.spillageAlert.spillageLevel.id - s2.spillageAlert.spillageLevel.id; 
    this.srtDscSeverityFunc1 = (s1, s2) => s2.spillageAlert.spillageLevel.id - s1.spillageAlert.spillageLevel.id;
  }


  public removeNonAffected(severityType: string): void {

    switch (severityType) {
      case 'spillage' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.spillageAlert.spillageLevel.id > 0);
        this.setupForSpillageSeverity();
        break;
      }
      case 'blockage' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.blockageAlert.blockageLevel.id > 0);
        this.severityCompareFunc1 = (s, c) => c.text === s.blockageAlert.blockageLevel.text;
        this.severityCompareFunc2 = () => false;
        this.severityTextFunc1 = s => s.blockageAlert.blockageLevel.text;
        this.srtAscSeverityFunc1 = (s1, s2) => s1.blockageAlert.blockageLevel.id - s2.blockageAlert.blockageLevel.id; 
        this.srtDscSeverityFunc1 = (s1, s2) => s2.blockageAlert.blockageLevel.id - s1.blockageAlert.blockageLevel.id;
        break;
      }
      case 'pump' : {
        this.sitesAffected = this.sitesReceived.filter(site => (site.pumpAlert.pump1AlertLevel.id > 0 || site.pumpAlert.pump2AlertLevel.id > 0));
        this.severityCompareFunc1 = (s, c) => c.text === s.pumpAlert.pump1AlertLevel.text;
        this.severityCompareFunc2 = (s, c) => c.text === s.pumpAlert.pump2AlertLevel.text;
        this.severityTextFunc1 = s => s.pumpAlert.pump1AlertLevel.text;
        this.severityTextFunc2 = s => s.pumpAlert.pump2AlertLevel.text;
        this.srtAscSeverityFunc1 = (s1, s2) => s1.pumpAlert.pump1AlertLevel.id - s2.pumpAlert.pump1AlertLevel.id; 
        this.srtDscSeverityFunc1 = (s1, s2) => s2.pumpAlert.pump1AlertLevel.id - s1.pumpAlert.pump1AlertLevel.id;
        this.srtAscSeverityFunc2 = (s1, s2) => s1.pumpAlert.pump2AlertLevel.id - s2.pumpAlert.pump2AlertLevel.id; 
        this.srtDscSeverityFunc2 = (s1, s2) => s2.pumpAlert.pump2AlertLevel.id - s1.pumpAlert.pump2AlertLevel.id;
        break;
      }
      case 'anomaly' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.anomalyAlert.Description != '');
        this.severityCompareFunc1 = () => false;
        this.severityCompareFunc2 = () => false;
        this.severityTextFunc1 = s => '';
        this.srtAscSeverityFunc1 = () => 0; 
        this.srtDscSeverityFunc1 = () => 0;
        break;
      }
      case 'sps' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.facility === 'SPS');
        this.setupForSpillageSeverity();
        break;
      }
      case 'cso' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.facility === 'CSO');
        this.setupForSpillageSeverity();
        break;
      }
      case 'wwtw' : {
        this.sitesAffected = this.sitesReceived.filter(site => site.facility === 'WWTW');
        this.severityCompareFunc1 = (s, c) => c.text === s.chamberInfo.inflowRateLevel.text;
        this.severityCompareFunc2 = () => false;
        this.severityTextFunc1 = s => s.chamberInfo.inflowRateLevel.text;
        this.srtAscSeverityFunc1 = (s1, s2) => s1.chamberInfo.inflowRateLevel.id - s2.chamberInfo.inflowRateLevel.id; 
        this.srtDscSeverityFunc1 = (s1, s2) => s2.chamberInfo.inflowRateLevel.id - s1.chamberInfo.inflowRateLevel.id;
        break;
      }
    }

    this.severityCheckboxes = [];
    this.facilityCheckboxes = [];
    this.catchmentCheckboxes = [];
    
    for (let i = 0; i < this.sitesAffected.length; ++i) {

      let addSeverity1 = true;
      for (let j = 0; j < this.severityCheckboxes.length; ++j) {
        if (this.severityCompareFunc1(this.sitesAffected[i], this.severityCheckboxes[j])) {
          addSeverity1 = false;
          break; 
        }
      }
      if (addSeverity1) { this.severityCheckboxes.push(new Checkbox(this.severityTextFunc1(this.sitesAffected[i]), false)); }

      if (severityType === 'pump') {
        let addSeverity2 = true;
        for (let j = 0; j < this.severityCheckboxes.length; ++j) {
          if (this.severityCompareFunc2(this.sitesAffected[i], this.severityCheckboxes[j])) {
            addSeverity2 = false;
            break; 
          }
        }
        if (addSeverity2) { this.severityCheckboxes.push(new Checkbox(this.severityTextFunc2(this.sitesAffected[i]), false)); }
      }

      let addFacility = true;
      for (let j = 0; j < this.facilityCheckboxes.length; ++j) {
        if (this.facilityCheckboxes[j].text === this.sitesAffected[i].facility) {
          addFacility = false;
          break; 
        }
      }
      if (addFacility) { this.facilityCheckboxes.push(new Checkbox(this.sitesAffected[i].facility, false)); }

      let addCatchment = true;
      for (let j = 0; j < this.catchmentCheckboxes.length; ++j) {
        if (this.catchmentCheckboxes[j].text === this.sitesAffected[i].catchment) {
          addCatchment = false;
          break; 
        }
      }
      if (addCatchment) { this.catchmentCheckboxes.push(new Checkbox(this.sitesAffected[i].catchment, false)); }
    }
  }


  public setSortBy(sortType: string, sortOrder: string): void {
    this.sortType = sortType;
    this.sortOrder = sortOrder;
    this.process();
  }


  public setFilterBySeverity(event: any, severityCheckbox: Checkbox): void {
    severityCheckbox.checked = event.srcElement.checked;    
    this.process();
  }


  public setFilterByFacility(event: any, facilityCheckbox: Checkbox): void {
    facilityCheckbox.checked = event.srcElement.checked;
    this.process();
  }


  public setFilterByCatchment(event: any, catchmentCheckbox: Checkbox): void {
    catchmentCheckbox.checked = event.srcElement.checked; 
    this.process();
  }


  public setFilterByStation(event: any): void {
    this.stationText = event.srcElement.value; 
    this.stationTextUpper = this.stationText.toUpperCase();
    this.process();
  }


  public resetFilters() {
    this.severityCheckboxes.forEach(scb => { scb.checked = false; });
    this.facilityCheckboxes.forEach(fcb => { fcb.checked = false; });
    this.catchmentCheckboxes.forEach(ccb => { ccb.checked = false; });
    this.stationText = '';
    this.stationTextUpper = '';
    this.process();
  }


  public process(): void {

    // FILTER

    let filtersApply = false;

    this.severityCheckboxes.forEach(scb => { if (scb.checked) { filtersApply = true; } });
    this.facilityCheckboxes.forEach(fcb => { if (fcb.checked) { filtersApply = true; } });
    this.catchmentCheckboxes.forEach(ccb => { if (ccb.checked) { filtersApply = true; } });

    if (this.stationText !== '') { filtersApply = true; }

    if (!filtersApply) {
      this.sitesFilteredSorted = this.sitesAffected.slice();
    }
    else {
      this.sitesFilteredSorted = [];
      this.sitesAffected.forEach(site => {

        let addSite = true;

        let severityChecked = false;
        let severityAdd = false;
        for (let i = 0; i < this.severityCheckboxes.length; ++i) {
          if (this.severityCheckboxes[i].checked) { severityChecked = true; }
          if (this.severityCheckboxes[i].checked &&
              (this.severityCompareFunc1(site, this.severityCheckboxes[i]) ||
               this.severityCompareFunc2(site, this.severityCheckboxes[i])) ) {
            severityAdd = true;
          } 
        }
        if (severityChecked && !severityAdd) { addSite = false; }

        let facilityChecked = false;
        let facilityAdd = false;
        for (let i = 0; i < this.facilityCheckboxes.length; ++i) {
          if (this.facilityCheckboxes[i].checked) { facilityChecked = true; }
          if (this.facilityCheckboxes[i].checked &&
              this.facilityCheckboxes[i].text === site.facility) {
            facilityAdd = true;
          } 
        }
        if (facilityChecked && !facilityAdd) { addSite = false; }

        let catchmentChecked = false;
        let catchmentAdd = false;
        for (let i = 0; i < this.catchmentCheckboxes.length; ++i) {
          if (this.catchmentCheckboxes[i].checked) { catchmentChecked = true; }
          if (this.catchmentCheckboxes[i].checked &&
              this.catchmentCheckboxes[i].text === site.catchment) {
            catchmentAdd = true;
          } 
        }
        if (catchmentChecked && !catchmentAdd) { addSite = false; }

        let siteNameUpper = site.name.toUpperCase();

        if (!siteNameUpper.includes(this.stationTextUpper)) { addSite = false; }

        if (addSite) { this.sitesFilteredSorted.push(site); }

      });
    }
    

    // SORT

    switch (this.sortType) {
      case 'Severity' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort(this.srtAscSeverityFunc1) :
            this.sitesFilteredSorted.sort(this.srtDscSeverityFunc1);
        break;
      case 'Severity2' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort(this.srtAscSeverityFunc2) :
            this.sitesFilteredSorted.sort(this.srtDscSeverityFunc2);
        break;
      case 'Station' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.name.localeCompare(b.name)) :
            this.sitesFilteredSorted.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case 'Facility' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.facility.localeCompare(b.facility)) :
            this.sitesFilteredSorted.sort((a, b) => b.facility.localeCompare(a.facility));
        break;
      case 'Duration' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.spillageAlert.durationMinutes - b.spillageAlert.durationMinutes) :
            this.sitesFilteredSorted.sort((a, b) => b.spillageAlert.durationMinutes - a.spillageAlert.durationMinutes);
        break;
      case 'Quantity' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.spillageAlert.quantity - b.spillageAlert.quantity) :
            this.sitesFilteredSorted.sort((a, b) => b.spillageAlert.quantity - a.spillageAlert.quantity);
        break;
      case 'Catchment' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.catchment.localeCompare(b.catchment)) :
            this.sitesFilteredSorted.sort((a, b) => b.catchment.localeCompare(a.catchment));
        break;    
      case 'Address' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.address.localeCompare(b.address)) :
            this.sitesFilteredSorted.sort((a, b) => b.address.localeCompare(a.address));
        break; 
      case 'StartTime' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.anomalyAlert.startTime.getTime() - b.anomalyAlert.startTime.getTime()) :
            this.sitesFilteredSorted.sort((a, b) => b.anomalyAlert.startTime.getTime() - a.anomalyAlert.startTime.getTime());
        break;
      case 'EndTime' : 
        this.sitesFilteredSorted = this.sortOrder == 'Asc' ?
          this.sitesFilteredSorted.sort((a, b) => a.anomalyAlert.endTime.getTime() - b.anomalyAlert.endTime.getTime()) :
            this.sitesFilteredSorted.sort((a, b) => b.anomalyAlert.endTime.getTime() - a.anomalyAlert.endTime.getTime());
        break;     
    }

    this.pagn8r.adjustForNewMaxIndex(this.sitesFilteredSorted.length);

    this.processPage();
  }

  public onPageUpdate(pageEvent: PageEvent) {

    this.pagn8r.pageIndex = pageEvent.pageIndex;

    this.processPage();
  }

  private processPage() {

    let numDisplayItems = this.pagn8r.calcNumDisplayRows(this.sitesFilteredSorted.length);

    this.sitesPageDisplay = new Array<Site>(numDisplayItems);

    this.pagn8r.createPageArray(this.sitesFilteredSorted, this.sitesPageDisplay, numDisplayItems);
  }

}
